import ContactsIcon from '@mui/icons-material/Contacts';
import { Alert, Card, CardContent, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { useEffect } from 'react';

type Props = {
    theme: Theme;
    targetUsers: string;
    setTargetUsers: (targetUsers: string) => void;
    unitCost: number;
    session?: any;
};

const MessageUsersFormTargetUsers = (props: Props) => {
    const { targetUsers, setTargetUsers, unitCost, theme, session } = props;

    useEffect(() => {
        if (session && session.targetUsers) {
            setTargetUsers(session.targetUsers);
        }
    }, [session, setTargetUsers]);

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
            <Grid item xs={12}>
                <Card variant="elevation">
                    <CardContent>
                        <Grid
                            container
                            spacing={1}
                            flexGrow={0}
                            alignItems="center"
                            sx={{
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            <Grid item xs="auto">
                                <ContactsIcon color="success" />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="h6" sx={{ marginBottom: theme.spacing(1) }}>
                                    Who to Send the Message To?
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <RadioGroup
                                    value={targetUsers}
                                    onChange={e => {
                                        setTargetUsers(e.currentTarget.value);
                                    }}
                                    sx={{ paddingBottom: theme.spacing(1) }}
                                >
                                    <FormControlLabel
                                        value="onlineActive"
                                        control={<Radio size="medium" />}
                                        label={
                                            <span>
                                                <strong>Current Fans</strong> who are <strong>Online Right Now</strong>
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value="onlineFollowing"
                                        control={<Radio size="medium" />}
                                        label={
                                            <span>
                                                <strong>Those You Follow</strong> who are <strong>Online Right Now</strong>
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value="onlineAll"
                                        control={<Radio size="medium" />}
                                        label={
                                            <span>
                                                <strong>Current Fans & Those You Follow</strong> who are <strong>Online Right Now</strong>
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        value="followingActive"
                                        control={<Radio size="medium" />}
                                        label="All Fans I Follow (Following→Active)"
                                    />
                                    <FormControlLabel
                                        value="fansActive"
                                        control={<Radio size="medium" />}
                                        label="All Fans Following Me (Fans→Active)"
                                    />
                                    <FormControlLabel
                                        value="fansAll"
                                        control={<Radio size="medium" />}
                                        label="All Fans and Following (Following→Active & Fans→Active)"
                                    />
                                    <FormControlLabel
                                        value="followingExpired"
                                        control={<Radio size="medium" />}
                                        label="Expired Fans I Follow"
                                    />
                                    <FormControlLabel value="lists" control={<Radio size="medium" />} label="Fans in a List" />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ color: theme.palette.grey[500] }}>* The system will automatically skip creators</div>
                            </Grid>

                            {unitCost !== 0 &&
                                targetUsers !== 'onlineActive' &&
                                targetUsers !== 'onlineFollowing' &&
                                targetUsers !== 'onlineAll' &&
                                targetUsers !== 'onlineExpired' && (
                                    <Grid item xs={12}>
                                        <Alert variant="filled" severity="warning">
                                            Please be mindful of the cost when messaging a large group of users
                                        </Alert>
                                    </Grid>
                                )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MessageUsersFormTargetUsers;
