import { Card, CardContent, Container, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import moment from 'moment-timezone';
import 'moment/locale/en-gb';
import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import SextforceMetricsTrial from '../../../components/services/sextforce/metrics/trials/details/SextforceMetricsTrial';
import SextforceMetricsTrialsOverview from '../../../components/services/sextforce/metrics/trials/overview/SextforceMetricsTrialsOverview';
import useDashboardAccount from '../../../hooks/useDashboardAccount';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';

const SextforceMetricsTrials = () => {
    const settingsContext = useContext(SettingsContext);
    const { dashboardAccount, dashboardAccountLoading } = useDashboardAccount(true);
    const theme: Theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();
    const [view, setView] = useState<string>(params.metricId ? 'details' : 'overview');
    const [timezone, setTimezone] = useState<string>(moment.tz.guess());

    const { data: subscriber } = useSubscriber();

    // Set global MomentJS locale
    moment.locale('en-gb');

    useEffect(() => {
        if (!dashboardAccountLoading && dashboardAccount) {
            if (dashboardAccount && dashboardAccount.timezone) {
                setTimezone(dashboardAccount.timezone);
            }
        }
    }, [dashboardAccountLoading, dashboardAccount]);

    useEffect(() => {
        if (params['*']?.includes('details')) {
            setView('details');
        } else if (params['*']?.includes('overview')) {
            setView('overview');
        } else {
            navigate(`/subscribers/${params.userId}/services/sextforce/metrics/trials/overview`);
        }
    }, [navigate, params]);

    return (
        <Container
            maxWidth={false}
            sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), backgroundColor: '#e7ebf0', minHeight: '100%' }}
        >
            <div style={{ paddingBottom: theme.spacing(2) }}>
                <Typography variant="h5">
                    <BackNavigationButton
                        {...(subscriber && { url: `/subscribers/${subscriber._id}/${settingsContext.services.sextforce.homeUrl}` })}
                    />{' '}
                    Free Trial Links Metrics for {subscriber?.username}
                </Typography>
            </div>

            <Card sx={{ width: '100%', marginBottom: theme.spacing(2) }}>
                <CardContent style={{ padding: 0 }}>
                    <ToggleButtonGroup
                        color="primary"
                        fullWidth
                        value={view}
                        exclusive
                        onChange={(e, value) => {
                            if (value !== null) {
                                setView(value);

                                // Navigate the router to the correct view but make sure we are not already on this URL
                                if (value === 'overview') {
                                    return navigate(`/subscribers/${params.userId}/services/sextforce/metrics/trials/overview`);
                                } else if (value === 'details') {
                                    return navigate(`/subscribers/${params.userId}/services/sextforce/metrics/trials/details`);
                                }
                            }
                        }}
                    >
                        <ToggleButton value="overview">Overview</ToggleButton>
                        <ToggleButton value="details">Detailed</ToggleButton>
                    </ToggleButtonGroup>
                </CardContent>
            </Card>

            <Routes>
                <Route
                    path="overview"
                    element={
                        <SextforceMetricsTrialsOverview
                            subscriber={subscriber}
                            timezone={timezone}
                            setTimezone={setTimezone}
                            theme={theme}
                        />
                    }
                />
                <Route
                    path="details"
                    element={<SextforceMetricsTrial subscriber={subscriber} timezone={timezone} setTimezone={setTimezone} theme={theme} />}
                />
                <Route
                    path="details/:metricId"
                    element={<SextforceMetricsTrial subscriber={subscriber} timezone={timezone} setTimezone={setTimezone} theme={theme} />}
                />
            </Routes>
        </Container>
    );
};

export default SextforceMetricsTrials;
