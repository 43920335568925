import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import dinero from 'dinero.js';
import moment from 'moment';
// import WarningIcon from '@mui/icons-material/Warning';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import { useMemo } from 'react';
import { d2f } from '../../../../../../utils/common';
import OverviewCard from '../../../../../common/OverviewCard';
import OverviewCardSmall from '../../../../../common/OverviewCardSmall';
import PriceCardWithGoal from '../../../../../common/PriceCardWithGoal';
import SextforceMetricsEarningsSettingsCard from '../../SextforceMetricsEarningsSettingsCard';
import { TotalSales } from '../../campaigns/details/SextforceMetricsCampaign';
import { parseCampaignStats } from '../../statsHelpers';

type Props = {
    trialData: any;
    trialCounters: any;
    trialCountersLoading: boolean;
    trialTotalSales: TotalSales;
    trialSales: any;
    trialSalesLoading: boolean;
    showEarningsWithSubscriptions: boolean;
    setShowEarningsWithSubscriptions: (value: boolean) => void;
    showEarningsAsGross: boolean;
    setShowEarningsAsGross: (value: boolean) => void;
    suspiciousUsersCount: number;
    goalSubs?: number;
    goalEarnings?: number;
    theme: Theme;
};

const SextforceMetricsTrialOverview = (props: Props) => {
    const {
        trialData,
        trialCounters,
        trialCountersLoading,
        trialTotalSales,
        trialSales,
        trialSalesLoading,
        showEarningsWithSubscriptions,
        setShowEarningsWithSubscriptions,
        showEarningsAsGross,
        setShowEarningsAsGross,
        suspiciousUsersCount,
        goalSubs,
        goalEarnings,
        theme,
    } = props;

    const stats = useMemo(
        () => parseCampaignStats(trialSales, trialCounters, trialTotalSales, showEarningsWithSubscriptions),
        [trialSales, trialCounters, trialTotalSales, showEarningsWithSubscriptions],
    );

    return (
        <>
            <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(1) }}>
                {trialData && trialData.payload && trialData.payload.lastUpdateAt && (
                    <Grid item xs={12}>
                        <OverviewCardSmall
                            title="Last Updated"
                            value={
                                <span
                                    style={{
                                        color:
                                            moment(trialData.payload.lastUpdateAt).diff(moment(), 'days') < 0
                                                ? theme.palette.error.dark
                                                : theme.palette.success.dark,
                                    }}
                                >
                                    {moment(trialData.payload.lastUpdateAt).format('L hh:mm a')}
                                </span>
                            }
                            format="plain"
                            theme={theme}
                            loading={!trialData}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <SextforceMetricsEarningsSettingsCard
                        showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                        setShowEarningsWithSubscriptions={setShowEarningsWithSubscriptions}
                        showEarningsAsGross={showEarningsAsGross}
                        setShowEarningsAsGross={setShowEarningsAsGross}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <OverviewCard
                        title="Total Claimed"
                        value={
                            trialCounters && trialCounters.metricsDatas && trialCounters.metricsDatas.length > 0
                                ? trialCounters.metricsDatas[trialCounters.metricsDatas.length - 1].payload.claimsCount
                                : 0
                        }
                        showOutOfSymbol={false}
                        maxValue={
                            <Typography variant="subtitle1" display={'inline'}>
                                {stats.subscribed} discovered
                            </Typography>
                        }
                        {...(goalSubs && { goal: goalSubs })}
                        loading={trialCountersLoading}
                        icon={<GroupAddIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <OverviewCard
                        title="Total Subscribed"
                        value={stats.clicked}
                        loading={trialCountersLoading}
                        icon={<SubscriptionsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    {/* <OverviewCard
                    title="Suspicious Users"
                    value={suspiciousUsersCount}
                    loading={trialCountersLoading}
                    icon={<WarningIcon fontSize="large" htmlColor={theme.palette.warning.dark} />}
                    theme={theme}
                /> */}
                    <OverviewCard
                        title="Total Spenders"
                        value={stats.spenders}
                        loading={trialSalesLoading}
                        icon={<StarIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(1) }}>
                <Grid item xs={12} sm={4}>
                    {stats.earningsGross === 0 && (
                        <OverviewCard
                            title={
                                <span>
                                    Cost Per Fan <small style={{ color: theme.palette.text.secondary }}>(net)</small>
                                </span>
                            }
                            value={<span style={{ color: theme.palette.text.secondary }}>n/a</span>}
                            loading={trialSalesLoading}
                            icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    )}
                    {stats.earningsGross !== 0 && (
                        <PriceCardWithGoal
                            title={
                                <span>
                                    Cost Per Fan <small style={{ color: theme.palette.text.secondary }}>(net)</small>
                                </span>
                            }
                            value={dinero({
                                amount: stats.cpf,
                                currency: 'USD',
                            })}
                            loading={trialSalesLoading}
                            icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <OverviewCard
                        title={<span>Fan To Spenders Conversion</span>}
                        value={stats.conversionRateSubscriberToSale.toFixed(2) + '%'}
                        loading={trialSalesLoading}
                        icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <PriceCardWithGoal
                        title={
                            <Typography variant="inherit" textAlign="right">
                                {showEarningsWithSubscriptions ? 'Earnings with Subs.' : 'Earnings without Subs.'}{' '}
                                <small style={{ color: theme.palette.text.secondary }}>{showEarningsAsGross ? '(gross)' : '(net)'}</small>
                            </Typography>
                        }
                        value={dinero({
                            amount: Math.trunc(showEarningsAsGross ? stats.earningsGross : stats.earningsNet),
                            currency: 'USD',
                        })}
                        {...(goalEarnings && {
                            goal: dinero({
                                amount: Math.trunc(d2f(goalEarnings) * 100 * (showEarningsAsGross ? 1 : 0.8)),
                                currency: 'USD',
                            }),
                        })}
                        loading={trialSalesLoading}
                        icon={<AttachMoneyIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title={trialData && trialData.type && trialData.type === 'trialLinkTrial' ? 'Name' : 'Price'}
                        value={
                            trialData && trialData.payload && trialData.type
                                ? trialData.type === 'trialLinkTrial'
                                    ? trialData.payload.name
                                    : dinero({ amount: Math.trunc((trialData.payload.price || 0) * 100), currency: 'USD' }).toFormat()
                                : null
                        }
                        format={'plain'}
                        loading={!trialData}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="Created On"
                        value={
                            trialData && trialData.payload && trialData.payload.createdAt && moment(trialData.payload.createdAt).format('L')
                        }
                        format={'plain'}
                        loading={!trialData}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="ID"
                        value={trialData && trialData.foreignId}
                        format={'plain'}
                        loading={!trialData}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="Days"
                        value={trialData && trialData.payload && trialData.payload.subscribeDays}
                        format={'plain'}
                        loading={!trialData}
                        theme={theme}
                    />
                </Grid>

                {trialData && trialData.type && trialData.type === 'trialLinkPromo' && (
                    <Grid item xs={12}>
                        <OverviewCardSmall
                            value={trialData && trialData.payload && trialData.payload.message}
                            format={'plain'}
                            loading={!trialData}
                            allowOverflow={true}
                            theme={theme}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default SextforceMetricsTrialOverview;
