import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import dinero from 'dinero.js';
import { parse as parseCsv } from 'json2csv';
import moment from 'moment-timezone';
import 'moment/locale/en-gb';
import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import AlertCollapsable from '../../../components/common/AlertCollapsable';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import SelectTimezone from '../../../components/forms/helpers/SelectTimezone';
import ReportAccountTotalsByProduct from '../../../components/services/sextforce/report/ReportAccountTotalsByProduct';
import ReportAccountTotalsOverview from '../../../components/services/sextforce/report/ReportAccountTotalsOverview';
import SextforceTransactionsResultsGrid from '../../../components/services/sextforce/transactions/SextforceTransactionsResultsGrid';
import SextforceTransactionsSearchBar from '../../../components/services/sextforce/transactions/SextforceTransactionsSearchBar';
import useDashboardAccount from '../../../hooks/useDashboardAccount';
import useSextforceTransactions, { SextforceTransactionsReportParams } from '../../../hooks/useSextforceTransactions';
import useSextforceTransactionsSummary from '../../../hooks/useSextforceTransactionsSummary';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';
import { d2f, titleCase } from '../../../utils/common';

/**
 * Transforms the transactions data rows from the server to a format ready for export to CSV or Excel
 * @param rows Transactions rows
 * @param timezone Timezone string to convert the transaction time to
 * @returns Transformed data for export
 */
const transformTransactionsForExport = (rows: any[], timezone: string) => {
    const headers = [
        {
            label: 'ID',
            value: '_id',
        },
        {
            label: 'Transaction ID',
            value: 'transactionId',
        },
        {
            label: `Date & Time (${timezone}`,
            value: 'createdAt',
        },
        {
            label: 'Fraction',
            value: 'fraction',
        },
        {
            label: 'Fan ID',
            value: 'user_id',
        },
        {
            label: 'Fan Name',
            value: 'user_name',
        },
        {
            label: 'Fan Username',
            value: 'user_username',
        },
        {
            label: 'Product',
            value: 'product',
        },
        {
            label: 'Gross $USD',
            value: 'amount',
        },
        {
            label: 'Fee $USD',
            value: 'fee',
        },
        {
            label: 'Net $USD',
            value: 'net',
        },
        {
            label: 'VAT $USD',
            value: 'vat',
        },
        {
            label: 'Commission %',
            value: 'commissionPercent',
        },
        {
            label: 'Commission $USD',
            value: 'commission',
        },
        {
            label: 'Agent',
            value: 'agent',
        },
    ];

    const data = rows.map(row => {
        let commissionPercent: number = 0;

        if (row.commissionPercentOverride) {
            commissionPercent = row.commissionPercentOverride;
        } else {
            if (row.agent) {
                commissionPercent = row.agent.commissionPercent;
            } else {
                commissionPercent = 0;
            }
        }

        return {
            _id: row._id,
            transactionId: row.transactionId,
            createdAt: moment(row.createdAt).tz(timezone).format('L hh:mm a'),
            fraction: (d2f(row.amount) % 1).toFixed(2),
            user_id: row.user.id,
            user_name: row.user.name,
            user_username: row.user.username,
            product: row.product ? titleCase(row.product) : 'Unknown',
            amount: Number.parseFloat(d2f(row.amount).toFixed(2)),
            fee: Number.parseFloat(d2f(row.fee).toFixed(2)),
            net: Number.parseFloat(d2f(row.net).toFixed(2)),
            vat: Number.parseFloat(d2f(row.vat).toFixed(2)),
            commissionPercent: row.commissionPercentOverride
                ? row.commissionPercentOverride
                : row.agent
                ? row.agent.commissionPercent
                : null,
            commission: Number.parseFloat(((commissionPercent * d2f(row.net)) / 100).toFixed(2)),
            agent: row.agent ? `${row.agent.name} (${d2f(row.agent.fraction).toFixed(2)})` : null,
        };
    });

    return { headers, rows: data };
};

const dineroZero = dinero({ amount: 0, currency: 'USD' });
const summaryZero = {
    totalAmount: dineroZero,
    totalFee: dineroZero,
    totalNet: dineroZero,
    totalVat: dineroZero,
    totalCommission: dineroZero,
};

const SextforceTransactions = () => {
    const settingsContext = useContext(SettingsContext);
    const { dashboardAccount, dashboardAccountLoading } = useDashboardAccount(true);
    const theme: Theme = useTheme();
    const params = useParams();
    const [searchParams] = useSearchParams();

    const { data: subscriber } = useSubscriber();

    // Search Bar
    const [timezone, setTimezone] = useState<string>(searchParams.has('timezone') ? searchParams.get('timezone')! : moment.tz.guess());
    const [searchFreeRange, setSearchFreeRange] = useState<boolean>(
        searchParams.has('freeRange') ? searchParams.get('freeRange')!.toLowerCase() === 'true' : false,
    );
    const [searchWeek, setSearchWeek] = useState<number>(
        searchParams.has('week') ? Number.parseInt(searchParams.get('week')!, 10) : moment().week(),
    );
    const [searchDateFrom, setSearchDateFrom] = useState<Date>(
        searchParams.has('dateFrom') ? moment(searchParams.get('dateFrom')).toDate() : moment().startOf('week').toDate(),
    );
    const [searchDateTo, setSearchDateTo] = useState<Date>(
        searchParams.has('dateTo') ? moment(searchParams.get('dateTo')).toDate() : moment().endOf('week').toDate(),
    );
    const [searchLimitTime, setSearchLimitTime] = useState<boolean>(
        searchParams.has('limitTime') && searchParams.get('limitTime') === 'true' ? true : false,
    );
    const [searchTimeFrom, setSearchTimeFrom] = useState<Date>(
        searchParams.has('limitTime') && searchParams.get('limitTime') === 'true'
            ? moment(searchParams.get('dateFrom')).toDate()
            : moment().startOf('week').toDate(),
    );
    const [searchTimeTo, setSearchTimeTo] = useState<Date>(
        searchParams.has('limitTime') && searchParams.get('limitTime') === 'true'
            ? moment(searchParams.get('dateTo')).toDate()
            : moment().hours(0).minutes(0).toDate(),
    );

    const [searchFilter, setSearchFilter] = useState<string>(searchParams.has('agent') ? 'agent' : 'all');
    const [searchAgent, setSearchAgent] = useState<string>(searchParams.has('agent') ? searchParams.get('agent')! : '');
    const [searchTimezone, setSearchTimezone] = useState<string>(
        searchParams.has('timezone') ? searchParams.get('timezone')! : moment.tz.guess(),
    );

    // Report
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [reportParams, setReportParams] = useState<SextforceTransactionsReportParams>({
        timezone: searchParams.has('timezone') ? searchParams.get('timezone')! : dashboardAccount?.timezone || moment.tz.guess(),
        dateFrom: searchParams.has('dateFrom') ? moment(searchParams.get('dateFrom')).toDate() : null,
        dateTo: searchParams.has('dateTo') ? moment(searchParams.get('dateTo')).toDate() : null,
        isFreeRange: searchParams.has('freeRange') ? searchParams.get('freeRange')!.toLowerCase() === 'true' : false,
        filter: searchParams.has('agent') ? 'agent' : 'all',
        agent: searchParams.has('agent') ? searchParams.get('agent')! : '',
    });
    const [reportSort, setReportSort] = useState<any[]>([{ field: 'createdAt', sort: 'desc' }]);
    const [exportLoading, setExportLoading] = useState<boolean>(false);

    const [rows, setRows] = useState<any[]>([]);
    const [rowsCount, setRowsCount] = useState<number>(0);

    const {
        data: transactions,
        isLoading: transactionsLoading,
        fetchTransactions,
    } = useSextforceTransactions(params.userId, reportParams, reportSort, offset || 0, limit || 10, false);

    const { data: transactionsSummary, isLoading: transactionsSummaryLoading } = useSextforceTransactionsSummary(
        params.userId,
        reportParams,
        reportSort,
    );

    // Set global MomentJS locale
    moment.updateLocale('en-gb', {});

    useEffect(() => {
        if (!dashboardAccountLoading && dashboardAccount && dashboardAccount.timezone) {
            setSearchTimezone(dashboardAccount.timezone);
        }
    }, [dashboardAccountLoading, dashboardAccount]);

    useEffect(() => {
        if (!transactionsLoading && transactions) {
            setRows(transactions.rows);
            setRowsCount(transactions.metadata.total);
        }
    }, [transactions, transactionsLoading]);

    // Retrieve the sales report from the server
    const requestReport = async () => {
        // Reset the offset
        setOffset(0);

        const calculatedMainDateFrom: Date = searchLimitTime
            ? moment(searchDateFrom).hours(searchTimeFrom.getHours()).minutes(searchTimeFrom.getMinutes()).toDate()
            : searchDateFrom;
        const calculatedMainDateTo: Date = searchLimitTime
            ? moment(searchDateTo).hours(searchTimeTo.getHours()).minutes(searchTimeTo.getMinutes()).toDate()
            : searchDateTo;

        // Update report date range
        setReportParams({
            timezone: searchTimezone,
            dateFrom: moment(calculatedMainDateFrom).tz(searchTimezone, true).toDate(),
            dateTo: moment(calculatedMainDateTo).tz(searchTimezone, true).toDate(),
            isFreeRange: searchFreeRange,
            filter: searchFilter,
            agent: searchAgent,
        });

        // refetchReport();
    };

    // Fetch a full report, convert to CSV format and start file download
    const handleDownloadReportCsv = () => {
        const fetchExport = async () => {
            setExportLoading(true);

            await fetchTransactions(params.userId, reportParams, reportSort, offset, limit, true).then(data => {
                const transformedData: any = transformTransactionsForExport(data.rows, timezone);

                // Format JSON data to CSV
                const csv = parseCsv(transformedData.rows, {
                    fields: transformedData.headers,
                    transforms: [],
                });

                // Convert CSV to Blob
                const blob: Blob = new Blob([csv], { type: 'text/csv' });

                // Create a descriptive filename
                const filename: string = `transactions_${
                    searchFreeRange
                        ? `${moment(searchDateFrom).tz(searchTimezone, true).format('YYYY-MM-DD')}_${moment(searchDateTo)
                              .tz(searchTimezone, true)
                              .format('YYYY-MM-DD')}`
                        : `week_${searchWeek}`
                }.csv`;

                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode && link.parentNode.removeChild(link);
            });

            setExportLoading(false);
        };

        if (reportParams.dateFrom && reportParams.dateTo) {
            fetchExport();
        }
    };

    // Fetch a full report, convert to CSV format and start file download
    const handleDownloadReportExcel = () => {
        const fetchExport = async () => {
            setExportLoading(true);

            await fetchTransactions(params.userId, reportParams, reportSort, offset, limit, true).then(data => {
                const transformedData: any = transformTransactionsForExport(data.rows, timezone);

                const workbook = XLSX.utils.book_new();
                const worksheet = XLSX.utils.json_to_sheet([]);

                XLSX.utils.sheet_add_aoa(worksheet, [transformedData.headers.map((header: any) => header.label)]);

                XLSX.utils.sheet_add_json(worksheet, transformedData.rows, {
                    origin: 'A2',
                    skipHeader: true,
                    header: transformedData.headers.map((header: any) => header.value),
                });

                XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

                // Create a descriptive filename
                const filename: string = `transactions_${
                    searchFreeRange
                        ? `${moment(searchDateFrom).tz(searchTimezone, true).format('YYYY-MM-DD')}_${moment(searchDateTo)
                              .tz(searchTimezone, true)
                              .format('YYYY-MM-DD')}`
                        : `week_${searchWeek}`
                }.xlsx`;

                XLSX.writeFile(workbook, filename);
            });

            setExportLoading(false);
        };

        if (reportParams.dateFrom && reportParams.dateTo) {
            fetchExport();
        }
    };

    return (
        <Container
            maxWidth={false}
            sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), backgroundColor: '#e7ebf0', minHeight: '100%' }}
        >
            <div style={{ paddingBottom: theme.spacing(2) }}>
                <Typography variant="h5">
                    <BackNavigationButton
                        {...(subscriber && { url: `/subscribers/${subscriber._id}/${settingsContext.services.sextforce.homeUrl}` })}
                    />{' '}
                    Transactions for {subscriber?.username}
                </Typography>
            </div>
            <AlertCollapsable
                openInitially={false}
                title="How It Works"
                variant="filled"
                severity="info"
                sx={{
                    marginBottom: 2,
                }}
            >
                <p>
                    Here you will find all the transactions collected from your OnlyFans account. These will mirror exactly what you see in
                    your Statements page on OnlyFans up to last hour. You can search them by week or any date range, filter them by
                    unassigned transactions or ones that have been assigned to a speicific agent.
                </p>
                <p>
                    This page is useful for exporting sales reports for your Agents as proof or as a tool to check any anomalies. It's much
                    easier to browse the transactions on this page than trying to scroll back to some transaction on OnlyFans. Crucially, it
                    is also possible to translate the time of sale to your Agent's time zone for convenience.
                </p>
                <p>
                    Search for the transactions you are looking for and then click on EXPORT ALL TO CSV or EXPORT ALL TO EXCEL to download
                    all the search results.
                </p>
            </AlertCollapsable>

            <SextforceTransactionsSearchBar
                timezone={searchTimezone}
                setTimezone={setSearchTimezone}
                mainSearch={{
                    freeRange: searchFreeRange,
                    setFreeRange: setSearchFreeRange,
                    week: searchWeek,
                    setWeek: setSearchWeek,
                    dateFrom: searchDateFrom,
                    setDateFrom: setSearchDateFrom,
                    dateTo: searchDateTo,
                    setDateTo: setSearchDateTo,
                    limitTime: searchLimitTime,
                    setLimitTime: setSearchLimitTime,
                    timeFrom: searchTimeFrom,
                    setTimeFrom: setSearchTimeFrom,
                    timeTo: searchTimeTo,
                    setTimeTo: setSearchTimeTo,
                }}
                filter={searchFilter}
                setFilter={setSearchFilter}
                agent={searchAgent}
                setAgent={setSearchAgent}
                isFetchingReport={transactionsLoading}
                requestReport={requestReport}
            />

            <Typography variant="subtitle1" sx={{ paddingBottom: theme.spacing(2) }}>
                {!searchFreeRange && (
                    <>
                        {`Results for Week ${moment(searchDateFrom).week()} `}
                        <br />
                    </>
                )}
                {searchDateFrom && searchDateTo ? (
                    <>
                        {searchDateFrom.toLocaleDateString()} {searchDateFrom.toLocaleTimeString(undefined, { hour12: true })} -{' '}
                        {searchDateTo.toLocaleDateString()} {searchDateTo.toLocaleTimeString(undefined, { hour12: true })}
                    </>
                ) : (
                    'No Dates Selected'
                )}
            </Typography>

            <ReportAccountTotalsOverview
                mainTotals={
                    transactionsSummary
                        ? {
                              totalAmount: dinero({
                                  amount: Math.trunc(d2f(transactionsSummary.summary.amount) * 100),
                                  currency: 'USD',
                              }),
                              totalFee: dinero({ amount: Math.trunc(d2f(transactionsSummary.summary.fee) * 100), currency: 'USD' }),
                              totalNet: dinero({ amount: Math.trunc(d2f(transactionsSummary.summary.net) * 100), currency: 'USD' }),
                              totalVat: dinero({ amount: Math.trunc(d2f(transactionsSummary.summary.vat) * 100), currency: 'USD' }),
                              totalCommission: dinero({
                                  amount: Math.trunc(d2f(transactionsSummary.summary.commission) * 100),
                                  currency: 'USD',
                              }),
                          }
                        : summaryZero
                }
                compareTotals={summaryZero}
                compare={false}
                isFetching={transactionsSummaryLoading}
            />

            <ReportAccountTotalsByProduct
                mainData={transactionsSummary ? transactionsSummary.products : []}
                compareData={[]}
                compare={false}
                isFetching={transactionsSummaryLoading}
                theme={theme}
            />

            <Card sx={{ width: '100%', marginBottom: theme.spacing(2) }}>
                <CardContent style={{ padding: theme.spacing(1) }}>
                    <Grid container flexGrow={1} spacing={1} alignItems="center">
                        <Grid item xs={'auto'}>
                            <Typography variant="body1">Translate transactions time to timezone:</Typography>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <SelectTimezone timezone={timezone} setTimezone={setTimezone} size="small" fullWidth={true} />
                        </Grid>
                        <Grid item xs>
                            <Grid container flexGrow={0} spacing={1} alignItems="center" justifyContent="right">
                                <Grid item xs={'auto'}>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            transactionsLoading || !transactions || transactions.metadata.total === 0 || exportLoading
                                        }
                                        loading={transactionsLoading || exportLoading}
                                        onClick={() => {
                                            handleDownloadReportCsv();
                                        }}
                                    >
                                        Export All To CSV
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <LoadingButton
                                        variant="contained"
                                        color="secondary"
                                        disabled={
                                            transactionsLoading || !transactions || transactions.metadata.total === 0 || exportLoading
                                        }
                                        loading={transactionsLoading || exportLoading}
                                        onClick={() => {
                                            handleDownloadReportExcel();
                                        }}
                                    >
                                        Export All To Excel
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <SextforceTransactionsResultsGrid
                rows={rows}
                loading={transactionsLoading}
                rowsCount={rowsCount}
                limit={limit}
                setLimit={setLimit}
                offset={offset}
                setOffset={setOffset}
                reportSort={reportSort}
                setReportSort={setReportSort}
                timezone={timezone}
                theme={theme}
            />

            <Box sx={{ width: '100%', marginBottom: theme.spacing(4) }}></Box>
        </Container>
    );
};

export default SextforceTransactions;
