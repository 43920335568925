import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MouseIcon from '@mui/icons-material/Mouse';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { Grid, Theme, Typography } from '@mui/material';
import dinero from 'dinero.js';
// import WarningIcon from '@mui/icons-material/Warning';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import moment from 'moment';
import { useMemo } from 'react';
import { d2f } from '../../../../../../utils/common';
import OverviewCard from '../../../../../common/OverviewCard';
import OverviewCardSmall from '../../../../../common/OverviewCardSmall';
import PriceCardWithGoal from '../../../../../common/PriceCardWithGoal';
import SextforceMetricsEarningsSettingsCard from '../../SextforceMetricsEarningsSettingsCard';
import { parseCampaignStats } from '../../statsHelpers';
import SextforceMetricsTrialLink from '../../trials/details/SextforceMetricsTrialLink';
import { TotalSales } from './SextforceMetricsCampaign';

type Props = {
    subscriber: any;
    promoCampaign: any;
    promoCampaignCounters: any;
    promoCampaignCountersLoading: boolean;
    promoCampaignTotalSales: TotalSales;
    promoCampaignSales: any;
    promoCampaignSalesLoading: boolean;
    showEarningsWithSubscriptions: boolean;
    setShowEarningsWithSubscriptions: (value: boolean) => void;
    showEarningsAsGross: boolean;
    setShowEarningsAsGross: (value: boolean) => void;
    suspiciousUsersCount: number;
    goalSubs?: number;
    goalEarnings?: number;
    theme: Theme;
};

const SextforceMetricsCampaignOverview = (props: Props) => {
    const {
        subscriber,
        promoCampaign,
        promoCampaignCounters,
        promoCampaignCountersLoading,
        promoCampaignTotalSales,
        promoCampaignSales,
        promoCampaignSalesLoading,
        showEarningsWithSubscriptions,
        setShowEarningsWithSubscriptions,
        showEarningsAsGross,
        setShowEarningsAsGross,
        suspiciousUsersCount,
        goalSubs,
        goalEarnings,
        theme,
    } = props;

    const stats = useMemo(
        () => parseCampaignStats(promoCampaignSales, promoCampaignCounters, promoCampaignTotalSales, showEarningsWithSubscriptions),
        [promoCampaignSales, promoCampaignCounters, promoCampaignTotalSales, showEarningsWithSubscriptions],
    );

    return (
        <>
            <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(1) }}>
                {promoCampaign && promoCampaign.payload && promoCampaign.payload.lastUpdateAt && (
                    <Grid item xs={12}>
                        <OverviewCardSmall
                            title="Last Updated"
                            value={
                                <span
                                    style={{
                                        color:
                                            moment(promoCampaign.payload.lastUpdateAt).diff(moment(), 'days') < 0
                                                ? theme.palette.error.dark
                                                : theme.palette.success.dark,
                                    }}
                                >
                                    {moment(promoCampaign.payload.lastUpdateAt).format('L hh:mm a')}
                                </span>
                            }
                            format="plain"
                            theme={theme}
                            loading={!promoCampaign}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <SextforceMetricsEarningsSettingsCard
                        showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                        setShowEarningsWithSubscriptions={setShowEarningsWithSubscriptions}
                        showEarningsAsGross={showEarningsAsGross}
                        setShowEarningsAsGross={setShowEarningsAsGross}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <OverviewCard
                        title="Subscribed"
                        value={stats.subscribed}
                        {...(goalSubs && { goal: goalSubs })}
                        loading={promoCampaignCountersLoading}
                        icon={<SubscriptionsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <OverviewCard
                        title="Clicked"
                        value={stats.clicked}
                        loading={promoCampaignCountersLoading}
                        icon={<MouseIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    {/* <OverviewCard
                    title="Suspicious Users"
                    value={suspiciousUsersCount}
                    loading={promoCampaignCountersLoading}
                    icon={<WarningIcon fontSize="large" htmlColor={theme.palette.warning.dark} />}
                    theme={theme}
                /> */}
                    <OverviewCard
                        title="Spenders"
                        value={stats.spenders}
                        loading={promoCampaignCountersLoading}
                        icon={<StarIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <PriceCardWithGoal
                        title={
                            <Typography variant="inherit" textAlign="right">
                                {showEarningsWithSubscriptions ? 'Earnings with Subs.' : 'Earnings without Subs.'}{' '}
                                <small style={{ color: theme.palette.text.secondary }}>{showEarningsAsGross ? '(gross)' : '(net)'}</small>
                            </Typography>
                        }
                        value={dinero({
                            amount: Math.trunc(showEarningsAsGross ? stats.earningsGross : stats.earningsNet),
                            currency: 'USD',
                        })}
                        {...(goalEarnings && {
                            goal: dinero({
                                amount: Math.trunc(d2f(goalEarnings) * 100 * (showEarningsAsGross ? 1 : 0.8)),
                                currency: 'USD',
                            }),
                        })}
                        loading={promoCampaignSalesLoading}
                        icon={<AttachMoneyIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    {stats.earningsGross === 0 && (
                        <OverviewCard
                            title={
                                <span>
                                    Cost Per Fan <small style={{ color: theme.palette.text.secondary }}>(net)</small>
                                </span>
                            }
                            value={<span style={{ color: theme.palette.text.secondary }}>n/a</span>}
                            loading={promoCampaignCountersLoading}
                            icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    )}
                    {stats.earningsGross !== 0 && (
                        <PriceCardWithGoal
                            title={
                                <span>
                                    Cost Per Fan <small style={{ color: theme.palette.text.secondary }}>(net)</small>
                                </span>
                            }
                            value={dinero({
                                amount: stats.cpf,
                                currency: 'USD',
                            })}
                            loading={promoCampaignSalesLoading}
                            icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    {stats.earningsGross === 0 && (
                        <OverviewCard
                            title={
                                <span>
                                    Cost Per Click <small style={{ color: theme.palette.text.secondary }}>(net)</small>
                                </span>
                            }
                            value={<span style={{ color: theme.palette.text.secondary }}>n/a</span>}
                            loading={promoCampaignCountersLoading}
                            icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    )}
                    {stats.earningsGross !== 0 && (
                        <PriceCardWithGoal
                            title={
                                <span>
                                    Cost Per Click <small style={{ color: theme.palette.text.secondary }}>(net)</small>
                                </span>
                            }
                            value={dinero({
                                amount: stats.cpc,
                                currency: 'USD',
                            })}
                            loading={promoCampaignSalesLoading}
                            icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <OverviewCard
                        title={<span>Fan To Spenders Conversion</span>}
                        value={stats.conversionRateSubscriberToSale.toFixed(2) + '%'}
                        loading={promoCampaignSalesLoading}
                        icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <OverviewCard
                        title={<span>Click To Fan Conversion</span>}
                        value={stats.conversionRateClickToSubscriber.toFixed(2) + '%'}
                        loading={promoCampaignSalesLoading}
                        icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(1) }}>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="Name"
                        value={promoCampaign && promoCampaign.payload && promoCampaign.payload.name}
                        format={'plain'}
                        loading={!promoCampaign}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="Created On"
                        value={
                            promoCampaign &&
                            promoCampaign.payload &&
                            promoCampaign.payload.createdAt &&
                            moment(promoCampaign.payload.createdAt).format('L')
                        }
                        format={'plain'}
                        loading={!promoCampaign}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="ID"
                        value={promoCampaign && promoCampaign.foreignId}
                        format={'plain'}
                        loading={!promoCampaign}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="Code"
                        value={promoCampaign && promoCampaign.payload && promoCampaign.payload.campaignCode}
                        format={'plain'}
                        loading={!promoCampaign}
                        theme={theme}
                    />
                </Grid>
            </Grid>

            {promoCampaign && promoCampaign.payload && promoCampaign.payload.campaignCode && (
                <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                    <Grid item xs={12}>
                        <SextforceMetricsTrialLink
                            trialUrl={`https://onlyfans.com/${subscriber.username}/c${promoCampaign.payload.campaignCode}`}
                            theme={theme}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default SextforceMetricsCampaignOverview;
