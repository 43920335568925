import { AlertColor, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SharedCreditCheckoutCart from '../../components/account/SharedCreditCheckoutCart';
import SharedCreditCheckoutForm from '../../components/account/SharedCreditCheckoutForm';
import SharedCreditCheckoutMessage from '../../components/account/SharedCreditCheckoutMessage';
import BackNavigationButton from '../../components/common/BackNavigationButton';
import SharedCreditCard from '../../components/common/SharedCreditCard';
import useSubscriber from '../../hooks/useSubscriber';
import { SettingsContext } from '../../store/SettingsContext';
import { UserContext } from '../../store/UserContext';
import { handleHttpError, handleHttpErrorResponse } from '../../utils/common';

const SharedCreditCheckout = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const params = useParams();
    const { data: subscriber } = useSubscriber();

    const service: string = 'sharedCredit';

    const [amount, setAmount] = useState<number | void>(50);
    const [amountError, setAmountError] = useState<string>('');
    const [showPaymentForm, setShowPaymentForm] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [message, setMessage] = useState<string | null | undefined>(null);
    const [messageType, setMessageType] = useState<AlertColor>('info');

    // Make sure to call loadStripe outside of a component’s render to avoid
    // recreating the Stripe object on every render.
    // This is a public sample test API key.
    // Don’t submit any personally identifiable information in requests made with this key.
    // Sign in to see your own test API key embedded in code samples.
    const stripePromise = loadStripe(
        'pk_live_51KPclcALRGpuPUX5efL0E1UUUgEv9a9FugI9a8T6NLd1Q3UIv7peUWE6jKpB2godltwmLll88jkPSNztvL9M0z0T00tjYpTyqy',
    );

    // Stripe
    const [clientSecret, setClientSecret] = useState('');
    const stripeOptions: StripeElementsOptions = {
        clientSecret,
        appearance: {
            theme: 'stripe',
            variables: {
                fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
                fontSizeBase: (theme as any).typography.body1.fontSize,
                colorPrimary: theme.palette.primary.main,
                colorText: theme.palette.common.black,
            },
        },
    };

    useEffect(() => {
        if (
            userContext.jwtToken &&
            'attributes' in (userContext.user as any) &&
            (userContext.user as any).attributes &&
            'email' in (userContext.user as any).attributes &&
            (userContext.user as any).attributes.email
        ) {
            setEmail((userContext.user as any).attributes.email);
        }
    }, [userContext, userContext.jwtToken, userContext.user]);

    useEffect(() => {
        if (subscriber && showPaymentForm) {
            // Create PaymentIntent as soon as the page loads
            fetch(`${settingsContext.routes.account.base}${params.userId}/payments/create-payment-intent`, {
                method: 'POST',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ amount, email, service }),
            })
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .then(data => {
                    if (data) {
                        setClientSecret(data.clientSecret);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    }, [
        amount,
        dialog,
        email,
        params.userId,
        service,
        settingsContext.apiKey,
        settingsContext.routes.account.base,
        showPaymentForm,
        subscriber,
        userContext.jwtToken,
    ]);

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton /> Top-Up Credit for {subscriber.username}
                    </Typography>

                    <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                        <Grid item xs={12} md={6}>
                            <SharedCreditCard showTopUpButton={false} />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={1}
                        flexGrow={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginBottom: theme.spacing(2) }}
                    >
                        <Grid item xs={12} md={6}>
                            <Card variant="elevation">
                                <CardContent>
                                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                                        <strong>Shared credit is used for services like Follow-Back and Message Users.</strong>
                                    </Typography>
                                    <Typography variant="body1">
                                        Please use the form below to buy more shared credit if you would like to pay by card or Apple Pay.
                                        The credit will be immediately applied to your account. Alternatively, you can pay by PayPal, Bank
                                        Transfer, Wise or Skrill by contacting Admin via Telegram.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <SharedCreditCheckoutCart
                        showPaymentForm={showPaymentForm}
                        setShowPaymentForm={setShowPaymentForm}
                        email={email}
                        setEmail={setEmail}
                        emailError={emailError}
                        setEmailError={setEmailError}
                        amount={amount}
                        setAmount={setAmount}
                        amountError={amountError}
                        setAmountError={setAmountError}
                        theme={theme}
                    />

                    <Grid
                        container
                        spacing={1}
                        flexGrow={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginBottom: theme.spacing(2) }}
                    >
                        <Grid item xs={12} md={6}>
                            <Grid item xs={12}>
                                {showPaymentForm && clientSecret && (
                                    <Elements options={stripeOptions} stripe={stripePromise}>
                                        <SharedCreditCheckoutForm
                                            theme={theme}
                                            returnUrl={`${settingsContext.webRoot}/subscribers/${subscriber._id}/account/sharedCreditCheckout`}
                                            amount={amount || 0}
                                            email={email}
                                            isEmailValid={emailError.length === 0}
                                            setMessage={setMessage}
                                            setMessageType={setMessageType}
                                        />
                                    </Elements>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Elements stripe={stripePromise}>
                        <SharedCreditCheckoutMessage
                            subscriber={subscriber}
                            message={message}
                            setMessage={setMessage}
                            messageType={messageType}
                            setMessageType={setMessageType}
                            theme={theme}
                        />
                    </Elements>
                </>
            )}
        </Container>
    );
};

export default SharedCreditCheckout;
