import { Grid, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
type Props = {
    trialsLoading: boolean;
    trialsSelectorType: { includeInactive: boolean; type: string };
    setTrialsSelectorType: (types: { includeInactive: boolean; type: string }) => void;
    theme: Theme;
};

const SextforceMetricsTrialsOverviewSelector = (props: Props) => {
    const { trialsLoading, trialsSelectorType, setTrialsSelectorType, theme } = props;
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md="auto">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <ToggleButtonGroup
                            color="secondary"
                            size={'small'}
                            fullWidth
                            disabled={trialsLoading}
                            exclusive={true}
                            value={trialsSelectorType.type}
                            onChange={(e, value: string) => {
                                setTrialsSelectorType({ includeInactive: trialsSelectorType.includeInactive, type: value });
                            }}
                            sx={{ marginTop: '4px' }}
                        >
                            <ToggleButton value="all" key="left">
                                All
                            </ToggleButton>
                            <ToggleButton value="trialLinkTrial" key="center">
                                Trials
                            </ToggleButton>
                            <ToggleButton value="trialLinkPromo" key="right">
                                Promos
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item>
                        <ToggleButton
                            color="secondary"
                            size={'small'}
                            value={trialsSelectorType.includeInactive ? 'true' : 'false'}
                            selected={trialsSelectorType.includeInactive}
                            disabled={trialsLoading}
                            onChange={(e, value: string) =>
                                setTrialsSelectorType({
                                    includeInactive: !trialsSelectorType.includeInactive,
                                    type: trialsSelectorType.type,
                                })
                            }
                            sx={{ marginTop: theme.spacing(0.5) }}
                        >
                            Show Inactive
                        </ToggleButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SextforceMetricsTrialsOverviewSelector;
