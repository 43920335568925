import { Card, CardContent, FormControlLabel, Grid, Switch } from '@mui/material';
import { useEffect } from 'react';

type Props = {
    showEarningsWithSubscriptions: boolean;
    setShowEarningsWithSubscriptions: (value: boolean) => void;
    showEarningsAsGross: boolean;
    setShowEarningsAsGross: (value: boolean) => void;
};

const SextforceMetricsEarningsSettingsCard = (props: Props) => {
    const { showEarningsWithSubscriptions, setShowEarningsWithSubscriptions, showEarningsAsGross, setShowEarningsAsGross } = props;

    useEffect(() => {
        localStorage.setItem('showEarningsWithSubscriptions', showEarningsWithSubscriptions ? 'true' : 'false');
    }, [showEarningsWithSubscriptions]);

    useEffect(() => {
        localStorage.setItem('showEarningsAsGross', showEarningsAsGross ? 'true' : 'false');
    }, [showEarningsAsGross]);

    return (
        <Card>
            <CardContent>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} sm={4} md={3}>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="medium"
                                    color="secondary"
                                    checked={showEarningsWithSubscriptions}
                                    onChange={() => setShowEarningsWithSubscriptions(!showEarningsWithSubscriptions)}
                                />
                            }
                            label={`Show earnings ${showEarningsWithSubscriptions ? 'with' : 'without'} subscriptions`}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <FormControlLabel
                            control={
                                <Switch
                                    size="medium"
                                    color="secondary"
                                    checked={showEarningsAsGross}
                                    onChange={() => setShowEarningsAsGross(!showEarningsAsGross)}
                                />
                            }
                            label={`Show earnings as ${showEarningsAsGross ? 'gross' : 'net'}`}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsEarningsSettingsCard;
