import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Box,
    Chip,
    Collapse,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import dinero from 'dinero.js';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { OnlyFansMetricsGroupWithSummary } from '../../../../hooks/useSextforceMetricsGroups';
import useSubscribers from '../../../../hooks/useSubscribers';
import { d2f, metricTypeName } from '../../../../utils/common';

const NumberChip = (props: { value: number }) => {
    const { value } = props;
    const theme: Theme = useTheme();

    return (
        <Chip
            label={value.toLocaleString()}
            color={value !== 0 ? 'success' : 'default'}
            sx={{
                width: '100%',
                fontFamily: 'monospace',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                justifyContent: 'flex-end',
                color: theme.palette.common.white,
                background: value !== 0 ? theme.palette.success.main : theme.palette.grey[300],
                '& .MuiChip-root': {},
            }}
        />
    );
};

type Props = {
    groups: OnlyFansMetricsGroupWithSummary[];
    group: OnlyFansMetricsGroupWithSummary;
    amountType: 'gross' | 'net';
    openGroupDialog: (groupId: string) => void;
    handleDeleteGroup: (groupId: string) => void;
};

const SextforceMetricsGroupsTableRow = ({ groups, group, amountType, openGroupDialog, handleDeleteGroup }: Props) => {
    const theme: Theme = useTheme();

    const { data: subscribers, isLoading: isSubscribersLoading } = useSubscribers();

    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{group.name}</TableCell>
                <TableCell align="center">{group.metrics.length.toLocaleString()}</TableCell>
                <TableCell align="right">
                    <NumberChip value={group.subscribersTotal || 0} />
                </TableCell>
                <TableCell align="right">
                    <NumberChip value={group.claimsTotal || 0} />
                </TableCell>
                <TableCell align="right">
                    <Chip
                        label={dinero({
                            amount: Math.trunc(
                                group.earningsTotal && typeof group.earningsTotal !== 'number'
                                    ? d2f(group.earningsTotal) * (amountType === 'net' ? 0.8 : 1) * 100
                                    : 0,
                            ),
                            currency: 'USD',
                        }).toFormat()}
                        color={group.earningsTotal && typeof group.earningsTotal !== 'number' ? 'success' : 'default'}
                        sx={{
                            width: '100%',
                            fontFamily: 'monospace',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            justifyContent: 'flex-end',
                            color: theme.palette.common.white,
                            background:
                                group.earningsTotal && typeof group.earningsTotal !== 'number'
                                    ? theme.palette.success.main
                                    : theme.palette.grey[300],
                            '& .MuiChip-root': {},
                        }}
                    />
                </TableCell>
                <TableCell align="center">
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                        <IconButton onClick={() => openGroupDialog(group._id!)} color="primary" size="small">
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteGroup(group._id!)} color="error" size="small">
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow
                sx={{
                    bgcolor: 'grey.50',
                }}
            >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            sx={{
                                margin: 1,
                            }}
                        >
                            <Typography variant="h6" gutterBottom component="div">
                                Grouped Metrics
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={180}>Username</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell width={90} align="center">
                                            Type
                                        </TableCell>
                                        <TableCell width={120} align="center">
                                            Claims/Subs
                                        </TableCell>
                                        <TableCell width={150} align="center">
                                            Earnings {amountType === 'gross' ? 'Gross' : 'Net'}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody
                                    sx={{
                                        // remove last bottom border
                                        '& tr:last-child td': {
                                            borderBottom: 'none',
                                        },
                                    }}
                                >
                                    {group.metrics.map((metric, index) => (
                                        <TableRow key={index}>
                                            <TableCell scope="row">
                                                {subscribers && !isSubscribersLoading
                                                    ? subscribers.find(subscriber => subscriber._id === metric.subscriberId)?.username
                                                    : metric.subscriberId}
                                            </TableCell>
                                            <TableCell>
                                                <Link
                                                    to={
                                                        metric.type === 'promoCampaign'
                                                            ? `/subscribers/${
                                                                  metric.subscriberId
                                                              }/services/sextforce/metrics/campaigns/details/${metric._id}?${
                                                                  metric.payload && !metric.payload.active ? '&includeInactive=true' : ''
                                                              }`
                                                            : `/subscribers/${
                                                                  metric.subscriberId
                                                              }/services/sextforce/metrics/trials/details/${metric._id}?type=${
                                                                  metric.type
                                                              }${metric.payload && !metric.payload.active ? '&includeInactive=true' : ''}`
                                                    }
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    {metric.payload?.name || 'No Name'}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="center">{metricTypeName(metric.type)}</TableCell>
                                            <TableCell align="right">
                                                {metric.type === 'trialLinkTrial' && (
                                                    <NumberChip value={metric.payload?.counters?.claimsCount || 0} />
                                                )}
                                                {metric.type === 'promoCampaign' && (
                                                    <NumberChip value={metric.payload?.counters?.countSubscribers || 0} />
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Chip
                                                    label={dinero({
                                                        amount: Math.trunc(
                                                            metric.payload &&
                                                                metric.payload.earningsTotal &&
                                                                typeof metric.payload.earningsTotal !== 'number'
                                                                ? d2f(metric.payload.earningsTotal) * (amountType === 'net' ? 0.8 : 1) * 100
                                                                : 0,
                                                        ),
                                                        currency: 'USD',
                                                    }).toFormat()}
                                                    color={
                                                        metric.payload &&
                                                        metric.payload.earningsTotal &&
                                                        typeof metric.payload.earningsTotal !== 'number'
                                                            ? 'success'
                                                            : 'default'
                                                    }
                                                    sx={{
                                                        width: '100%',
                                                        fontFamily: 'monospace',
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        justifyContent: 'flex-end',
                                                        color: theme.palette.common.white,
                                                        background:
                                                            metric.payload &&
                                                            metric.payload.earningsTotal &&
                                                            typeof metric.payload.earningsTotal !== 'number'
                                                                ? theme.palette.success.main
                                                                : theme.palette.grey[300],
                                                        '& .MuiChip-root': {},
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default SextforceMetricsGroupsTableRow;
