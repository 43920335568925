import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    Theme,
    useTheme,
    Grid,
    useMediaQuery,
} from '@mui/material';
import { SextforceMetricsBoughtFromSettingsContent } from '../../SextforceMetricsBoughtFromSettings';
import { d2f, metricTypeName } from '../../../../../../utils/common';

interface SextforceMetricsTrialsOverviewGridEditProps {
    metricData: any;
    open: boolean;
    onClose: () => void;
    saveSettings: (
        trialId: string,
        platform: string,
        associatedUsername: string,
        goalSpent: number,
        goalSubs: number,
        callBack: () => void,
    ) => void;
    isSavingSettings: boolean;
}

const SextforceMetricsTrialsOverviewGridEdit = (props: SextforceMetricsTrialsOverviewGridEditProps) => {
    const { metricData, open, onClose, saveSettings, isSavingSettings } = props;
    const theme: Theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [platform, setPlatform] = useState<string>('');
    const [associatedUsername, setAssociatedUsername] = useState<string>('');
    const [goalSpent, setGoalSpent] = useState<number>(0);
    const [goalSpentString, setGoalSpentString] = useState<string>('');
    const [goalSubs, setGoalSubs] = useState<number>(0);
    const [goalSubsString, setGoalSubsString] = useState<string>('');
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    useEffect(() => {
        if (metricData) {
            if (metricData.associatedUsername) {
                setAssociatedUsername(metricData.associatedUsername);
            } else {
                setAssociatedUsername('');
            }

            if (metricData.settings) {
                setPlatform(metricData.settings.platform || '');
                setGoalSpent(metricData.settings.goalSpent ? d2f(metricData.settings.goalSpent) : 0);
                setGoalSpentString(metricData.settings.goalSpent ? d2f(metricData.settings.goalSpent).toString() : '');
                setGoalSubs(metricData.settings.goalSubs || 0);
                setGoalSubsString(
                    metricData.settings.goalSubs && metricData.settings.goalSubs !== 0 ? metricData.settings.goalSubs.toString() : '',
                );
            } else {
                setPlatform('');
                setGoalSpent(0);
                setGoalSpentString('');
                setGoalSubs(0);
                setGoalSubsString('');
            }

            setHasChanged(false);
        } else {
            setPlatform('');
            setGoalSpent(0);
            setGoalSpentString('');
            setGoalSubs(0);
            setGoalSubsString('');
        }
    }, [metricData]);

    const handleSaveSettings = () => {
        saveSettings(metricData._id, platform.trim(), associatedUsername.trim(), goalSpent, goalSubs, onClose);
    };

    const handleResetSettings = () => {
        setAssociatedUsername('');
        setPlatform('');
        setGoalSpent(0);
        setGoalSpentString('');
        setGoalSubs(0);
        setGoalSubsString('');
        setHasChanged(true);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={isLargeScreen ? 'lg' : 'xs'}>
            <DialogTitle>
                Edit {metricData && metricTypeName(metricData.type)} Settings -{' '}
                {metricData && metricData.payload && metricData.payload.name}
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    flexGrow={1}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ marginBottom: theme.spacing(2) }}
                >
                    <Grid item xs={12}>
                        Enter the username of the creator or account owner you shared the campaign/trial with for improved tracking and
                        easier search. Select the platform they are active on and enter their username.
                    </Grid>
                    <Grid item xs={12}>
                        Enter the campaign's $$$ goal to get see the campaign's progress and get alerted via Telegram when the goal is
                        reached!
                    </Grid>
                </Grid>

                <Grid container spacing={1} flexGrow={1} alignItems="center" justifyContent="flex-start">
                    <SextforceMetricsBoughtFromSettingsContent
                        metricData={metricData}
                        platform={platform}
                        associatedUsername={associatedUsername}
                        setHasChanged={setHasChanged}
                        goalSpentString={goalSpentString}
                        goalSubsString={goalSubsString}
                        setAssociatedUsername={setAssociatedUsername}
                        setGoalSpent={setGoalSpent}
                        setGoalSpentString={setGoalSpentString}
                        setGoalSubs={setGoalSubs}
                        setGoalSubsString={setGoalSubsString}
                        setPlatform={setPlatform}
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button size="medium" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    disabled={isSavingSettings || !metricData}
                    onClick={() => {
                        handleResetSettings();
                    }}
                >
                    Clear
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    disabled={isSavingSettings || !metricData || !hasChanged}
                    onClick={() => {
                        handleSaveSettings();
                    }}
                >
                    Save
                    {isSavingSettings && <CircularProgress size={20} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceMetricsTrialsOverviewGridEdit;
