import moment from 'moment-timezone';
import Compress from 'compress.js';
import { Buffer } from 'buffer';
import mime from 'mime';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Box, Theme } from '@mui/system';
import useTheme from '@mui/material/styles/useTheme';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
    Alert,
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Snackbar,
    TextField,
    Typography,
} from '@mui/material';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SettingsContext } from '../../../store/SettingsContext';
import SelectTimezone from '../../../components/forms/helpers/SelectTimezone';
import { useDialog } from 'muibox';
import { handleHttpError, handleHttpErrorResponse } from '../../../utils/common';
import AlertCollapsable from '../../../components/common/AlertCollapsable';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const SextforceAgentSubmit = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const params = useParams();
    const dialog = useDialog();
    const [loading, setLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [timezone, setTimezone] = useState<string>(moment.tz.guess());
    const [dateTime, setDateTime] = useState<moment.Moment>(moment());
    const [date, setDate] = useState<moment.Moment>(moment());
    const [time, setTime] = useState<moment.Moment>(moment());
    const [amountString, setAmountString] = useState<string>('');
    const [amount, setAmount] = useState<number | void>();
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [notes, setNotes] = useState<string>('');
    const compress = new Compress();

    // Fetch Sextforce Agents
    const fetchAgent = async () => {
        if ('agentId' in params && params.agentId) {
            const query: string = `${settingsContext.routes.sextforce.publicAgent}${params.agentId}`;

            const data = await fetch(query, {
                method: 'get',
                headers: {
                    apiKey: settingsContext.apiKey,
                },
            }).then(async response => {
                if (response.ok) {
                    try {
                        const json = await response.json();

                        return json;
                    } catch {
                        throw new Error(`Agent not found`);
                    }
                } else {
                    try {
                        const error = await response.json();

                        throw new Error(`${response.status} - ${error.message} ${'extra' in error ? `(${error.extra})` : null}`);
                    } catch {
                        throw new Error(`Agent not found`);
                    }
                }
            });

            return data;
        }

        return [];
    };

    // Retrieve a list of Sextforce Agents
    const { data: agent, error: agentError } = useQuery('agent', fetchAgent, {
        refetchOnWindowFocus: false,
        enabled: 'agentId' in params && params.agentId ? true : false,
        onError: error => {
            dialog.alert((error as any).message as string);
        },
    });

    useEffect(() => {
        if (agent && 'timezone' in agent && agent.timezone) {
            setTimezone(agent.timezone);
        }
    }, [setTimezone, agent]);

    useEffect(() => {
        if (selectedImage) {
            setImageUrl(URL.createObjectURL(selectedImage));
        }
    }, [selectedImage]);

    const dataURItoBlob = (dataURI: string, mimeString: string) => {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        let byteString = Buffer.from(dataURI, 'base64');
        // separate out the mime component
        // write the bytes of the string to an ArrayBuffer
        let ab = new ArrayBuffer(byteString.length);
        let dw = new DataView(ab);

        for (var i = 0; i < byteString.length; i++) {
            if (byteString.at(i)) {
                dw.setUint8(i, byteString.at(i) || 0);
            }
        }

        // write the ArrayBuffer to a blob, and you're done
        return new Blob([ab], { type: mimeString });
    };

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleSuccessfulUpload = () => {
        setSelectedImage(null);
        setNotes('');
        setAmount();
        setAmountString('');
        setSnackbarOpen(true);
    };

    const handleUpload = () => {
        if (!selectedImage || !params || !params.agentId) {
            return;
        }

        // Compress the image
        compress
            .compress([selectedImage], {
                size: 1, // the max size in MB, defaults to 2MB
                quality: 0.7, // the quality of the image, max is 1,
                maxWidth: 1280, // the max width of the output image, defaults to 1920px
                maxHeight: 1280, // the max height of the output image, defaults to 1920px
                resize: true, // defaults to true, set false if you do not want to resize the image width and height
                // rotate: false, // See the rotation section below
            })
            .then(data => {
                // returns an array of compressed images
                const query: string = `${settingsContext.routes.sextforce.agentSubmitSale}${params.agentId}`;

                // Build FormData
                const formData = new FormData();

                formData.append('file', dataURItoBlob(data[0].data, data[0].ext));
                formData.append('filename', selectedImage.name);
                formData.append('timezone', timezone);
                formData.append('dateTime', moment(dateTime).tz(timezone, true).format());
                formData.append('amount', amount ? amount.toLocaleString('en-GB') : '0');
                formData.append('notes', notes);

                // Post request
                fetch(query, {
                    method: 'post',
                    headers: {
                        apiKey: settingsContext.apiKey,
                        // 'Content-Type': 'multipart/form-data',
                    },
                    body: formData,
                })
                    .then(async response => {
                        if (response.ok) {
                            // Done submitting
                            setLoading(false);

                            handleSuccessfulUpload();

                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);

                            // Done submitting
                            setLoading(false);
                        }
                    })
                    .catch(error => {
                        console.error(error);

                        setLoading(false);
                        handleHttpError(error, dialog);
                    });
            });
    };

    const handleDateTimeChanged = (newDate: moment.Moment, newTime: moment.Moment) => {
        const newDateTime = moment({
            year: newDate.year(),
            month: newDate.month(),
            day: newDate.date(),
            hour: newTime.hours(),
            minute: newTime.minutes(),
        });

        setDateTime(newDateTime);
    };

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4) }}>
            {!agentError ? (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(4) }} textAlign="center">
                        Submit Sale for {agent && agent.name}
                    </Typography>

                    <AlertCollapsable
                        openInitially={false}
                        title="How It Works"
                        variant="filled"
                        severity="info"
                        sx={{
                            marginBottom: theme.spacing(4),
                        }}
                    >
                        <p>
                            This is your <strong>unique</strong> sales proof submission form! DO NOT SHARE IT WITH ANYONE!
                        </p>
                        <p>To submit a proof of sale:</p>
                        <ol style={{ lineHeight: 1.5 }}>
                            <li>
                                Select the time zone you are working from (it should get selected automatically based on your browser's
                                location but please double check)
                            </li>
                            <li>
                                Enter the date and time of the sale <strong>EXACTLY</strong> as it appears in the Messages chat
                            </li>
                            <li>
                                Enter the <strong>EXACT</strong> sale amount
                            </li>
                            <li>
                                Upload a screenshot of the sale showing the time of the sale. If you are working on a laptop/desktop, you
                                can simply screenshot the chat (
                                <KeyboardIcon sx={{ fontSize: '14px' }} />{' '}
                                <span
                                    style={{
                                        fontFamily: 'monospace',
                                        color: theme.palette.primary.dark,
                                    }}
                                >
                                    Cmd+Ctrl+Shift+4
                                </span>{' '}
                                on a Mac or <KeyboardIcon sx={{ fontSize: '14px' }} />{' '}
                                <span
                                    style={{
                                        fontFamily: 'monospace',
                                        color: theme.palette.primary.dark,
                                    }}
                                >
                                    PrtScr
                                </span>{' '}
                                on Windows) and press PASTE FROM CLIPBOARD below (your browser will ask you for permission). A preview will
                                confirm the image is loaded.
                            </li>
                            <li>Enter any notes if required</li>
                            <li>Press UPLOAD</li>
                            <li>A message will confirm if the submission was successful</li>
                        </ol>
                    </AlertCollapsable>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(4), displayPrint: 'none' }}>
                        <CardContent>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Grid container spacing={4} alignItems="center">
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">Your Timezone</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <SelectTimezone timezone={timezone} setTimezone={setTimezone} fullWidth={true} />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">EXACT Date of Sale</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <MobileDatePicker
                                            renderInput={props => <TextField {...props} helperText={'DD/MM/YYYY'} />}
                                            label="Date"
                                            value={date}
                                            onChange={newValue => {
                                                setDate(newValue as moment.Moment);
                                                handleDateTimeChanged(newValue as moment.Moment, time);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">EXACT Time of Sale</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <MobileTimePicker
                                            label="Time"
                                            ampm={true}
                                            value={time}
                                            onChange={newValue => {
                                                setTime(newValue as moment.Moment);
                                                handleDateTimeChanged(date, newValue as moment.Moment);
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        marginBottom: theme.spacing(4),
                                                    }}
                                                />
                                            )}
                                            DialogProps={{
                                                sx: {
                                                    '.MuiPickersToolbar-content .Mui-selected': { color: theme.palette.secondary.main },
                                                },
                                            }}
                                        />
                                        <AlertCollapsable openInitially={false} title="Examples" variant="standard" severity="info">
                                            <p>
                                                Make sure to enter the EXACT date and time of the actual sale/tip message like shown here:
                                            </p>
                                            <p>
                                                <img
                                                    src="https://onlystruggles.s3.eu-west-2.amazonaws.com/sextforce/assets/sale_example_1.png"
                                                    alt="Sale Example 1"
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                        maxWidth: 594,
                                                        maxHeight: 222,
                                                        borderWidth: 1,
                                                        borderStyle: 'solid',
                                                        borderColor: theme.palette.info.main,
                                                    }}
                                                />
                                            </p>
                                            <p>
                                                <img
                                                    src="https://onlystruggles.s3.eu-west-2.amazonaws.com/sextforce/assets/sale_example_2.png"
                                                    alt="Sale Example 2"
                                                    style={{
                                                        width: '100%',
                                                        height: 'auto',
                                                        maxWidth: 344,
                                                        maxHeight: 146,
                                                        borderWidth: 1,
                                                        borderStyle: 'solid',
                                                        borderColor: theme.palette.info.main,
                                                    }}
                                                />
                                            </p>
                                        </AlertCollapsable>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant="body1">EXACT Amount</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="amount">Amount</InputLabel>
                                            <OutlinedInput
                                                id="amount"
                                                inputProps={{ inputMode: 'text', pattern: /([0-9]+([.][0-9]*)?|[.][0-9]+)/ }}
                                                value={amountString}
                                                onChange={e => {
                                                    e.preventDefault();

                                                    if (/^([\d]*[,.]?[\d]{0,2})$/.test(e.target.value)) {
                                                        setAmountString(e.target.value);
                                                        const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                                                        if (isNaN(parsed)) {
                                                            setAmount();
                                                        } else {
                                                            setAmount(parsed);
                                                        }
                                                    }
                                                }}
                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                label="Amount"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </CardContent>
                    </Card>
                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(4), displayPrint: 'none' }}>
                        <CardContent>
                            <Grid container spacing={4} alignItems="center">
                                <Grid item xs={12} md={4}>
                                    <Typography variant="body1">Proof of Sale</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <input
                                        accept="image/*"
                                        type="file"
                                        id="select-image"
                                        style={{ display: 'none' }}
                                        onChange={e =>
                                            e.target && e.target.files && e.target.files.length > 0 && setSelectedImage(e.target.files[0])
                                        }
                                    />
                                    <label htmlFor="select-image">
                                        <Button variant="contained" color="primary" component="span">
                                            Upload Image
                                        </Button>
                                    </label>
                                    &nbsp;&nbsp;
                                    <Button
                                        variant="contained"
                                        color="warning"
                                        component="span"
                                        onClick={async () => {
                                            const clipboardItems: ClipboardItems | void = await navigator.clipboard.read().catch(error => {
                                                console.error(error);
                                            });

                                            if (clipboardItems) {
                                                for (const clipboardItem of clipboardItems) {
                                                    for (const type of clipboardItem.types) {
                                                        const blob = await clipboardItem.getType(type);

                                                        if (type.includes('image')) {
                                                            setSelectedImage(
                                                                new File([blob], `image.${mime.getExtension(type)}`, { type }),
                                                            );
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        Paste from Clipboard
                                    </Button>
                                    &nbsp;&nbsp;
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        disabled={!selectedImage}
                                        component="span"
                                        onClick={() => setSelectedImage(null)}
                                    >
                                        Clear
                                    </Button>
                                    <Box mt={2}>
                                        {imageUrl && selectedImage ? (
                                            <img src={imageUrl} alt={selectedImage.name} height="300px" />
                                        ) : (
                                            <Typography variant="body1" color={theme.palette.grey[600]}>
                                                No Preview
                                            </Typography>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(4), displayPrint: 'none' }}>
                        <CardContent>
                            <Grid container spacing={4} alignItems="center">
                                <Grid item xs={12} md={4}>
                                    <Typography variant="body1">Notes</Typography>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        id="notes"
                                        label="Notes"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        value={notes}
                                        onChange={e => setNotes(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Box textAlign={'center'}>
                        <Button
                            variant="contained"
                            disabled={!agent || !selectedImage || loading || !amount}
                            onClick={() => {
                                setLoading(true);
                                handleUpload();
                            }}
                            sx={{ marginBottom: theme.spacing(4), displayPrint: 'none' }}
                        >
                            {loading ? 'Loading...' : 'Upload'}
                        </Button>
                    </Box>

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                        onClose={handleSnackbarClose}
                    >
                        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                            Thank You Agent!
                            <br />
                            Sales Proof Submitted Successfully!
                        </Alert>
                    </Snackbar>
                </>
            ) : (
                <Typography variant="body1">You came to the wrong place</Typography>
            )}
        </Container>
    );
};

export default SextforceAgentSubmit;
