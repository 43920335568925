import { Box, Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { OnlyFansMetricsGroupWithSummary } from '../../../../hooks/useSextforceMetricsGroups';
import SextforceMetricsGroupsTableRow from './SextforceMetricsGroupsTableRow';

type Props = {
    groups: OnlyFansMetricsGroupWithSummary[];
    amountType: 'gross' | 'net';
    openGroupDialog: (groupId: string) => void;
    handleDeleteGroup: (groupId: string) => void;
    theme: Theme;
};

const SextforceMetricsGroupsTable = (props: Props) => {
    const { groups, amountType, openGroupDialog, handleDeleteGroup } = props;

    return (
        <Box
            component={'div'}
            sx={{
                width: '100%',
                overflowX: 'auto',
            }}
        >
            <Table
                width="100%"
                size="small"
                sx={{
                    // no left/right padding on the first and last cells
                    '& td:first-of-type, & th:first-of-type': {
                        paddingLeft: 0,
                    },
                    // Bold header
                    '& th': {
                        fontWeight: 'bold',
                    },
                    // No wrapping
                    whiteSpace: 'nowrap',
                    '& td, & th': {
                        // Small padding on the cells
                        padding: 1,
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell width={45}>&nbsp;</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell align="center" width={70}>
                            Metrics
                        </TableCell>
                        <TableCell align="center" width={140}>
                            Subs. Total
                        </TableCell>
                        <TableCell align="center" width={140}>
                            Total Claimers
                        </TableCell>
                        <TableCell align="center" width={150}>
                            Total {amountType === 'gross' ? 'Gross' : 'Net'}
                        </TableCell>
                        <TableCell align="center" width={90}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={{
                        // remove last bottom border
                        '& tr:last-child td': {
                            borderBottom: 'none',
                        },
                    }}
                >
                    {groups &&
                        groups.map(group => (
                            <SextforceMetricsGroupsTableRow
                                key={group._id}
                                group={group}
                                groups={groups}
                                amountType={amountType}
                                openGroupDialog={openGroupDialog}
                                handleDeleteGroup={handleDeleteGroup}
                            />
                        ))}
                </TableBody>
            </Table>
        </Box>
    );
};

export default SextforceMetricsGroupsTable;
