import { OnlyFansLists, OnlyFansVaultMedias } from './onlyFansTypes';

export interface OnlyFansSubscriberMessageUsersAutoMessageDetailsDelayBeforeSendingMessage {
    fromSeconds: number;
    toSeconds: number;
}

export interface OnlyFansSubscriberMessageUsersAutoMessageDetailsTaggedCreator {
    id: number;
    name: string;
}

export interface OnlyFansSubscriberMessageUsersAutoMessageDetailsTaggedReleaseForm {
    id: number;
    name: string;
}

export interface MomentTimePeriod {
    amount: number;
    unit: string;
}

export interface OnlyFansListIdAndName {
    id: number | OnlyFansLists.Type;
    name: string;
}

export interface OnlyFansSubscriberMessageUsersAutoMessageDetails {
    active: boolean;
    delayBeforeSendingMessage: OnlyFansSubscriberMessageUsersAutoMessageDetailsDelayBeforeSendingMessage | null;
    lockMessagePrice: number | null;
    message: string;
    replaceUsername: string | null;
    mediaFiles: OnlyFansVaultMedias.RestructuredResponseItem[];
    mediaFilesPreviews: number[];
    taggedCreators: OnlyFansSubscriberMessageUsersAutoMessageDetailsTaggedCreator[];
    taggedReleaseForms?: OnlyFansSubscriberMessageUsersAutoMessageDetailsTaggedReleaseForm[];
    autoUnsendTimerPeriod: MomentTimePeriod | null;
    delayBetweenMessages: number | null;
    excludeLists: OnlyFansListIdAndName[] | null;
    excludeSpentCondition: string | null;
    excludeSpentAmount: number | null;
    excludeSpentPeriod: MomentTimePeriod | null;
    excludeMessagedInPeriod: MomentTimePeriod | null;
    skipFansActive: boolean;
    skipFollowingActive: boolean;
    skipExpiredFans: boolean;
}

export const createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails = (
    active?: boolean,
): OnlyFansSubscriberMessageUsersAutoMessageDetails => {
    return {
        active: active || false,
        delayBeforeSendingMessage: null,
        lockMessagePrice: null,
        message: '',
        replaceUsername: null,
        mediaFiles: [],
        mediaFilesPreviews: [],
        taggedCreators: [],
        taggedReleaseForms: [],
        autoUnsendTimerPeriod: null,
        delayBetweenMessages: null,
        excludeLists: null,
        excludeSpentCondition: null,
        excludeSpentAmount: null,
        excludeSpentPeriod: null,
        excludeMessagedInPeriod: null,
        skipFansActive: false,
        skipFollowingActive: false,
        skipExpiredFans: false,
    };
};
