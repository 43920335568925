import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export interface SextforceAgents {
    _id?: string;
    subscriberId: string;
    fraction: object;
    type?: string;
    role?: string;
    commissionPercent: number;
    name: string;
    urlId?: string;
}

const useSextforceAgents = (userId: string | undefined) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const axios = useAxios();

    // Fetch Fans Counters
    const fetchData = async (): Promise<SextforceAgents[]> => {
        const query: string = `${settingsContext.routes.sextforce.base}${userId}/agents`;

        const data = await axios
            .get(query)
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    return useQuery(['sextforceAgents', userId], () => fetchData(), {
        refetchOnWindowFocus: false,
        // 5 minutes
        staleTime: 60 * 1000 * 5,
        enabled: userContext.jwtToken && settingsContext.apiKey && userId ? true : false,
    });
};

export default useSextforceAgents;
