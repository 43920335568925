import { Card, CardContent, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import dinero from 'dinero.js';
import React from 'react';
import { Bar, CartesianGrid, ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { titleCase } from '../../../../utils/common';

interface TotalSales {
    total: number;
    resubscription: number;
    resubscriptionCount: number;
    subscription: number;
    subscriptionCount: number;
    tip: number;
    tipCount: number;
    message: number;
    messageCount: number;
    post: number;
    postCount: number;
    stream: number;
    streamCount: number;
}

const CustomTooltip = ({ active, payload, showEarningsAsGross, theme }: any) => {
    if (active && payload && payload.length > 0) {
        return (
            <div>
                <Grid
                    container
                    flexGrow={0}
                    alignItems="center"
                    spacing={1}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 4,
                        border: '1px solid #999',
                        maxWidth: 280,
                    }}
                >
                    {payload[0].payload && payload[0].payload.name && (
                        <Grid item xs={12}>
                            <span style={{ fontWeight: 'bold' }}>{titleCase(payload[0].payload.name)}</span>
                        </Grid>
                    )}
                    {payload.map((item: any, index: number) => (
                        <React.Fragment key={index}>
                            <Grid item xs={12}>
                                {item.dataKey === 'count' && (
                                    <Grid container flexGrow={1} alignItems="center">
                                        <Grid item xs sx={{ color: theme.palette.primary.main }}>
                                            Count:
                                        </Grid>
                                        <Grid
                                            item
                                            xs="auto"
                                            textAlign={'right'}
                                            sx={{ color: theme.palette.primary.main, paddingRight: '8px', fontFamily: 'monospace' }}
                                        >
                                            {item.value.toLocaleString()}
                                        </Grid>
                                    </Grid>
                                )}
                                {item.dataKey === 'total' && (
                                    <Grid container flexGrow={1} alignItems="center">
                                        <Grid item xs sx={{ color: theme.palette.secondary.main }}>
                                            Total Sales:
                                        </Grid>
                                        <Grid
                                            item
                                            xs="auto"
                                            textAlign={'right'}
                                            sx={{ color: theme.palette.secondary.main, paddingRight: '8px', fontFamily: 'monospace' }}
                                        >
                                            {dinero({
                                                amount: item.value ? item.value : 0,
                                                currency: 'USD',
                                            }).toFormat()}{' '}
                                            <small>({showEarningsAsGross ? 'gross' : 'net'})</small>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </div>
        );
    }

    return null;
};

type Props = {
    totalSales: TotalSales;
    totalSalesLoading: boolean;
    showEarningsAsGross: boolean;
    theme: Theme;
};

const SextforceMetricsSalesByProductGraph = (props: Props) => {
    const { totalSales, totalSalesLoading, showEarningsAsGross, theme } = props;
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const data: { name: string; total: number; count: number }[] = totalSalesLoading
        ? []
        : [
              {
                  name: largeScreen ? 'Subcription' : 'Subs.',
                  total: showEarningsAsGross ? totalSales.subscription : totalSales.subscription * 0.8,
                  count: totalSales.subscriptionCount,
              },
              {
                  name: largeScreen ? 'Resubcription' : 'Resubs.',
                  total: showEarningsAsGross ? totalSales.resubscription : totalSales.resubscription * 0.8,
                  count: totalSales.resubscriptionCount,
              },
              {
                  name: 'Tip',
                  total: showEarningsAsGross ? totalSales.tip : totalSales.tip * 0.8,
                  count: totalSales.tipCount,
              },
              {
                  name: 'Message',
                  total: showEarningsAsGross ? totalSales.message : totalSales.message * 0.8,
                  count: totalSales.messageCount,
              },
              {
                  name: 'Post',
                  total: showEarningsAsGross ? totalSales.post : totalSales.post * 0.8,
                  count: totalSales.postCount,
              },
              {
                  name: 'Stream',
                  total: showEarningsAsGross ? totalSales.stream : totalSales.stream * 0.8,
                  count: totalSales.streamCount,
              },
          ];

    const Chart = (chartData: any) => (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={chartData}
                margin={{
                    top: 5,
                    right: largeScreen ? 10 : 0,
                    left: largeScreen ? -10 : -20,
                    bottom: largeScreen ? 0 : 30,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.grey[400]} />
                <XAxis
                    dataKey="name"
                    name="name"
                    interval={0}
                    tick={{ fontSize: 13 }}
                    angle={largeScreen ? 0 : -45}
                    textAnchor={largeScreen ? 'middle' : 'end'}
                />
                <YAxis
                    yAxisId="count"
                    dataKey={'count'}
                    scale={'linear'}
                    name="Count"
                    domain={[0, (dataMax: number) => dataMax * 1.1]}
                    tickFormatter={(value: number) => value.toLocaleString()}
                    interval={0}
                    tick={{ fill: theme.palette.primary.main, fontSize: 13 }}
                />
                <Bar key={'count'} yAxisId={'count'} type="monotone" dataKey={'count'} maxBarSize={20} fill={theme.palette.primary.main} />
                <YAxis
                    yAxisId="total"
                    dataKey={'total'}
                    scale={'linear'}
                    name="Total Sales"
                    domain={[0, (dataMax: number) => dataMax * 1.1]}
                    tickFormatter={(value: number) => dinero({ amount: value, currency: 'USD', precision: 2 }).toFormat('$0,0')}
                    interval={0}
                    tick={{ fill: theme.palette.secondary.main, fontSize: 13 }}
                    orientation="right"
                />
                <Line
                    yAxisId="total"
                    type="linear"
                    dataKey="total"
                    stroke={theme.palette.secondary.main}
                    activeDot={{ r: 3 }}
                    dot={{ r: 2 }}
                />
                <Tooltip content={<CustomTooltip payload={chartData.data} showEarningsAsGross={showEarningsAsGross} theme={theme} />} />
            </ComposedChart>
        </ResponsiveContainer>
    );

    return (
        <Card sx={{ width: '100%', marginBottom: theme.spacing(2) }}>
            <CardContent>
                <Grid container flexGrow={1} spacing={0} sx={{ marginBottom: theme.spacing(1) }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ marginBottom: theme.spacing(1) }}>
                            Total Earnings by Product
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                            The following graph shows a preakdown of the total earnings by product (gross).
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container flexGrow={1} spacing={0} sx={{ padding: 0 }}>
                    <Grid item xs={12} sx={{ height: { xs: 200, sm: 300 } }}>
                        {totalSalesLoading ? <Skeleton /> : Chart(data)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsSalesByProductGraph;
