export const arrayOnlyFansSubscriberHostessAutoReplyFreeloadersAction = ['none', 'mute', 'restrict', 'block'];

export type OnlyFansSubscriberHostessAutoReplyFreeloadersAction = typeof arrayOnlyFansSubscriberHostessAutoReplyFreeloadersAction[number];

export interface OnlyFansSubscriberHostessAutoReplyFreeloaders {
    active: boolean;
    spentThreshold: number;
    replies: string[];
    action: OnlyFansSubscriberHostessAutoReplyFreeloadersAction;
    excludeCreators: boolean;
    autoUnrestrict: boolean;
    followingThresholdAmount?: number;
    followingThresholdPeriod?: string;
    skipExpiredFans: boolean;
}
