import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Alert, Container, Grid, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import OverviewCard from '../../../components/common/OverviewCard';
import ServiceActionsBlocks from '../../../components/common/ServiceActions';
import ServiceStatusCard from '../../../components/common/ServiceStatuxCard';
import SharedCreditCard from '../../../components/common/SharedCreditCard';
import MessageUsersScheduledList from '../../../components/services/messageUsers/scheduled/MessageUsersScheduledList';
import MessageUsersOverviewStatus from '../../../components/services/messageUsers/sendMessage/MessageUsersOverviewStatus';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';
import { UserContext } from '../../../store/UserContext';
import { handleHttpError, handleHttpErrorResponse } from '../../../utils/common';

const MessageUsersOverview = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const params = useParams();

    const { data: subscriber } = useSubscriber();
    const [refreshCurrentSession, setRefreshCurrentSession] = useState<boolean>(false);

    // Fetch Total Messages Sent
    const fetchTotalSent = async (): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/totalSent`;

            return fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const { data: totalSent, isLoading: totalSentLoading } = useQuery(['messageUsersTotalSent', subscriber?._id], () => fetchTotalSent(), {
        refetchOnWindowFocus: false,
        // Stall for 3 minutes
        staleTime: 180000,
        enabled: subscriber ? true : false,
    });

    // Fetch Follow-Back Overview
    const fetchCurrentSession = async (): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/currentSessions`;

            return fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const { data: currentSessions, isLoading: currentSessionLoading } = useQuery(
        ['messageUsersCurrentSessions'],
        () => fetchCurrentSession(),
        {
            onSuccess: (data: any) => {
                const isSessionRunning: boolean = data && (data.messageUsers.sessions.length > 0 || data.unsendMessage.sessions.length > 0);
                const isJobInQueue: boolean = data && (data.messageUsers.queue || data.unsendMessage.queue);

                setRefreshCurrentSession(isSessionRunning || isJobInQueue ? true : false);
            },
            refetchOnWindowFocus: false,
            staleTime: 0,
            enabled: subscriber ? true : false,
            refetchInterval: refreshCurrentSession ? 15000 : 0,
        },
    );

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}`} /> Messaging for {subscriber.username}
                    </Typography>
                    <Alert severity="info" sx={{ marginBottom: theme.spacing(2) }}>
                        Please make sure you read the{' '}
                        <Link
                            to={`/subscribers/${subscriber._id}/services/messageUsers/welcome`}
                            style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                        >
                            <strong>Welcome</strong>
                        </Link>{' '}
                        information first!
                    </Alert>
                    <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                        <Grid item xs={12} md={6} lg={4}>
                            <ServiceStatusCard
                                subscriber={subscriber}
                                serviceName="messageUsers"
                                alwaysActive={settingsContext.services.messageUsers.alwaysActive}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <OverviewCard
                                title="Total Messages Sent Since Started"
                                value={totalSent && totalSent.totalSent ? totalSent.totalSent.toLocaleString() : 0}
                                loading={totalSentLoading}
                                icon={<EmojiEventsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                theme={theme}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <SharedCreditCard showTopUpButton={true} />
                        </Grid>
                    </Grid>

                    <MessageUsersOverviewStatus
                        subscriber={subscriber}
                        currentSessions={currentSessions}
                        loading={currentSessionLoading}
                        theme={theme}
                    />

                    <MessageUsersScheduledList subscriber={subscriber} />

                    <ServiceActionsBlocks subscriber={subscriber} actions={settingsContext.services.messageUsers.actions} theme={theme} />
                </>
            )}
        </Container>
    );
};

export default MessageUsersOverview;
