import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Select,
    Stack,
    Theme,
    Typography,
} from '@mui/material';
import dinero from 'dinero.js';
import { ContentState, EditorState } from 'draft-js';
import Joi from 'joi';
import { useDialog } from 'muibox';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    SextforceAutoCampaignTipReply,
    SextforceAutoCampaignTipReplyMessageDetails,
    SextforceAutoCampaignTipReplyMessageDetailsSchema,
    SextforceAutoCampaignTipReplyWithMessageDetails,
} from '../../../../hooks/useSextforceAutoCampaignTipReply';
import useSextforceBigBrotherRestrictedWords from '../../../../hooks/useSextforceBigBrotherRestrictedWords';
import useSubscriberLists from '../../../../hooks/useSubscriberLists';
import useSubscriberMedia from '../../../../hooks/useSubscriberMedia';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { OnlyFansVaultMedias } from '../../../../types/onlyFansTypes';
import { handleHttpError } from '../../../../utils/common';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';
import MessageUsersFormAddMedias from '../../messageUsers/sendMessage/MessageUsersFormAddMedias';
import MessageUsersFormEditor from '../../messageUsers/sendMessage/MessageUsersFormEditor';
import MessageUsersPriceLock from '../../messageUsers/sendMessage/MessageUsersPriceLock';
import MessageUsersTagCreators from '../../messageUsers/sendMessage/MessageUsersTagCreators';
import SextforceLiveStreamAddTippersToListCreateListDialog from '../liveStreams/addTippersToList/SextforceLiveStreamAddTippersToListCreateListDialog';

type Props = {
    subscriber: any;
    autoCampaignTipReply: SextforceAutoCampaignTipReplyWithMessageDetails;
    initialValues: SextforceAutoCampaignTipReplyMessageDetails | null;
    tipOption: number;
    theme: Theme;
    open: boolean;
    onClose: () => void;
    createAutoCampaignTipReplyTipMessageDetails: (
        autoCampaignTipReply: SextforceAutoCampaignTipReply,
        autoCampaignTipReplyTipMessageDetails: SextforceAutoCampaignTipReplyMessageDetails,
    ) => Promise<SextforceAutoCampaignTipReplyMessageDetails | null>;
    updateAutoCampaignTipReplyTipMessageDetails: (
        autoCampaignTipReply: SextforceAutoCampaignTipReply,
        autoCampaignTipReplyTipMessageDetails: SextforceAutoCampaignTipReplyMessageDetails,
    ) => Promise<SextforceAutoCampaignTipReplyMessageDetails | null>;
    refetchAutoCampaignTipReplies: () => void;
};

const SextforceAutoCampaignTipReplyMessageDetailsDialog = (props: Props) => {
    const {
        subscriber,
        autoCampaignTipReply,
        initialValues,
        tipOption,
        theme,
        open,
        onClose,
        createAutoCampaignTipReplyTipMessageDetails,
        updateAutoCampaignTipReplyTipMessageDetails,
        refetchAutoCampaignTipReplies,
    } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const { data: restrictedWords, isLoading: isLoadingRestrictedWords } = useSextforceBigBrotherRestrictedWords();

    // Loading state
    const [isSaving, setIsSaving] = useState(false);

    // Message details
    const [replyMessageDetails, setReplyMessageDetails] = useState<SextforceAutoCampaignTipReplyMessageDetails>(
        initialValues || {
            autoCampaignReplyId: autoCampaignTipReply._id!,
            tipAmount: tipOption,
            listId: undefined,
            existingTippersProcessed: undefined,
            messageDetails: {
                message: '',
                lockMessagePrice: null,
                mediaFiles: [],
                mediaFilesPreviews: [],
                replaceUsername: null,
                taggedCreators: [],
                taggedReleaseForms: [],
            },
            replyCount: 0,
        },
    );

    // Errors
    const errors: Joi.ValidationErrorItem[] | null =
        SextforceAutoCampaignTipReplyMessageDetailsSchema.validate(replyMessageDetails).error?.details || null;

    // Lists
    const [addTipperToList, setAddTipperToList] = useState<boolean>(false);
    const { data: lists, isLoading: listsLoading, createList, isCreatingList } = useSubscriberLists(addTipperToList);

    const [isCreateListDialogOpen, setIsCreateListDialogOpen] = useState(false);

    const handleCreateList = (listName: string) => {
        createList(listName).then(newList => {
            if (newList) {
                setReplyMessageDetails(prevState => {
                    return {
                        ...prevState,
                        listId: newList.id,
                    };
                });
            }
            setIsCreateListDialogOpen(false);
        });
    };

    // Media
    const { getThumbnail: getSubscriberThumbnail, getAudio: getSubscriberAudio } = useSubscriberMedia();

    // Form
    const [includeMedia, setIncludeMedia] = useState<boolean>(replyMessageDetails.messageDetails.mediaFiles.length > 0 ? true : false);
    const [vaultAlbum, setVaultAlbum] = useState<number>(-1);
    const [medias, setMedias] = useState<OnlyFansVaultMedias.RestructuredResponseItem[]>(
        replyMessageDetails.messageDetails.mediaFiles || [],
    );
    const [vaultAlbumName, setVaultAlbumName] = useState<string | undefined>(undefined);
    const [mediasPreviews, setMediasPreviews] = useState<number[]>(replyMessageDetails.messageDetails.mediaFilesPreviews || []);
    const [thumbnails, setThumbnails] = useState<any[]>([]);
    const [tagCreators, setTagCreators] = useState<boolean>(replyMessageDetails.messageDetails.taggedCreators.length > 0 ? true : false);
    const [taggedCreators, setTaggedCreators] = useState<{ id: number; name: string }[]>(
        replyMessageDetails.messageDetails.taggedCreators || [],
    );
    const [taggedReleaseForms, setTaggedReleaseForms] = useState<{ id: number; name: string }[]>(
        replyMessageDetails.messageDetails.taggedReleaseForms || [],
    );
    const [lockMessage, setLockMessage] = useState<boolean>(replyMessageDetails.messageDetails.lockMessagePrice !== null ? true : false);
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(ContentState.createFromText(replyMessageDetails.messageDetails.message)),
    );
    const [messageHasRestrictedWords, setMessageHasRestrictedWords] = useState<boolean>(false);
    const [messageFoundRestrictedWords, setMessageFoundRestrictedWords] = useState<string[]>([]);
    const [saveTemplate, setSaveTemplate] = useState<boolean>(false);
    const [saveTemplateName, setSaveTemplateName] = useState<string>('');

    const taggedCreatorsCount = replyMessageDetails.messageDetails.taggedCreators.length;
    const taggedReleaseFormsCount = replyMessageDetails.messageDetails.taggedReleaseForms
        ? replyMessageDetails.messageDetails.taggedReleaseForms.length
        : 0;
    const totalTagged = taggedCreatorsCount + taggedReleaseFormsCount;

    useEffect(() => {
        if (open) {
            setReplyMessageDetails(
                initialValues || {
                    autoCampaignReplyId: autoCampaignTipReply._id!,
                    tipAmount: tipOption,
                    listId: undefined,
                    existingTippersProcessed: undefined,
                    messageDetails: {
                        message: '',
                        lockMessagePrice: null,
                        mediaFiles: [],
                        mediaFilesPreviews: [],
                        replaceUsername: null,
                        taggedCreators: [],
                    },
                    replyCount: 0,
                },
            );

            setAddTipperToList(initialValues ? (initialValues.listId ? true : false) : false);
            setIncludeMedia(
                initialValues
                    ? initialValues.messageDetails.mediaFiles.length > 0
                        ? true
                        : false
                    : replyMessageDetails.messageDetails.mediaFiles.length > 0
                    ? true
                    : false,
            );
            setVaultAlbum(-1);
            setVaultAlbumName(undefined);
            setMedias(initialValues?.messageDetails.mediaFiles || []);
            setMediasPreviews(initialValues?.messageDetails.mediaFilesPreviews || []);
            setThumbnails([]);
            setTagCreators(
                initialValues
                    ? initialValues.messageDetails.taggedCreators.length > 0 ||
                      (initialValues.messageDetails.taggedReleaseForms && initialValues.messageDetails.taggedReleaseForms.length > 0)
                        ? true
                        : false
                    : replyMessageDetails.messageDetails.taggedCreators.length > 0 ||
                      (replyMessageDetails.messageDetails.taggedReleaseForms &&
                          replyMessageDetails.messageDetails.taggedReleaseForms.length > 0)
                    ? true
                    : false,
            );
            setTaggedCreators(initialValues ? initialValues.messageDetails.taggedCreators : []);
            setTaggedReleaseForms(
                initialValues && initialValues.messageDetails.taggedReleaseForms ? initialValues.messageDetails.taggedReleaseForms : [],
            );
            setLockMessage(initialValues ? (initialValues.messageDetails.lockMessagePrice !== null ? true : false) : false);
            setEditorState(EditorState.createWithContent(ContentState.createFromText(initialValues?.messageDetails.message || '')));
            setMessageHasRestrictedWords(false);
            setMessageFoundRestrictedWords([]);
            setSaveTemplate(false);
            setSaveTemplateName('');
        }
    }, [autoCampaignTipReply._id, initialValues, open]);

    // Check the message doesn't contain any OnlyFans restricted words
    const checkForRestrictedWords = useCallback(
        (text: string) => {
            if (text && !isLoadingRestrictedWords && restrictedWords && subscriber) {
                const lowerCaseMessage = text.toLowerCase();
                const wordsInMessage = lowerCaseMessage.split(/[\s\n/,.]+/);

                const restrictedWordsFound = wordsInMessage.filter(word => restrictedWords.has(word));

                if (subscriber.sextforce?.bigBrother?.restrictedWordsWhiteList?.includes(restrictedWordsFound)) {
                    // Remove any words that are in the whitelist
                    restrictedWordsFound.filter(word => !subscriber.sextforce?.bigBrother?.restrictedWordsWhiteList?.includes(word));
                }

                setMessageHasRestrictedWords(restrictedWordsFound.length > 0 ? true : false);
                setMessageFoundRestrictedWords(restrictedWordsFound);
            } else {
                setMessageHasRestrictedWords(false);
                setMessageFoundRestrictedWords([]);
            }
        },
        [isLoadingRestrictedWords, restrictedWords, subscriber],
    );

    useEffect(() => {
        setReplyMessageDetails(prevState => {
            return {
                ...prevState,
                messageDetails: {
                    ...prevState.messageDetails,
                    mediaFiles: medias,
                },
            };
        });
    }, [medias]);

    useEffect(() => {
        setReplyMessageDetails(prevState => {
            return {
                ...prevState,
                messageDetails: {
                    ...prevState.messageDetails,
                    mediaFilesPreviews: mediasPreviews,
                },
            };
        });
    }, [mediasPreviews]);

    useEffect(() => {
        setReplyMessageDetails(prevState => {
            return {
                ...prevState,
                messageDetails: {
                    ...prevState.messageDetails,
                    taggedCreators: taggedCreators,
                },
            };
        });
    }, [taggedCreators]);

    useEffect(() => {
        setReplyMessageDetails(prevState => {
            return {
                ...prevState,
                messageDetails: {
                    ...prevState.messageDetails,
                    taggedReleaseForms: taggedReleaseForms,
                },
            };
        });
    }, [taggedReleaseForms]);

    // Media
    const getThumbnail = (thumbId: number, thumbUrl: string) => {
        getSubscriberThumbnail(subscriber, thumbId, thumbUrl)?.then(blob => {
            if (blob) {
                setThumbnails(prevState => {
                    return [...prevState, { thumbId, blob }];
                });
            } else {
                setThumbnails(prevState => {
                    return [...prevState, { thumbId, blob: null }];
                });
            }
        });
    };

    const getAudio = (url: string) => getSubscriberAudio(subscriber, url);

    // Save the message details
    const handleSave = () => {
        const updateAutoRepost = async () => {
            if (
                userContext.jwtToken &&
                settingsContext.apiKey &&
                'userId' in params &&
                params.userId &&
                subscriber &&
                autoCampaignTipReply &&
                autoCampaignTipReply._id
            ) {
                setIsSaving(true);

                if (initialValues) {
                    // Update the existing message details
                    const updatedMessageDetails = await updateAutoCampaignTipReplyTipMessageDetails(
                        autoCampaignTipReply,
                        replyMessageDetails,
                    ).catch(error => {
                        console.error(error);
                        setIsSaving(false);
                        handleHttpError(error, dialog);
                    });

                    if (updatedMessageDetails && updatedMessageDetails._id) {
                        dialog
                            .alert({
                                title: 'Auto Tip Campaign Tip Reply',
                                message: 'Auto Tip Campaign Tip Reply updated successfully.',
                            })
                            .then(() => {
                                onClose();
                                refetchAutoCampaignTipReplies();
                            })
                            .catch(() => {});
                    } else {
                        dialog
                            .alert({
                                title: 'Auto Tip Campaign Tip Reply',
                                message: 'Auto Tip Campaign Tip Reply could not be updated.',
                            })
                            .then(() => {})
                            .catch(() => {});
                    }
                } else {
                    // Create a new message details
                    const createdMessageDetails = await createAutoCampaignTipReplyTipMessageDetails(
                        autoCampaignTipReply,
                        replyMessageDetails,
                    ).catch(error => {
                        console.error(error);
                        setIsSaving(false);
                        handleHttpError(error, dialog);
                    });

                    if (createdMessageDetails && createdMessageDetails._id) {
                        dialog
                            .alert({
                                title: 'Auto Tip Campaign Tip Reply',
                                message: 'Auto Tip Campaign Tip Reply saved successfully.',
                            })
                            .then(() => {
                                onClose();
                                refetchAutoCampaignTipReplies();
                            })
                            .catch(() => {});
                    } else {
                        dialog
                            .alert({
                                title: 'Auto Tip Campaign Tip Reply',
                                message: 'Auto Tip Campaign Tip Reply could not be saved.',
                            })
                            .then(() => {})
                            .catch(() => {});
                    }
                }

                setIsSaving(false);
            }
        };

        updateAutoRepost();
    };

    // Close the dialog
    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth disableEscapeKeyDown>
            <StyledCardTitleBar title="Add Auto Tip Campaign Tip Reply" theme={theme} />
            <DialogContent>
                <DialogContentText>
                    <Typography variant="h6" gutterBottom>
                        {autoCampaignTipReply.name}
                    </Typography>
                    Enter the message details for the tip amount of{' '}
                    <strong>{dinero({ amount: Math.trunc(tipOption * 100), currency: 'USD' }).toFormat()}</strong>. This message will be
                    sent automatically whenever a subscriber tips/contributes this amount to the campaign.
                </DialogContentText>

                <Grid
                    container
                    spacing={1}
                    flexGrow={0}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12}>
                        <Card variant="elevation">
                            <CardContent>
                                <Grid container spacing={1} flexGrow={1} alignItems="flex-start" sx={{ pr: 1 }}>
                                    <Grid item xs>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    size="medium"
                                                    checked={addTipperToList}
                                                    onChange={(e, checked) => {
                                                        setAddTipperToList(checked);
                                                    }}
                                                />
                                            }
                                            label={<Typography variant="h6">Add Tippers to List?</Typography>}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Stack direction={'column'} spacing={0}>
                                            <Grid container spacing={1} alignItems="center" justifyContent="center">
                                                <Grid item xs>
                                                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                                                        Select Target List
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Stack direction={'row'} spacing={1}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        value={listsLoading || isCreatingList ? 0 : replyMessageDetails.listId || 0}
                                                        onChange={e => {
                                                            setReplyMessageDetails(prevState => {
                                                                return {
                                                                    ...prevState,
                                                                    listId: e.target.value as number,
                                                                };
                                                            });
                                                        }}
                                                        fullWidth
                                                        size="small"
                                                        disabled={!addTipperToList}
                                                    >
                                                        {(listsLoading || isCreatingList) && (
                                                            <MenuItem value={0} disabled sx={{ opacity: 0.5 }}>
                                                                <em>Loading...</em>
                                                            </MenuItem>
                                                        )}
                                                        {!listsLoading && !isCreatingList && lists && lists.length === 0 && (
                                                            <MenuItem value={0} disabled sx={{ opacity: 0.5 }}>
                                                                <em>No Lists Found</em>
                                                            </MenuItem>
                                                        )}
                                                        {!listsLoading && !isCreatingList && lists && lists.length > 0 && (
                                                            <MenuItem value={0} disabled sx={{ opacity: 0.5 }}>
                                                                <em>Select a List</em>
                                                            </MenuItem>
                                                        )}
                                                        {!listsLoading &&
                                                            !isCreatingList &&
                                                            lists &&
                                                            lists
                                                                .slice()
                                                                .sort((a: any, b: any) => {
                                                                    return a.name.localeCompare(b.name);
                                                                })
                                                                .map((list: any) => (
                                                                    <MenuItem value={list.id} key={list.id}>
                                                                        <Grid container spacing={0} sx={{ margin: 0, padding: 0 }}>
                                                                            <Grid item xs>
                                                                                {list.name}
                                                                            </Grid>
                                                                            <Grid item xs="auto" sx={{ paddingRight: theme.spacing(2) }}>
                                                                                <span style={{ color: theme.palette.grey[700] }}>
                                                                                    {(list.usersCount as number).toLocaleString()} Users
                                                                                </span>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </MenuItem>
                                                                ))}
                                                    </Select>
                                                </FormControl>
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    disabled={isCreatingList || !addTipperToList}
                                                    onClick={() => {
                                                        setIsCreateListDialogOpen(true);
                                                    }}
                                                >
                                                    Create
                                                </Button>
                                                <SextforceLiveStreamAddTippersToListCreateListDialog
                                                    open={isCreateListDialogOpen}
                                                    onClose={() => setIsCreateListDialogOpen(false)}
                                                    handleSubmit={handleCreateList}
                                                    theme={theme}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <MessageUsersFormAddMedias
                            serviceName="autoSendWelcomeMessage"
                            includeMedia={includeMedia}
                            setIncludeMedia={setIncludeMedia}
                            vaultAlbum={vaultAlbum}
                            setVaultAlbum={setVaultAlbum}
                            vaultAlbumName={vaultAlbumName}
                            setVaultAlbumName={setVaultAlbumName}
                            medias={medias}
                            setMedias={setMedias}
                            mediasPreviews={mediasPreviews}
                            setMediasPreviews={setMediasPreviews}
                            thumbnails={thumbnails}
                            getThumbnail={getThumbnail}
                            getAudio={getAudio}
                            tagCreators={tagCreators}
                            setTagCreators={setTagCreators}
                            taggedCreators={taggedCreators}
                            setTaggedCreators={setTaggedCreators}
                            taggedReleaseForms={taggedReleaseForms}
                            setTaggedReleaseForms={setTaggedReleaseForms}
                            session={null}
                            disabled={false}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {includeMedia && (
                            <MessageUsersTagCreators
                                subscriber={subscriber}
                                tagCreators={tagCreators}
                                setTagCreators={setTagCreators}
                                taggedCreators={taggedCreators}
                                setTaggedCreators={setTaggedCreators}
                                taggedReleaseForms={taggedReleaseForms}
                                setTaggedReleaseForms={setTaggedReleaseForms}
                                session={null}
                                theme={theme}
                                disabled={false}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <MessageUsersPriceLock
                            lockMessage={lockMessage}
                            setLockMessage={setLockMessage}
                            lockMessagePrice={replyMessageDetails.messageDetails.lockMessagePrice || 0}
                            setLockMessagePrice={lockMessagePrice => {
                                setReplyMessageDetails({
                                    ...replyMessageDetails,
                                    messageDetails: {
                                        ...replyMessageDetails.messageDetails,
                                        lockMessagePrice,
                                    },
                                });
                            }}
                            medias={medias}
                            thumbnails={thumbnails}
                            mediasPreviews={mediasPreviews}
                            setMediasPreviews={setMediasPreviews}
                            getAudio={getAudio}
                            session={null}
                            theme={theme}
                            disabled={false}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <MessageUsersFormEditor
                            subscriber={subscriber}
                            message={replyMessageDetails.messageDetails.message || ''}
                            setMessage={message => {
                                setReplyMessageDetails(prevState => {
                                    return {
                                        ...prevState,
                                        messageDetails: {
                                            ...prevState.messageDetails,
                                            message,
                                        },
                                    };
                                });
                            }}
                            editorState={editorState}
                            setEditorState={setEditorState}
                            messageHasRestrictedWords={messageHasRestrictedWords}
                            messageFoundRestrictedWords={messageFoundRestrictedWords}
                            checkForRestrictedWords={checkForRestrictedWords}
                            usernameReplacement={replyMessageDetails.messageDetails.replaceUsername || ''}
                            setUsernameReplacement={(replaceUsername: string) => {
                                setReplyMessageDetails(prevState => {
                                    return {
                                        ...prevState,
                                        messageDetails: {
                                            ...prevState.messageDetails,
                                            replaceUsername,
                                        },
                                    };
                                });
                            }}
                            taggedCreators={taggedCreators}
                            taggedReleaseForms={taggedReleaseForms}
                            lockMessage={lockMessage}
                            lockMessagePrice={replyMessageDetails.messageDetails.lockMessagePrice || 0}
                            saveTemplate={saveTemplate}
                            setSaveTemplate={setSaveTemplate}
                            saveTemplateName={saveTemplateName}
                            setSaveTemplateName={setSaveTemplateName}
                            session={null}
                            theme={theme}
                            disabled={false}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button
                    color="error"
                    size="medium"
                    disabled={isSaving}
                    onClick={() => {
                        handleClose({}, 'escapeKeyDown');
                    }}
                >
                    Close
                </Button>
                <LoadingButton
                    color="secondary"
                    variant="contained"
                    size="medium"
                    loading={isSaving}
                    disabled={
                        !!errors ||
                        !autoCampaignTipReply._id ||
                        messageHasRestrictedWords ||
                        (includeMedia && !medias.length) ||
                        (tagCreators && totalTagged === 0) ||
                        (lockMessage &&
                            (!replyMessageDetails.messageDetails.lockMessagePrice ||
                                replyMessageDetails.messageDetails.lockMessagePrice < 3)) ||
                        replyMessageDetails.messageDetails.message.trim().length === 0 ||
                        (addTipperToList && !replyMessageDetails.listId)
                    }
                    onClick={() => {
                        handleSave();
                    }}
                >
                    {initialValues ? 'Update Reply' : 'Create Reply'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceAutoCampaignTipReplyMessageDetailsDialog;
