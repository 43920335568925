import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Grid, IconButton, TextField, ToggleButton, ToggleButtonGroup, Tooltip, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import axios from 'axios';
import { parse as parseCsv } from 'json2csv';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import useSubscribers from '../../../../../../hooks/useSubscribers';
import { SettingsContext } from '../../../../../../store/SettingsContext';
import { UserContext } from '../../../../../../store/UserContext';
import { d2f, handleHttpError, metricTypeName } from '../../../../../../utils/common';
import SelectTimezone from '../../../../../forms/helpers/SelectTimezone';
import SextforceMetricsCrossReferenceEarningsSubscriberSelector from '../../SextforceMetricsCrossReferenceEarningsSubscriberSelector';
import SextforceMetricsGroups from '../../SextforceMetricsGroups';
import SextforceMetricsTrialsClaimsTodayOverview from './SextforceMetricsTrialsClaimsTodayOverview';
import SextforceMetricsTrialsOverviewGrid from './SextforceMetricsTrialsOverviewGrid';
import SextforceMetricsTrialsOverviewSelector from './SextforceMetricsTrialsOverviewSelector';

/**
 * Transforms the transactions data rows from the server to a format ready for export to CSV or Excel
 * @param rows Transactions rows
 * @param timezone Timezone string to convert the transaction time to
 * @returns Transformed data for export
 */
const transformTransactionsForExport = (rows: any[], amountType: 'gross' | 'net', timezone: string) => {
    const headers = [
        {
            label: 'ID',
            value: 'foreignId',
        },
        {
            label: 'Type',
            value: 'type',
        },
        {
            label: 'Active',
            value: 'active',
        },
        {
            label: `Created At (${timezone})`,
            value: 'createdAt',
        },
        {
            label: 'OnlyFans Name',
            value: 'name',
        },
        {
            label: 'Platform',
            value: 'platform',
        },
        {
            label: '@ Username',
            value: 'associatedUsername',
        },
        {
            label: 'Claims Today',
            value: 'claimedToday',
        },
        {
            label: 'Claims Total',
            value: 'claimsCount',
        },
        {
            label: 'Claims Goal',
            value: 'goalClaims',
        },
        {
            label: 'Cost Per Fan Net',
            value: 'cpf',
        },
        {
            label: `Earnings ${amountType === 'gross' ? 'Gross' : 'Net'} Total (USD)`,
            value: 'earningsTotal',
        },
        {
            label: `Earning ${amountType === 'gross' ? 'Gross' : 'Net'} Goal (USD)`,
            value: 'goalSpent',
        },
    ];

    const data = rows.map(row => {
        const earningsTotal =
            row.payload && row.payload.earningsTotal && typeof row.payload.earningsTotal === 'object'
                ? d2f(row.payload.earningsTotal) * (amountType === 'gross' ? 1 : 0.8)
                : 0;
        const claimsCount = (row.payload && row.payload.counters && row.payload.counters.claimsCount) || 0;
        const cpf = row.payload && row.payload.cpf ? Math.round(row.payload.cpf * 100) / 100 : 0;

        return {
            foreignId: row.foreignId,
            type: metricTypeName(row.type),
            active: row.payload.active || false,
            createdAt: (row.payload && moment(row.payload.createdAt).tz(timezone).format('L hh:mm a')) || '',
            name: (row.payload && row.payload.name) || '',
            platform: (row.settings && row.settings.platform) || '',
            associatedUsername: row.associatedUsername || '',
            claimedToday: row.claimedToday || 0,
            claimsCount,
            goalClaims: (row.settings && row.settings.goalSubs) || 0,
            cpf,
            earningsTotal,
            goalSpent: (row.settings && row.settings.goalSpent && row.settings.goalSpent * (amountType === 'gross' ? 1 : 0.8)) || 0,
        };
    });

    return { headers, rows: data };
};

type Props = {
    subscriber: any | null;
    timezone: string;
    setTimezone: (timezone: string) => void;
    theme: Theme;
};

const SextforceMetricsTrialsOverview = (props: Props) => {
    const { subscriber, timezone, setTimezone, theme } = props;

    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const queryClient = useQueryClient();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const [date, setDate] = useState<Date | null>(moment().startOf('day').add(1, 'hour').toDate());
    const [trialsSelectorType, setTrialsSelectorType] = useState<{ includeInactive: boolean; type: string }>({
        includeInactive: false,
        type: 'trialLinkTrial',
    });
    const [isSavingSettings, setIsSavingSettings] = useState<boolean>(false);
    const [amountType, setAmountType] = useState<'gross' | 'net'>(
        localStorage.getItem('showEarningsAsGross') !== null && localStorage.getItem('showEarningsAsGross') === 'true' ? 'gross' : 'net',
    );
    const [reportSort, setReportSort] = useState<GridSortModel>([
        {
            field: 'payload.createdAt',
            sort: 'desc',
        },
    ]);
    // const [filter, setFilter] = useState<GridFilterModel>({ items: [] });
    const [search, setSearch] = useState<string>('');
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        pageSize: 20,
        page: 0,
    });
    const [trialsFullForDownloadLoading, setTrialsFullForDownloadLoading] = useState<boolean>(false);

    const [crossReferenceSubscriberId, setCrossReferenceSubscriberId] = useState<string>('none');
    const { data: subscribers, isLoading: subscribersLoading } = useSubscribers();

    useEffect(() => {
        if (subscribers && Array.isArray(subscribers) && crossReferenceSubscriberId === 'none' && subscribers.length === 2) {
            const subscriber = subscribers.find(subscriber => subscriber._id !== params.userId);

            if (subscriber) {
                setCrossReferenceSubscriberId(subscriber._id);
            }
        }
    }, [subscribers, crossReferenceSubscriberId, params.userId]);

    // Claims Today Overview
    const [hoveredClaimsTodayOverviewMetricId, setHoveredClaimsTodayOverviewMetricId] = useState<string>('');

    // Fetch Full Report for download
    const fetchTrialsOverviewFullFordownload = async (includeInactive: boolean, type: string): Promise<any[]> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/trialsOverview?${new URLSearchParams({
                includeInactive: includeInactive ? 'true' : 'false',
                type,
                startDate: moment(date).tz(timezone, true).startOf('day').format(),
                endDate: moment(date).tz(timezone, true).endOf('day').format(),
                search,
                sort: reportSort && reportSort.length > 0 ? `${reportSort[0].field}=${reportSort[0].sort}` : '',
            })}`;

            const data = await axios
                .get(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(response => response.data as any[])
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);

                    return [];
                });

            return data;
        }

        return [];
    };

    // Fetch Paginated Report
    const fetchTrialsOverview = async (
        includeInactive: boolean,
        type: string,
    ): Promise<{ data: any[]; metadata: { total: number; earningsTotal: any; claimsTotal: number } }> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/trialsOverviewPaginated?${new URLSearchParams({
                includeInactive: includeInactive ? 'true' : 'false',
                type,
                search,
                page: paginationModel.page.toString(),
                pageSize: paginationModel.pageSize.toString(),
                sort: reportSort && reportSort.length > 0 ? `${reportSort[0].field}=${reportSort[0].sort}` : '',
                startDate: moment(date).tz(timezone, true).startOf('day').format(),
                endDate: moment(date).tz(timezone, true).endOf('day').format(),
            })}`;

            const data = await axios
                .get(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(response => response.data as { data: any[]; metadata: { total: number; earningsTotal: any; claimsTotal: number } })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);

                    return { data: [], metadata: { total: 0, earningsTotal: 0, claimsTotal: 0 } };
                });

            return data;
        }

        return { data: [], metadata: { total: 0, earningsTotal: 0, claimsTotal: 0 } };
    };

    const { data: trials, isLoading: trialsLoading } = useQuery(
        ['metricsTrialsOverviewPaginated', timezone, trialsSelectorType, subscriber, search, paginationModel, reportSort],
        () => fetchTrialsOverview(trialsSelectorType.includeInactive, trialsSelectorType.type),
        {
            refetchOnWindowFocus: false,
            // Stale time 5 minutes
            staleTime: 60 * 1000 * 5,
            enabled: subscriber ? true : false,
        },
    );

    // ** SAVE SETTINGS **

    const saveSettings = (
        trialId: string,
        platform: string,
        associatedUsername: string,
        goalSpent: number,
        goalSubs: number,
        callBack: () => void,
    ) => {
        const doUpdate = async () => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.metrics.base}${params.userId}/trials/${trialId}`;

                setIsSavingSettings(true);

                const body: any = {
                    ...(platform !== '' && { platform }),
                    ...(associatedUsername !== '' && { associatedUsername }),
                    ...(goalSpent !== 0 && { goalSpent }),
                    ...(goalSubs !== 0 && { goalSubs }),
                };

                // Update campaign settings
                const updatedMetric = await axios
                    .put(query, JSON.stringify(body), {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(response => response.data)
                    .catch(error => {
                        console.error(error);
                        setIsSavingSettings(false);
                        handleHttpError(error, dialog);
                    });

                // Update the query data
                const newTrials = trials ? { data: [...trials.data], metadata: trials.metadata } : { data: [], metadata: { total: 0 } };
                const updatedTrialIndex: number = newTrials.data.findIndex((trial: any) => trial._id === updatedMetric._id);

                if (updatedTrialIndex) {
                    newTrials.data[updatedTrialIndex].associatedUsername = updatedMetric.associatedUsername || '';
                    newTrials.data[updatedTrialIndex].settings = updatedMetric.settings || {};
                    newTrials.data[updatedTrialIndex].roi = updatedMetric.roi || {};

                    queryClient.setQueryData(['metricsTrialsOverview'], newTrials);
                }

                setIsSavingSettings(false);
                callBack();
            }
        };

        doUpdate();
    };

    // Convert report to CSV format and start file download
    const handleDownloadReportCsv = (data: any[]) => {
        if (!data) {
            return;
        }

        const transformedData: any = transformTransactionsForExport(data, amountType, timezone);

        // Format JSON data to CSV
        const csv = parseCsv(transformedData.rows, {
            fields: transformedData.headers,
            transforms: [],
        });

        // Convert CSV to Blob
        const blob: Blob = new Blob([csv], { type: 'text/csv' });

        // Create a descriptive filename
        const filename: string = `trials_${moment().tz(timezone, true).format('YYYY-MM-DD')}.csv`;

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode && link.parentNode.removeChild(link);
    };

    // Convert to Excel format and start file download
    const handleDownloadReportExcel = (data: any[]) => {
        if (!data) {
            return;
        }

        const transformedData: any = transformTransactionsForExport(data, amountType, timezone);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [transformedData.headers.map((header: any) => header.label)]);

        XLSX.utils.sheet_add_json(worksheet, transformedData.rows, {
            origin: 'A2',
            skipHeader: true,
            header: transformedData.headers.map((header: any) => header.value),
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Trials');

        // Create a descriptive filename
        const filename: string = `trials_${moment().tz(timezone, true).format('YYYY-MM-DD')}.xlsx`;

        XLSX.writeFile(workbook, filename, { type: 'file', bookType: 'xlsx' });
    };

    return (
        <>
            <Card sx={{ width: '100%', marginBottom: 1 }}>
                <CardContent style={{ padding: theme.spacing(2) }}>
                    <Grid container flexGrow={1} spacing={1} alignItems="center">
                        <Grid item xs={12}>
                            <ul style={{ paddingLeft: theme.spacing(2) }}>
                                <li>
                                    Select the date for which to show that day's gains. The total amount earned won't change. It is showing
                                    the latest total for the entire trial.
                                </li>
                                <li>Click on the CSV and Excel icons to download the report.</li>
                                <li>Use the search field to filter through the results.</li>
                                <li>Click on the Edit icon in the results to change the settings for that trial.</li>
                            </ul>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        flexGrow={1}
                        spacing={1}
                        alignItems="center"
                        justifyContent={isLargeScreen ? 'flex-start' : 'space-between'}
                    >
                        <Grid item xs={12} sm="auto">
                            <SelectTimezone fullWidth size={'small'} timezone={timezone} setTimezone={setTimezone} />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Date"
                                    value={moment(date)}
                                    onChange={newValue => setDate(newValue ? newValue.toDate() : null)}
                                    renderInput={params => <TextField fullWidth size={'small'} {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <SextforceMetricsTrialsOverviewSelector
                                trialsLoading={trialsLoading}
                                trialsSelectorType={trialsSelectorType}
                                setTrialsSelectorType={setTrialsSelectorType}
                                theme={theme}
                            />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <SextforceMetricsCrossReferenceEarningsSubscriberSelector
                                subscribers={subscribers}
                                subscribersLoading={subscribersLoading}
                                crossReferenceSubscriberId={crossReferenceSubscriberId}
                                setCrossReferenceSubscriberId={setCrossReferenceSubscriberId}
                            />
                        </Grid>
                        <Grid item xs="auto" sm="auto">
                            <ToggleButtonGroup
                                value={amountType}
                                exclusive
                                color="secondary"
                                size={'small'}
                                onChange={(_event, newValue) => {
                                    if (!newValue) {
                                        return;
                                    }

                                    setAmountType(newValue);
                                    localStorage.setItem('showEarningsAsGross', newValue === 'gross' ? 'true' : 'false');
                                }}
                                sx={{ marginTop: '4px' }}
                            >
                                <ToggleButton value="gross">GROSS</ToggleButton>
                                <ToggleButton value="net">NET</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs="auto" sm>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs="auto">
                                    <Tooltip title="Download as CSV">
                                        <span>
                                            <IconButton
                                                disabled={trialsFullForDownloadLoading || !trials}
                                                onClick={async () => {
                                                    setTrialsFullForDownloadLoading(true);

                                                    try {
                                                        await queryClient
                                                            .fetchQuery(
                                                                [
                                                                    'metricsTrialsOverviewFullForDownload',
                                                                    timezone,
                                                                    date,
                                                                    trialsSelectorType,
                                                                    subscriber,
                                                                    search,
                                                                ],
                                                                () =>
                                                                    fetchTrialsOverviewFullFordownload(
                                                                        trialsSelectorType.includeInactive,
                                                                        trialsSelectorType.type,
                                                                    ),
                                                                {
                                                                    // Stale time 5 minutes
                                                                    staleTime: 60 * 1000 * 5,
                                                                },
                                                            )
                                                            .then(data => {
                                                                handleDownloadReportCsv(data);
                                                            });
                                                    } catch (error) {
                                                        console.error(error);
                                                    }

                                                    setTrialsFullForDownloadLoading(false);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFileCsv}
                                                    size="2x"
                                                    color={
                                                        trialsFullForDownloadLoading ? theme.palette.text.disabled : theme.palette.info.main
                                                    }
                                                />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs="auto">
                                    <Tooltip title="Download as Excel">
                                        <span>
                                            <IconButton
                                                disabled={trialsFullForDownloadLoading || !trials}
                                                onClick={async () => {
                                                    setTrialsFullForDownloadLoading(true);

                                                    try {
                                                        await queryClient
                                                            .fetchQuery(
                                                                [
                                                                    'metricsTrialsOverviewFullForDownload',
                                                                    timezone,
                                                                    date,
                                                                    trialsSelectorType,
                                                                    subscriber,
                                                                    search,
                                                                ],
                                                                {
                                                                    queryFn: () =>
                                                                        fetchTrialsOverviewFullFordownload(
                                                                            trialsSelectorType.includeInactive,
                                                                            trialsSelectorType.type,
                                                                        ),
                                                                    // Stale time 5 minutes
                                                                    staleTime: 60 * 1000 * 5,
                                                                },
                                                            )
                                                            .then(data => {
                                                                handleDownloadReportExcel(data);
                                                            });
                                                    } catch (error) {
                                                        console.error(error);
                                                    }

                                                    setTrialsFullForDownloadLoading(false);
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFileExcel}
                                                    size="2x"
                                                    color={
                                                        trialsFullForDownloadLoading ? theme.palette.text.disabled : theme.palette.info.main
                                                    }
                                                />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {trialsSelectorType.type === 'trialLinkTrial' && (
                <>
                    <SextforceMetricsGroups subscriber={subscriber} metricType="trial" amountType={amountType} />

                    <SextforceMetricsTrialsClaimsTodayOverview
                        subscriber={subscriber}
                        timezone={timezone}
                        hoveredMetricId={hoveredClaimsTodayOverviewMetricId}
                        setHoveredMetricId={setHoveredClaimsTodayOverviewMetricId}
                    />
                </>
            )}

            <SextforceMetricsTrialsOverviewGrid
                subscriber={subscriber}
                metrics={trials}
                metricsType={trialsSelectorType.type}
                amountType={amountType}
                isLoading={trialsLoading}
                saveSettings={saveSettings}
                isSavingSettings={isSavingSettings}
                reportSort={reportSort}
                setReportSort={setReportSort}
                // filter={filter}
                // setFilter={setFilter}
                search={search}
                setSearch={setSearch}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                crossReferenceSubscriberId={crossReferenceSubscriberId}
                startDate={moment(date).startOf('day').toDate()}
                endDate={moment(date).endOf('day').toDate()}
                timezone={timezone}
                hoverMetricId={hoveredClaimsTodayOverviewMetricId}
            />
        </>
    );
};

export default SextforceMetricsTrialsOverview;
