import { Container } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import 'moment/locale/en-gb';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const SextforceMetrics = () => {
    const theme: Theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/subscribers/${params.userId}/services/sextforce/metrics/account`, { replace: true });
    }, [navigate, params]);

    return (
        <Container
            maxWidth={false}
            sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), backgroundColor: '#e7ebf0', minHeight: '100%' }}
        ></Container>
    );
};

export default SextforceMetrics;
