import { Card, CardContent, Divider, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { Container } from '@mui/system';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import LogoFollowBack from '../../../components/icons/LogoFollowBack';
import FollowBackServiceDescription from '../../../components/services/followBack/FollowBackServiceDescription';

const FollowBackWelcome = () => {
    const theme: Theme = useTheme();

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            <Typography variant="h6" textAlign="center" sx={{ marginBottom: theme.spacing(2) }}>
                <LogoFollowBack
                    style={{
                        width: '100%',
                        maxWidth: '250px',
                    }}
                />
            </Typography>

            <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                <BackNavigationButton /> Follow-Back Welcome
            </Typography>

            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                    <FollowBackServiceDescription theme={theme} />
                </CardContent>
            </Card>

            <Divider sx={{ marginBottom: theme.spacing(3) }} />

            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        Why Do Follow-Back?
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        Because of the way OnlyFans is designed, you can only message users you follow.
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        Expired fans, are fans who used to be subscribed to you but their subscription had expired for one reason or another
                        - maybe they forgot to rebill, maybe they had a girlfriend at the time and didn't think they'll need OnlyFans
                        anymore, maybe they just were not in the mood - and they are now just sitting there in the Expired list waiting to
                        be approached... messaged... noticed... given a reason to resubscribe. Expired fans are the cheapest fans you can
                        get to start spending again because you don't need to get them from an external source. You just need to follow them
                        so that you could message them and make them an offer they can't refuse.
                    </Typography>
                </CardContent>
            </Card>

            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        What is the Difference Between the Following→Expired List and the Fans→Expired List
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        Both lists contain your expired fans. The difference is how they got there:
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        <ul>
                            <li style={{ marginBottom: theme.spacing(1) }}>
                                Following→Expired list contains the fans who you used to follow but who then expired.
                            </li>
                            <li style={{ marginBottom: theme.spacing(1) }}>
                                Fans→Expired list contains fans you never followed but who followed you and then expired.
                            </li>
                        </ul>
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        This Follow-Back service follows the expired fans in BOTH of these lists every time it runs.
                    </Typography>
                </CardContent>
            </Card>

            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        How to Message Just the Expired Fans
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        Go to Messages, click on New Message (+), select "Following" in Send To, and then click on View All next to Exclude
                        and select "Fans".
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        This will message everybody you follow minus the currently active fans.
                    </Typography>
                </CardContent>
            </Card>
        </Container>
    );
};

export default FollowBackWelcome;
