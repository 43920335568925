import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ClearIcon from '@mui/icons-material/Clear';
import PaidIcon from '@mui/icons-material/Paid';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import {
    Box,
    Card,
    CardContent,
    Chip,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Stack,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/system';
import {
    DataGrid,
    GridCellParams,
    GridColumnHeaderParams,
    GridColumns,
    GridFilterModel,
    GridLinkOperator,
    GridSortModel,
    GridValueGetterParams,
} from '@mui/x-data-grid';
import dinero from 'dinero.js';
import { parse as parseCsv } from 'json2csv';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import { d2f, metricTypeName } from '../../../../utils/common';

/**
 * Transforms the transactions data rows from the server to a format ready for export to CSV or Excel
 * @param rows Transactions rows
 * @param timezone Timezone string to convert the transaction time to
 * @returns Transformed data for export
 */
const transformTransactionsForExport = (rows: any[], timezone: string) => {
    const headers = [
        {
            label: `Subscribed At ${timezone}`,
            value: 'subscribedAt',
        },
        {
            label: 'Fan ID',
            value: 'userId',
        },
        {
            label: 'Fan Name',
            value: 'userName',
        },
        {
            label: 'Total Spent ($USD)',
            value: 'total',
        },
    ];

    const data = rows.map(row => {
        return {
            subscribedAt: row.subscribedAt ? moment(row.subscribedAt).tz(timezone).format('L hh:mm a') : 'Not Found',
            userId: row.userId,
            userName: row.userName,
            total: Number.parseFloat(d2f(row.total).toFixed(2)),
        };
    });

    return { headers, rows: data };
};

const moneyCell = (params: any, showEarningsAsGross: boolean, showEarningsWithSubscriptions: boolean) => {
    let total: number = 0;

    if (showEarningsWithSubscriptions) {
        total = d2f(params.row.total);
    } else {
        total = d2f(params.row.totalWithoutSubscriptions);
    }

    if (!showEarningsAsGross) {
        total = total * 0.8;
    }

    return <div style={{ fontFamily: 'monospace' }}>{dinero({ amount: Math.round(total * 100), currency: 'USD' }).toFormat()}</div>;
};

const subscribedAtString = (subscribedAt: null | undefined | Date | string, timezone: string, isLargeScreen: boolean) => {
    if (typeof subscribedAt === 'undefined' && subscribedAt !== null) {
        return 'Loading...';
    }

    if (subscribedAt === null) {
        return 'Not Found';
    }

    return moment(subscribedAt)
        .tz(timezone)
        .format(isLargeScreen ? 'L hh:mma' : 'L');
};

const countUsersSubscribedBeforeStartDate = (promoCampaignTotalSales: any[], metricStartDate: Date | undefined) => {
    if (metricStartDate === undefined) {
        return 0;
    }

    return promoCampaignTotalSales.filter(user => moment(user.subscribedAt).isBefore(metricStartDate)).length;
};

const ChipTotalSales = (props: { promoCampaignTotalSales: any; selected: boolean; onClick: () => void }) => {
    const { promoCampaignTotalSales, selected, onClick } = props;

    return (
        <Grid item xs={12} md={'auto'}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs="auto">
                    <Chip
                        variant={selected ? 'filled' : 'outlined'}
                        color="primary"
                        label={`${(promoCampaignTotalSales && promoCampaignTotalSales.length.toLocaleString()) || 0} Total Found`}
                        onClick={onClick}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const ChipSubscribedBeforeMetricStart = (props: {
    promoCampaignTotalSales: any;
    metricStartDate: Date | undefined;
    metricType: string;
    selected: boolean;
    onClick: () => void;
}) => {
    const { promoCampaignTotalSales, metricStartDate, metricType, selected, onClick } = props;

    return (
        <Grid item xs={12} md={'auto'}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs="auto">
                    <Chip
                        variant={selected ? 'filled' : 'outlined'}
                        color="secondary"
                        label={`${
                            promoCampaignTotalSales &&
                            countUsersSubscribedBeforeStartDate(promoCampaignTotalSales, metricStartDate).toLocaleString()
                        } Subscribed Before ${metricTypeName(metricType)}`}
                        onClick={onClick}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const ChipSpendersCount = (props: { spendersCount: number; selected: boolean; onClick: () => void }) => {
    const { spendersCount, selected, onClick } = props;

    return (
        <Grid item xs={12} md={'auto'}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs="auto">
                    <Chip
                        variant={selected ? 'filled' : 'outlined'}
                        color="primary"
                        label={`${spendersCount.toLocaleString()} Spenders`}
                        onClick={onClick}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const ChipFreeloadersCount = (props: { freeloadersCount: number; selected: boolean; onClick: () => void }) => {
    const { freeloadersCount, selected, onClick } = props;

    return (
        <Grid item xs={12} md={'auto'}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs="auto">
                    <Chip
                        variant={selected ? 'filled' : 'outlined'}
                        color="error"
                        label={`${freeloadersCount.toLocaleString()} Freeloaders`}
                        onClick={onClick}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const filterUsersList = (
    data: any,
    filterUsers: 'all' | 'spenders' | 'freeloaders' | 'previouslySubscribed',
    metricStartDate: Date | undefined,
) => {
    if (!data) {
        return [];
    }

    if (filterUsers === 'all') {
        return data;
    }

    if (filterUsers === 'spenders') {
        return data.filter((user: any) => d2f(user.total) > 0);
    }

    if (filterUsers === 'freeloaders') {
        return data.filter((user: any) => d2f(user.total) === 0);
    }

    if (filterUsers === 'previouslySubscribed') {
        if (!metricStartDate) {
            return [];
        }

        return data.filter((user: any) => moment(user.subscribedAt).isBefore(moment(metricStartDate)));
    }
};

type Props = {
    metricId: string | undefined;
    promoCampaignTotalSales: any[] | undefined;
    promoCampaignTotalSalesLoading: boolean;
    showEarningsWithSubscriptions: boolean;
    showEarningsAsGross: boolean;
    metricStartDate: Date | undefined;
    metricType: string;
    closeUsers: { [key: number]: boolean };
    timezone: string;
    theme: Theme;
};

const SextforceMetricsCampaignUsers = (props: Props) => {
    const {
        metricId,
        promoCampaignTotalSales,
        promoCampaignTotalSalesLoading,
        showEarningsAsGross,
        showEarningsWithSubscriptions,
        metricStartDate,
        metricType,
        closeUsers,
        timezone,
        theme,
    } = props;
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [limit, setLimit] = useState<number>(20);
    const [offset, setOffset] = useState<number>(0);
    const [reportSort, setReportSort] = useState<GridSortModel>([{ field: 'total', sort: 'desc' }]);
    const [search, setSearch] = useState<string>('');
    const [filter, setFilter] = useState<GridFilterModel>({ items: [] });
    const [freeloadersCount, setFreeloadersCount] = useState<number>(0);
    const [spendersCount, setSpendersCount] = useState<number>(0);
    const [filterUsers, setFilterUsers] = useState<'all' | 'spenders' | 'freeloaders' | 'previouslySubscribed'>('all');

    const filteredUsersList = filterUsersList(promoCampaignTotalSales, filterUsers, metricStartDate);

    useEffect(() => {
        if (promoCampaignTotalSales && !promoCampaignTotalSalesLoading) {
            let freeloaders: number = 0;
            let spenders: number = 0;

            promoCampaignTotalSales.forEach((user: any) => {
                const amount: number = d2f(user.total);

                if (amount === 0) {
                    freeloaders += 1;
                } else {
                    spenders += 1;
                }
            });

            setFreeloadersCount(freeloaders);
            setSpendersCount(spenders);
            setOffset(0);
        } else {
            setFreeloadersCount(0);
            setSpendersCount(0);
        }
    }, [promoCampaignTotalSales, promoCampaignTotalSalesLoading]);

    /**
     * DataGrid Columns
     */

    // Define agents DataGrid columns
    const columns = useMemo<GridColumns>(
        () => [
            {
                field: 'icon',
                headerName: '',
                width: 24,
                align: 'center',
                renderHeader: params => <div style={{ fontWeight: 'bold' }}>{params.colDef.headerName}</div>,
                renderCell: params => (d2f(params.row.total) > 0 ? <PaidIcon color="success" /> : <ThumbDownIcon color="error" />),
            },
            {
                field: 'subscribedAt',
                headerName: 'Subscribed',
                width: isLargeScreen ? 160 : 100,
                renderHeader: params => <div style={{ fontWeight: 'bold' }}>{params.colDef.headerName}</div>,
                renderCell: params => (
                    <div style={{ fontFamily: 'monospace' }}>
                        <Typography variant="inherit" color={closeUsers[params.row.userId] ? theme.palette.error.main : 'inherit'}>
                            {subscribedAtString(params.row.subscribedAt, timezone, isLargeScreen)}
                        </Typography>
                    </div>
                ),
            },
            ...(isLargeScreen
                ? [
                      {
                          field: 'userId',
                          headerName: 'ID',
                          weight: 100,
                          renderHeader: (params: GridColumnHeaderParams) => (
                              <div style={{ fontWeight: 'bold' }}>{params.colDef.headerName}</div>
                          ),
                          valueGetter: (params: GridValueGetterParams) => params.row.userId && params.row.userId,
                          renderCell: (params: GridCellParams) => (
                              <Link href={`https://onlyfans.com/${params.row.userName}`} target="_blank" rel="noopener noreferrer">
                                  <Typography
                                      variant="inherit"
                                      color={closeUsers[params.row.userId] ? theme.palette.error.main : 'inherit'}
                                  >
                                      {params.row.userId}
                                  </Typography>
                              </Link>
                          ),
                      },
                  ]
                : []),
            {
                field: 'userName',
                headerName: 'Username',
                flex: 1,
                renderHeader: params => <div style={{ fontWeight: 'bold' }}>{params.colDef.headerName}</div>,
                valueGetter: (params: GridValueGetterParams) => params.row.userName && params.row.userName,
                renderCell: (params: GridCellParams) => (
                    <Link href={`https://onlyfans.com/${params.row.userName}`} target="_blank" rel="noopener noreferrer">
                        <Typography variant="inherit" color={closeUsers[params.row.userId] ? theme.palette.error.main : 'inherit'}>
                            {params.row.userName}
                        </Typography>
                    </Link>
                ),
            },
            {
                field: 'total',
                headerName: `Total (${showEarningsAsGross ? 'gross' : 'net'})`,
                flex: 1,
                align: 'right',
                headerAlign: 'right',
                renderHeader: params => <div style={{ fontWeight: 'bold' }}>{params.colDef.headerName}</div>,
                sortComparator: (v1: any, v2: any) => d2f(v1) - d2f(v2),
                renderCell: params => moneyCell(params, showEarningsAsGross, showEarningsWithSubscriptions),
            },
        ],
        [isLargeScreen, showEarningsAsGross, timezone, closeUsers, theme.palette.error.main, showEarningsWithSubscriptions],
    );

    useEffect(() => {
        if (search.trim().length === 0) {
            setFilter({ items: [] });
        } else {
            setFilter({
                items: [
                    {
                        id: 1,
                        columnField: 'userName',
                        operatorValue: 'contains',
                        value: search,
                    },
                ],
                linkOperator: GridLinkOperator.Or,
            });
        }
    }, [search]);

    // Convert report to CSV format and start file download
    const handleDownloadReportCsv = () => {
        const transformedData: any = transformTransactionsForExport(filteredUsersList, timezone);

        // Format JSON data to CSV
        const csv = parseCsv(transformedData.rows, {
            fields: transformedData.headers,
            transforms: [],
        });

        // Convert CSV to Blob
        const blob: Blob = new Blob([csv], { type: 'text/csv' });

        // Create a descriptive filename
        const filename: string = `users_${metricId}_${moment().tz(timezone, true).format('YYYY-MM-DD')}.csv`;

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode && link.parentNode.removeChild(link);
    };

    // Convert to Excel format and start file download
    const handleDownloadReportExcel = () => {
        const transformedData: any = transformTransactionsForExport(filteredUsersList, timezone);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [transformedData.headers.map((header: any) => header.label)]);

        XLSX.utils.sheet_add_json(worksheet, transformedData.rows, {
            origin: 'A2',
            skipHeader: true,
            header: transformedData.headers.map((header: any) => header.value),
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

        // Create a descriptive filename
        const filename: string = `users_${metricId}_${moment().tz(timezone, true).format('YYYY-MM-DD')}.xlsx`;

        XLSX.writeFile(workbook, filename);
    };

    return (
        <>
            <Typography variant="h5" sx={{ marginBottom: theme.spacing(1) }}>
                Subscribers and Sales
            </Typography>

            <Card sx={{ marginBottom: theme.spacing(1) }}>
                <CardContent sx={{ marginBottom: theme.spacing(0) }}>
                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                        The following is a list of users who{' '}
                        {metricType === 'promoCampaign' ? 'subscribed via this campaign' : 'calaimed this trial/promo'}, when they
                        {metricType === 'promoCampaign' ? 'subscribed' : 'claimed it'} and how much they had spent in total (gross) since
                        the {metricType === 'promoCampaign' ? 'campaign' : 'trial/promo'} started.
                    </Typography>
                    {metricType === 'promoCampaign' && (
                        <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                            If you don't see the subscription date yet, please come back later. The system is slowly fetching this
                            information from OnlyFans in the background.
                        </Typography>
                    )}
                    <Typography variant="body1">Click on the user ID or username to open their OnlyFans profile in a new tab.</Typography>
                </CardContent>
            </Card>

            <Card>
                <CardContent sx={{ marginBottom: theme.spacing(0) }}>
                    <Grid container spacing={2} alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                        <ChipTotalSales
                            promoCampaignTotalSales={promoCampaignTotalSales}
                            selected={filterUsers === 'all'}
                            onClick={() => {
                                setFilterUsers('all');
                            }}
                        />
                        <ChipSubscribedBeforeMetricStart
                            promoCampaignTotalSales={promoCampaignTotalSales}
                            metricStartDate={metricStartDate}
                            metricType={metricType}
                            selected={filterUsers === 'previouslySubscribed'}
                            onClick={() => {
                                setFilterUsers('previouslySubscribed');
                            }}
                        />
                        <ChipSpendersCount
                            spendersCount={spendersCount}
                            selected={filterUsers === 'spenders'}
                            onClick={() => {
                                setFilterUsers('spenders');
                            }}
                        />
                        <ChipFreeloadersCount
                            freeloadersCount={freeloadersCount}
                            selected={filterUsers === 'freeloaders'}
                            onClick={() => {
                                setFilterUsers('freeloaders');
                            }}
                        />
                    </Grid>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs="auto">
                            Download:
                        </Grid>
                        <Grid item xs="auto">
                            <Tooltip title="Download as CSV">
                                <span>
                                    <IconButton
                                        disabled={!metricId}
                                        onClick={() => {
                                            handleDownloadReportCsv();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFileCsv} size="2x" color={theme.palette.info.main} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs="auto">
                            <Tooltip title="Download as Excel">
                                <span>
                                    <IconButton
                                        disabled={!metricId}
                                        onClick={() => {
                                            handleDownloadReportExcel();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFileExcel} size="2x" color={theme.palette.info.main} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Search"
                        value={search}
                        sx={{ backgroundColor: '#ffffff' }}
                        onChange={e => {
                            setSearch(e.currentTarget.value);
                        }}
                        onKeyDown={e => {
                            console.log(e.key);
                            if (e.key === 'Escape') {
                                setSearch('');
                            }
                        }}
                        InputProps={{
                            endAdornment: search.length > 0 && (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            setSearch('');
                                        }}
                                    >
                                        <ClearIcon color="error" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            width: '100%',
                            '.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                                outline: 'none',
                            },
                            marginBottom: theme.spacing(4),
                        }}
                    >
                        <DataGrid
                            rows={filteredUsersList}
                            columns={columns}
                            density={'compact'}
                            autoHeight={true}
                            disableColumnFilter
                            disableColumnMenu
                            disableSelectionOnClick
                            filterMode="client"
                            rowCount={filteredUsersList.length}
                            getRowId={row => row._id}
                            pageSize={limit}
                            filterModel={filter}
                            onPageSizeChange={newPageSize => setLimit(newPageSize)}
                            rowsPerPageOptions={[10, 20, 40, 80, 100]}
                            page={promoCampaignTotalSales ? offset / limit : undefined}
                            onPageChange={newPage => setOffset(limit * newPage)}
                            paginationMode={'client'}
                            sortingMode={'client'}
                            sortModel={reportSort}
                            onSortModelChange={(model: GridSortModel) => {
                                setReportSort(model);
                            }}
                            components={{
                                NoRowsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        No Subscribers Found
                                    </Stack>
                                ),
                                NoResultsOverlay: () => (
                                    <Stack height="100%" alignItems="center" justifyContent="center">
                                        No Results Matching Your Search
                                    </Stack>
                                ),
                            }}
                            loading={promoCampaignTotalSalesLoading}
                            sx={{ backgroundColor: '#ffffff' }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default SextforceMetricsCampaignUsers;
