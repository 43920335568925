import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Button,
    Card,
    CardContent,
    Collapse,
    Grid,
    IconButton,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError, handleHttpErrorResponse, stripHtml, targetUsersName } from '../../../../utils/common';
import MessageUsersCurrentSessionExclude from '../sendMessage/MessageUsersCurrentSessionExclude';

const SessionRow = (props: {
    row: any;
    timezone: string;
    smallScreen: boolean;
    handleDeleteScheduledMessage: (id: string) => void;
    theme: Theme;
}) => {
    const { row, timezone, smallScreen, handleDeleteScheduledMessage, theme } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow
                key={row._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                onClick={() => setOpen(!open)}
            >
                <TableCell padding="none">
                    <IconButton size="small">{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {moment(row.scheduleDateTime).tz(timezone).format('L LT')}
                </TableCell>
                <TableCell>{targetUsersName(row.payload.usersType, {})}</TableCell>
                {smallScreen && (
                    <TableCell
                        sx={{
                            display: { xs: 'none', sm: 'table-cell' },
                        }}
                    >
                        {stripHtml(row.payload.message)
                            .split('\n')
                            .map((i: string, key: number) => {
                                return <div key={key}>{i}</div>;
                            })}
                    </TableCell>
                )}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: theme.palette.grey[100] }} colSpan={6}>
                    <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        sx={{ maxWidth: { sm: theme.breakpoints.values.sm, md: theme.breakpoints.values.md } }}
                    >
                        <Grid container spacing={1} sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>
                                    Message Details
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <strong>Message</strong>
                            </Grid>
                            <Grid item xs={12} sx={{ marginLeft: theme.spacing(2) }}>
                                {row.payload && 'message' in row.payload && row.payload.message
                                    ? stripHtml(row.payload.message)
                                          .split('\n')
                                          .map((i: string, key: number) => {
                                              return <div key={key}>{i}</div>;
                                          })
                                    : ''}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>%USERNAME% Replacement</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {row.payload.usernameReplacement || <div style={{ color: theme.palette.grey[500] }}>None</div>}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>To</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {targetUsersName(row.payload.usersType, {})}
                            </Grid>
                            {row.targetUsers === 'lists' && (
                                <>
                                    <Grid item xs={4}>
                                        <strong>List Name</strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        {row.payload && row.payload.listName}
                                    </Grid>
                                </>
                            )}
                            {row.payload && row.payload.exclude && (
                                <>
                                    <Grid item xs={12}>
                                        <strong>Excludes</strong>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}>
                                        <MessageUsersCurrentSessionExclude exclude={row.payload.exclude} theme={theme} />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={6}>
                                <strong>Attachment</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {row.payload && row.payload.mediaFiles && row.payload.mediaFiles.length > 0
                                    ? `${row.payload.mediaFiles.length} photo/video`
                                    : 'None'}
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="medium"
                                    startIcon={<DeleteIcon />}
                                    fullWidth={!smallScreen}
                                    onClick={() => {
                                        handleDeleteScheduledMessage(row._id);
                                    }}
                                >
                                    Delete
                                </Button>
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

type Props = {
    subscriber: any;
    refreshScheduledMessages?: boolean;
};

const MessageUsersScheduledList = (props: Props) => {
    const { subscriber, refreshScheduledMessages } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const params = useParams();
    const dialog = useDialog();
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const timezone: string = moment.tz.guess();

    // Fetch Scheduled Messages
    const fetchScheduledMessages = async (): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/scheduled`;

            return fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const {
        data: scheduledMessages,
        isLoading: scheduledMessagesLoading,
        refetch: refetchScheduledMessages,
    } = useQuery(
        ['scheduledMessages', subscriber],
        () => {
            if (subscriber) {
                return fetchScheduledMessages();
            }
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 0,
            enabled: subscriber ? true : false,
        },
    );

    useEffect(() => {
        refetchScheduledMessages();
    }, [refreshScheduledMessages, refetchScheduledMessages]);

    const { mutate: deleteScheduledMessage } = useMutation(
        async (id: string) => {
            const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/scheduled/${id}`;

            return fetch(query, {
                method: 'delete',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        },
        {
            onSuccess: async (result: any) => {
                if (result && result.success) {
                    refetchScheduledMessages();
                }
            },
            onError: error => {
                dialog.alert((error as any).message as string);
            },
        },
    );

    const handleDeleteScheduledMessage = (id: string) => {
        dialog
            .confirm({
                message: 'Are you sure you want to delete this scheduled message?',
                ok: { text: 'Delete', variant: 'contained', color: 'error' },
                cancel: { text: 'Cancel' },
                title: 'Delete Scheduled Message',
            })
            .then(() => {
                deleteScheduledMessage(id);
            })
            .catch(() => {});
    };

    return (
        <>
            <Typography variant="h5" sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
                Scheduled Messages
            </Typography>
            <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
                <CardContent>
                    <Grid container flexGrow={1}>
                        {scheduledMessagesLoading ? (
                            <Skeleton />
                        ) : scheduledMessages && scheduledMessages.length === 0 ? (
                            <Typography variant="body1">No messages scheduled</Typography>
                        ) : (
                            <>
                                <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                                    This list shows the currently scheduled messages. Click the row for details and to cancel scheduled
                                    message.
                                </Typography>

                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={20} />
                                            <TableCell sx={{ fontWeight: 'bold', width: { xs: '100px', sm: '150px' } }}>Date</TableCell>
                                            <TableCell width={180} sx={{ fontWeight: 'bold' }}>
                                                Target
                                            </TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', display: { xs: 'none', sm: 'block' } }}>Message</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {scheduledMessages
                                            .slice()
                                            .reverse()
                                            .map((scheduledMessage: any) => (
                                                <SessionRow
                                                    row={scheduledMessage}
                                                    timezone={timezone}
                                                    smallScreen={smallScreen}
                                                    handleDeleteScheduledMessage={handleDeleteScheduledMessage}
                                                    theme={theme}
                                                    key={scheduledMessage._id}
                                                />
                                            ))}
                                    </TableBody>
                                </Table>
                            </>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default MessageUsersScheduledList;
