import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, Chip, CircularProgress, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { useEffect } from 'react';
import useSubscriberVaultMedias from '../../../../hooks/useSubscriberVaultMedias';
import { OnlyFansVaultMedias } from '../../../../types/onlyFansTypes';
import MessageUsersFormAddMediasSelectorImageList from './MessageUsersFormAddMediasSelectorImageList';

export enum ReleaseFormPartnerSource {
    Document = 'document',
    Tag = 'tag',
}

export enum ReleaseFormType {
    Document = 'document',
    User = 'user',
}

export enum ReleaseFormStatus {
    Approved = 'approved',
}

export interface ReleaseFormUser {
    view: ReleaseFormView;
    id: number;
    name: string;
    username: string;
    isVerified: boolean;
    avatar: null;
    avatarThumbs: null;
    ivStatus: null;
    isFromGuest: boolean;
}

export enum ReleaseFormView {
    Tu = 'tu',
}

export type VaultMediaResponseList = {
    id: number;
    canView: boolean;
    hasError: boolean;
    createdAt: Date;
    thumb: string;
    cachedThumb: string | null;
    src?: string;
    duration?: number;
    type: string;
    counters: {
        likesCount: number;
        tipsSumm: number;
    };
    mentionedUsers?: {
        view: string;
        avatar: string;
        avatarThumbs: {
            c50: string;
            c144: string;
        };
        header: string;
        headerSize: {
            width: number;
            height: number;
        };
        headerThumbs: {
            w480: string;
            w760: string;
        };
        id: number;
        name: string;
        username: string;
        hasNotViewedStory: boolean;
        isVerified: boolean;
        hasStream: boolean;
        hasStories: boolean;
        isRestricted: boolean;
        hasPromotion: boolean;
        isFree: boolean;
    }[];
    releaseForms?: {
        id: number;
        name: string;
        partnerSource: ReleaseFormPartnerSource;
        type: ReleaseFormType;
        status: ReleaseFormStatus;
        code: null;
        signature: null;
        date: Date;
        approve_date: Date;
        user: ReleaseFormUser;
    }[];
};

export type VaultMediaResponse = {
    list: VaultMediaResponseList[];
    hasMore: boolean;
    nextOffset: number | null;
};

type Props = {
    serviceName: string;
    albumId: number;
    albumName: string;
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    setMedias: React.Dispatch<React.SetStateAction<OnlyFansVaultMedias.RestructuredResponseItem[]>>;
    mediasPreviews: number[];
    setMediasPreviews: React.Dispatch<React.SetStateAction<number[]>>;
    albumMedias: VaultMediaResponseList[];
    setAlbumMedias: React.Dispatch<React.SetStateAction<VaultMediaResponseList[]>>;
    albumHasMore: boolean;
    setAlbumHasMore: React.Dispatch<React.SetStateAction<boolean>>;
    thumbnails: any[];
    getThumbnail: (thumbId: number, thumbUrl: string) => void;
    getAudio: (url: string) => Promise<Blob | null>;
    tagCreators: boolean;
    setTagCreators: (tagCreators: boolean) => void;
    taggedCreators: { id: number; name: string }[];
    setTaggedCreators: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>;
    taggedReleaseForms: { id: number; name: string }[];
    setTaggedReleaseForms: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>;
    theme: Theme;
    disabled?: boolean;
};

const MessageUsersFormAddMediasSelector = (props: Props) => {
    const {
        serviceName,
        albumId,
        albumName,
        medias,
        setMedias,
        mediasPreviews,
        setMediasPreviews,
        albumMedias,
        setAlbumMedias,
        albumHasMore,
        setAlbumHasMore,
        thumbnails,
        getThumbnail,
        getAudio,
        tagCreators,
        setTagCreators,
        taggedCreators,
        setTaggedCreators,
        taggedReleaseForms,
        setTaggedReleaseForms,
        theme,
        disabled,
    } = props;

    const {
        data: vaultMediasRaw,
        isLoading: vaultAlbumLoading,
        isFetching: vaultAlbumFetching,
        refetch: refetchMedias,
        hasNextPage: vaultMediasHasNextPage,
        fetchNextPage: fetchNextVaultMediasPage,
    } = useSubscriberVaultMedias(serviceName, albumId, albumId !== -1 ? true : false);

    useEffect(() => {
        if (!vaultMediasRaw || !vaultMediasRaw.pages) {
            return;
        }

        const newResponse: VaultMediaResponse = {
            list: [],
            hasMore: vaultMediasRaw ? vaultMediasRaw.pages[vaultMediasRaw.pages.length - 1].hasMore : false,
            nextOffset: vaultMediasRaw ? vaultMediasRaw.pages[vaultMediasRaw.pages.length - 1].nextOffset : null,
        };

        // Join all the pages list results into one array and use the last page's hasMore
        vaultMediasRaw.pages.forEach((page: VaultMediaResponse) => {
            newResponse.list = [...newResponse.list, ...page.list];
        });

        if (newResponse && newResponse.list) {
            setAlbumMedias(newResponse.list);
            setAlbumHasMore(newResponse.hasMore);

            const mediasKeys = Object.keys(thumbnails);

            newResponse.list.forEach(item => {
                if (item.type !== 'audio' && !item.cachedThumb && !mediasKeys.includes(item.id.toString())) {
                    getThumbnail(item.id, item.thumb);
                }
            });
        }
    }, [vaultMediasRaw]);

    useEffect(() => {
        // console.log('MessageUsersFormAddMediasSelector useEffect');
        // setMedias([]);
        // setMediasPreviews([]);
        // setAlbumMedias([]);
        // setAlbumHasMore(false);
    }, [albumId, setMedias, setMediasPreviews]);

    const handleMediasSelectAllClicked = () => {
        const missingMentionedUsers: { id: number; name: string }[] = [];
        const missingReleaseForms: { id: number; name: string }[] = [];

        // Add missing mentioned users and release forms to taggedCreators and taggedReleaseForms
        albumMedias.forEach(albumMedia => {
            if (!medias.find(media => media.id === albumMedia.id)) {
                setMedias(prevMedias => {
                    return [
                        ...prevMedias,
                        {
                            id: albumMedia.id,
                            type: albumMedia.type,
                            src: albumMedia.src || '',
                            cachedThumb: albumMedia.cachedThumb,
                            createdAt: albumMedia.createdAt,
                            counters: albumMedia.counters,
                            duration: albumMedia.duration || 0,
                            canView: albumMedia.canView,
                            hasError: albumMedia.hasError,
                            thumb: albumMedia.thumb,
                        },
                    ];
                });

                // Does taggedCreators already have these mentionedUsers?
                if (albumMedia.mentionedUsers && albumMedia.mentionedUsers.length > 0) {
                    for (let i = 0; i < albumMedia.mentionedUsers.length; i += 1) {
                        const mentionedUser = albumMedia.mentionedUsers[i];

                        if (
                            !taggedCreators.find(taggedCreator => taggedCreator.id === mentionedUser.id) &&
                            !missingMentionedUsers.find(missingMentionedUser => missingMentionedUser.id === mentionedUser.id)
                        ) {
                            missingMentionedUsers.push({ id: mentionedUser.id, name: mentionedUser.name });
                        }
                    }
                }

                // Add any missing releaseFormsUsers to taggedCreators
                const releaseFromsUsers =
                    albumMedia.releaseForms && albumMedia.releaseForms.filter(releaseForm => releaseForm.type === 'user');

                if (releaseFromsUsers && releaseFromsUsers.length > 0) {
                    for (let i = 0; i < releaseFromsUsers.length; i += 1) {
                        const releaseFormUser = releaseFromsUsers[i].user;

                        if (
                            !taggedCreators.find(taggedCreator => taggedCreator.id === releaseFormUser.id) &&
                            !missingMentionedUsers.find(missingMentionedUser => missingMentionedUser.id === releaseFormUser.id)
                        ) {
                            missingMentionedUsers.push({ id: releaseFormUser.id, name: releaseFormUser.name });
                        }
                    }
                }

                // Only tag creators if the releaseForm is a document
                const releaseFormsDocuments =
                    albumMedia.releaseForms && albumMedia.releaseForms.filter(releaseForm => releaseForm.type === 'document');

                if (releaseFormsDocuments && releaseFormsDocuments.length > 0) {
                    // Does taggedReleaseForms already have these releaseFormsFiltered?
                    for (let i = 0; i < releaseFormsDocuments.length; i += 1) {
                        const releaseForm = releaseFormsDocuments[i];

                        if (
                            !taggedReleaseForms.find(taggedReleaseForm => taggedReleaseForm.id === releaseForm.id) &&
                            !missingReleaseForms.find(missingReleaseForm => missingReleaseForm.id === releaseForm.id)
                        ) {
                            missingReleaseForms.push({ id: releaseForm.id, name: releaseForm.name });
                        }
                    }
                }
            }
        });

        if (!tagCreators && (missingMentionedUsers.length > 0 || missingReleaseForms.length > 0)) {
            setTagCreators(true);
        }

        // Add the missing mentionedUsers to taggedCreators
        if (missingMentionedUsers.length > 0) {
            setTaggedCreators(prevTaggedCreators => {
                return [...prevTaggedCreators, ...missingMentionedUsers];
            });
        }

        // Add the missing releaseFormsFiltered to taggedReleaseForms
        if (missingReleaseForms.length > 0) {
            setTaggedReleaseForms(prevTaggedReleaseForms => {
                return [...prevTaggedReleaseForms, ...missingReleaseForms];
            });
        }
    };

    const handleMediasClearSelectionClicked = () => {
        setMedias([]);
        setMediasPreviews([]);
        setTagCreators(false);
        setTaggedCreators([]);
    };

    return (
        <>
            <Grid
                container
                spacing={1}
                flexGrow={0}
                alignItems="center"
                justifyContent="center"
                sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(0) }}
            >
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" sx={{ marginBottom: theme.spacing(1) }}>
                        <Grid item xs={'auto'}>
                            <PhotoLibraryIcon />
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h6">{albumId === 0 ? 'All' : albumName}</Typography>
                        </Grid>
                        {!vaultAlbumLoading && !vaultAlbumFetching && (
                            <>
                                <Grid item xs={'auto'}>
                                    <Tooltip title="Select All">
                                        <IconButton
                                            onClick={async () => {
                                                handleMediasSelectAllClicked();
                                            }}
                                            disabled={disabled}
                                            color="primary"
                                        >
                                            <DoneAllIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Tooltip title="Clear Selection">
                                        <IconButton
                                            color="error"
                                            onClick={() => {
                                                handleMediasClearSelectionClicked();
                                            }}
                                            disabled={disabled}
                                        >
                                            <ClearIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={'auto'}>
                                    <Tooltip title="Reload Album content from OnlyFans">
                                        <IconButton
                                            onClick={async () => {
                                                const reset = async () => {
                                                    setAlbumHasMore(false);
                                                    setAlbumMedias([]);
                                                };

                                                await reset();
                                                refetchMedias();
                                            }}
                                            color="secondary"
                                            disabled={disabled}
                                        >
                                            <RefreshIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" sx={{ marginBottom: theme.spacing(1) }}>
                        <Grid item xs>
                            <div style={{ color: theme.palette.grey[500] }}>Click to select</div>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <Chip
                                color={medias.length === 0 || medias.length > 40 ? 'error' : 'primary'}
                                icon={<CheckCircleIcon />}
                                label={`${medias.length}/40 selected`}
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" sx={{ marginBottom: theme.spacing(0) }}>
                        <Grid
                            container
                            spacing={1}
                            flexGrow={0}
                            alignItems="center"
                            justifyContent="center"
                            sx={{ marginBottom: theme.spacing(0) }}
                        >
                            {albumMedias && albumMedias.length > 0 && (
                                <Grid item xs={12}>
                                    <MessageUsersFormAddMediasSelectorImageList
                                        albumMedias={albumMedias}
                                        thumbnails={thumbnails}
                                        medias={medias}
                                        setMedias={setMedias}
                                        mediasPreviews={mediasPreviews}
                                        setMediasPreviews={setMediasPreviews}
                                        getAudio={getAudio}
                                        tagCreators={tagCreators}
                                        setTagCreators={setTagCreators}
                                        taggedCreators={taggedCreators}
                                        setTaggedCreators={setTaggedCreators}
                                        taggedReleaseForms={taggedReleaseForms}
                                        setTaggedReleaseForms={setTaggedReleaseForms}
                                        theme={theme}
                                        disabled={disabled}
                                    />
                                </Grid>
                            )}

                            {(vaultAlbumLoading || vaultAlbumFetching) && (
                                <Grid item xs={12}>
                                    <Box textAlign={'center'}>
                                        <Typography variant="caption">
                                            Retrieving your Album content...
                                            <br />
                                            <br />
                                            This might take a while depending on how many albums you have and OnlyFans servers speed 🤷‍♂️
                                            <br />
                                            <br />
                                            <CircularProgress size={24} />
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}

                            {!vaultAlbumLoading && !vaultAlbumFetching && albumHasMore && (
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        size="large"
                                        disabled={!vaultMediasHasNextPage || disabled}
                                        onClick={() => {
                                            // refetchMedias();
                                            fetchNextVaultMediasPage();
                                        }}
                                    >
                                        Load More
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersFormAddMediasSelector;
