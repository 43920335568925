import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { Alert, Button, Card, CardContent, debounce, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useCallback, useEffect, useState } from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './CustomEditor.css';
import MessageUsersFormEditiorPreview from './MessageUsersFormEditiorPreview';
import MessageUsersFormEditorTemplateLoadDialog from './MessageUsersFormEditorTemplateLoadDialog';
import MessageUsersFormEditorTemplateSave from './MessageUsersFormEditorTemplateSave';

const UsernameButton = (props: any) => {
    const { editorState, onChange } = props;

    const addStar = (): void => {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            '%USERNAME%',
            editorState.getCurrentInlineStyle(),
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    };

    return (
        <Button variant="outlined" color="primary" onClick={addStar} sx={{ backgroundColor: 'white', ml: '3px' }}>
            Add %USERNAME%
        </Button>
    );
};

type Props = {
    subscriber: any;
    message: string;
    setMessage: (message: string) => void;
    editorState: EditorState;
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
    messageHasRestrictedWords: boolean;
    messageFoundRestrictedWords: string[];
    checkForRestrictedWords: (text: string) => void;
    usernameReplacement: string;
    setUsernameReplacement: (usernameReplacement: string) => void;
    taggedCreators: { id: number; name: string }[];
    taggedReleaseForms: { id: number; name: string }[];
    lockMessage: boolean;
    lockMessagePrice: number;
    saveTemplate: boolean;
    setSaveTemplate: (saveTemplate: boolean) => void;
    saveTemplateName: string;
    setSaveTemplateName: (templateName: string) => void;
    session?: any;
    theme: Theme;
    disabled?: boolean;
};

const MessageUsersFormEditor = (props: Props) => {
    const {
        subscriber,
        message,
        setMessage,
        editorState,
        setEditorState,
        session,
        messageHasRestrictedWords,
        messageFoundRestrictedWords,
        checkForRestrictedWords,
        usernameReplacement,
        setUsernameReplacement,
        taggedCreators,
        taggedReleaseForms,
        lockMessage,
        lockMessagePrice,
        saveTemplate,
        setSaveTemplate,
        saveTemplateName,
        setSaveTemplateName,
        theme,
        disabled,
    } = props;
    const [loadTemplateDialogOpen, setLoadTemplateDialogOpen] = useState<boolean>(false);

    const debouncedSetMessage = useCallback(
        debounce((newMessage: string) => {
            setMessage(newMessage);
            checkForRestrictedWords(newMessage);
        }, 300), // 300ms debounce delay
        [],
    );

    useEffect(() => {
        if (session && session.payload && session.payload.message) {
            setEditorState(EditorState.createWithContent(ContentState.createFromText(session.payload.message)));
            setMessage(session.payload.message);
        }

        if (session && session.payload && session.payload.replaceUsername) {
            setUsernameReplacement(session.payload.replaceUsername);
        }
    }, [session, setEditorState, setMessage, setUsernameReplacement]);

    const handleLoadTemplate = (template: any) => {
        setEditorState(EditorState.createWithContent(ContentState.createFromText(template.message)));
        setMessage(template.message);

        if (template.usernameReplacement) {
            setUsernameReplacement(template.usernameReplacement);
        }
    };

    const toolbar: EditorProps['toolbar'] = {
        options: ['inline', 'fontSize', 'colorPicker'],
        inline: {
            options: ['bold', 'italic'],
            bold: { className: 'toolbar-button' },
            italic: { className: 'toolbar-button' },
        },
        fontSize: {
            options: ['12', '14', '16', '20', '24'],
            className: 'toolbar-button',
        },
        colorPicker: {
            // icon: 'T',
            className: 'toolbar-button',
            // component: undefined,
            colors: ['rgb(0,0,0)', '#00aff0', '#1b98e0', '#8a96a3'],
        },
    };

    const customConvertToHTML = (contentState: ContentState) => {
        const rawContentState = convertToRaw(contentState);
        let html = draftToHtml(rawContentState, undefined, undefined, customEntityTransform);

        // Replace color spans
        html = html.replace(/<span style="color: rgb\(173,216,230\);"/g, '<span class="m-editor-fc__blue-1"');
        html = html.replace(/<span style="color: rgb\(0,0,139\);"/g, '<span class="m-editor-fc__blue-2"');
        html = html.replace(/<span style="color: rgb\(128,128,128\);"/g, '<span class="m-editor-fc__gray"');
        html = html.replace(/<span style="color: rgb\(0,0,0\);"/g, '<span class="m-editor-fc__default"');

        // Replace font size spans
        html = html.replace(/<span style="font-size: 24px;"/g, '<span class="m-editor-fc__default m-editor-fs__lg"');
        html = html.replace(/<span style="font-size: 20px;"/g, '<span class="m-editor-fc__default m-editor-fs__l"');
        html = html.replace(/<span style="font-size: 16px;"/g, '<span class="m-editor-fc__default m-editor-fs__r"');
        html = html.replace(/<span style="font-size: 14px;"/g, '<span class="m-editor-fc__default m-editor-fs__s"');
        html = html.replace(/<span style="font-size: 12px;"/g, '<span class="m-editor-fc__default m-editor-fs__sm"');

        return html;
    };

    const customEntityTransform = (entity: any, text: string) => {
        if (entity.type === 'LINK') {
            return `<a href="${entity.data.url}">${text}</a>`;
        }
        return text;
    };

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                <Grid item xs={12}>
                    <Card variant="elevation">
                        <CardContent>
                            <Grid container spacing={1} alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs>
                                    <Typography variant="h6">Message</Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Tooltip title="Load from template">
                                        <span>
                                            <IconButton
                                                color="primary"
                                                onClick={() => {
                                                    setLoadTemplateDialogOpen(true);
                                                }}
                                                disabled={disabled}
                                            >
                                                <FolderOpenIcon fontSize="large" />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    Enter the message to send below. Use the toolbar icons for stylying the text in bold, italic and
                                    strikethrough. Click on "ADD %USERNAME%" to add the special %USERNAME% placeholder which will get
                                    replaced with the user's actual username when the message is sent (their orignal username, and not the
                                    thing you renamed them to to avoid accidents).
                                </Grid>
                                <Grid item xs={12}>
                                    If the user's name is a default uXXXXXXXX, the system can automatically replace their name with the word
                                    or phrase below, or leave it blank so that their name is not used at all automatically, in which case
                                    when composing your message, make sure to word it in such a way that it would still make sense if the
                                    user's name was not there.
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Replacement for %USERNAME% (i.e. baby, bby, daddy, etc...)"
                                        fullWidth
                                        value={usernameReplacement}
                                        onChange={e => {
                                            setUsernameReplacement(e.target.value);
                                            // debouncedSetMessage(message, e.target.value);
                                        }}
                                        disabled={disabled}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={1}
                                flexGrow={0}
                                alignItems="center"
                                justifyContent="center"
                                sx={{ marginBottom: theme.spacing(1) }}
                            >
                                <Grid item xs={12}>
                                    <Editor
                                        editorState={editorState}
                                        toolbarStyle={{
                                            borderRadius: '4px',
                                            borderColor: '#0000003b',
                                            borderWidth: '1px',
                                            borderStyle: 'solid',
                                            borderBottom: 'none',
                                            padding: '4px 8px',
                                            marginBottom: 0,
                                            backgroundColor: theme.palette.grey[100],
                                        }}
                                        wrapperClassName="wrapper-class"
                                        editorClassName="editor-class"
                                        toolbarClassName="toolbar-class"
                                        toolbar={toolbar}
                                        toolbarCustomButtons={[<UsernameButton />]}
                                        editorStyle={{
                                            borderRadius: '4px',
                                            borderColor: '#0000003b',
                                            borderWidth: '1px',
                                            borderStyle: 'solid',
                                            paddingLeft: theme.spacing(1),
                                            paddingRight: theme.spacing(1),
                                            // paddingTop: theme.spacing(1),
                                            minHeight: 200,
                                        }}
                                        stripPastedStyles={true}
                                        handlePastedText={() => false}
                                        onEditorStateChange={(state: EditorState) => {
                                            setEditorState(state);

                                            debouncedSetMessage(customConvertToHTML(state.getCurrentContent()));
                                        }}
                                        readOnly={disabled}
                                    />
                                </Grid>
                                {messageHasRestrictedWords && (
                                    <Grid item xs={12}>
                                        <Alert variant="filled" severity="error">
                                            Message contains words that are restricted by OnlyFans!
                                            {messageFoundRestrictedWords.length > 0 && (
                                                <>
                                                    <br />
                                                    {messageFoundRestrictedWords.join(', ')}
                                                </>
                                            )}
                                        </Alert>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <MessageUsersFormEditorTemplateSave
                                        saveTemplate={saveTemplate}
                                        setSaveTemplate={setSaveTemplate}
                                        saveTemplateName={saveTemplateName}
                                        setSaveTemplateName={setSaveTemplateName}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" sx={{ marginTop: theme.spacing(1) }}>
                                        Preview
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <MessageUsersFormEditiorPreview
                                        editorState={editorState}
                                        taggedCreators={taggedCreators}
                                        taggedReleaseForms={taggedReleaseForms}
                                        lockMessage={lockMessage}
                                        lockMessagePrice={lockMessagePrice}
                                        theme={theme}
                                        disabled={disabled}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <MessageUsersFormEditorTemplateLoadDialog
                subscriber={subscriber}
                handleLoadTemplate={handleLoadTemplate}
                loadTemplateDialogOpen={loadTemplateDialogOpen}
                setLoadTemplateDialogOpen={setLoadTemplateDialogOpen}
            />
        </>
    );
};

export default MessageUsersFormEditor;
