import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Container, Grid, Skeleton, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { useDialog } from 'muibox';
import { useContext, useEffect, useMemo, useState } from 'react';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import LargeMaterialUISwitch from '../../../components/common/LargeMaterialUISwitch';
import StyledCard from '../../../components/common/StyledCard';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';
import { OnlyFansSubscriberHostessAutoReplyFreeloaders } from '../../../types/hostessAutoReplyFreeloaders';
import { isSextforceActive } from '../../../utils/common';
import AutoReplyIntroCard from './components/AutoReplyIntroCard';
import AutoReplySettingsCard from './components/AutoReplySettingsCard';
import FormAutoReplyMessageEditor from './components/FormAutoReplyMessageEditor';

const HostessAutoReply = () => {
    const settingsContext = useContext(SettingsContext);
    const {
        data: subscriber,
        isFetching: subscriberLoading,
        setServiceRunning,
        setServiceRunningLoading,
        setServiceSettingsHostessAutoReplyFreeloaders,
        setServiceSettingsHostessAutoReplyFreeloadersLoading,
    } = useSubscriber();
    const theme: Theme = useTheme();
    const dialog = useDialog();

    const autoReplyFreeloaders: OnlyFansSubscriberHostessAutoReplyFreeloaders | null = useMemo(() => {
        return subscriber && subscriber.hostess && subscriber.hostess.autoReply && subscriber.hostess.autoReply.freeloaders
            ? subscriber.hostess.autoReply.freeloaders
            : null;
    }, [subscriber]);

    const [formData, setFormData] = useState<OnlyFansSubscriberHostessAutoReplyFreeloaders>(
        autoReplyFreeloaders ||
            ({
                action: 'mute',
                active: false,
                autoUnrestrict: true,
                excludeCreators: true,
                skipExpiredFans: false,
                spentThreshold: 0,
                replies: [],
            } as OnlyFansSubscriberHostessAutoReplyFreeloaders),
    );
    const [autoReplyFreeloadersRunning, setAutoReplyFreeloadersServiceRunning] = useState<boolean>(false);
    const [followingThresholdAmountString, setFollowingThresholdAmountString] = useState<string>(
        autoReplyFreeloaders && autoReplyFreeloaders.followingThresholdAmount
            ? autoReplyFreeloaders.followingThresholdAmount.toString()
            : '0',
    );
    const [followingThresholdEnabled, setFollowingThresholdEnabled] = useState<boolean>(false);

    // Keep track which messages have restricted words with an array of booleans corresponding to each message
    const [messageHasRestrictedWords, setMessageHasRestrictedWords] = useState<boolean[]>([]);

    useEffect(() => {
        if (autoReplyFreeloaders && subscriber) {
            setFormData(autoReplyFreeloaders);
            setAutoReplyFreeloadersServiceRunning(autoReplyFreeloaders.active && isSextforceActive(subscriber) ? true : false);
            // setSpentThresholdString(autoReplyFreeloaders.spentThreshold.toString());

            if (autoReplyFreeloaders.followingThresholdAmount) {
                setFollowingThresholdAmountString(autoReplyFreeloaders.followingThresholdAmount.toString());
                setFollowingThresholdEnabled(true);
            }
        } else {
            setAutoReplyFreeloadersServiceRunning(false);
        }
    }, [autoReplyFreeloaders, subscriber]);

    // useEffect(() => {
    //     if (subscriber && !subscriberLoading) {
    //         if (subscriber.hostess && subscriber.hostess.autoReply && subscriber.hostess.autoReply.freeloaders) {
    //             setAutoReplyFreeloadersServiceRunning(subscriber.hostess.autoReply.freeloaders.active);
    //         }
    //     }
    // }, [subscriber, subscriberLoading]);

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}`} />{' '}
                        Hostess Auto Reply for {subscriber.username}
                    </Typography>

                    <AutoReplyIntroCard />

                    {!subscriber.isLoggedIn && (
                        <Alert severity="error" sx={{ marginBottom: theme.spacing(2) }}>
                            We need to relogin to this account
                            <br />
                            Please contact Admin
                        </Alert>
                    )}

                    <StyledCard>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs>
                                Auto Reply to Freeloaders is currently{' '}
                                {autoReplyFreeloadersRunning ? (
                                    <span style={{ color: theme.palette.success.dark }}>running</span>
                                ) : (
                                    <span style={{ color: theme.palette.error.main }}>not running</span>
                                )}
                                .
                            </Grid>
                            <Grid item xs="auto">
                                {subscriberLoading || setServiceRunningLoading ? (
                                    <Skeleton width={87} height={51} />
                                ) : (
                                    <LargeMaterialUISwitch
                                        checked={autoReplyFreeloadersRunning}
                                        disabled={
                                            !autoReplyFreeloaders || !autoReplyFreeloaders.replies || !autoReplyFreeloaders.replies.length
                                        }
                                        onChange={e => {
                                            setServiceRunning('hostess.autoReply.freeloaders', e.target.checked);
                                            // setAutoReplyFreeloadersServiceRunning(e.target.checked)
                                        }}
                                        color="primary"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </StyledCard>

                    <AutoReplySettingsCard
                        {...{
                            formData,
                            setFormData,
                            autoReplyFreeloaders,
                            followingThresholdEnabled,
                            setFollowingThresholdEnabled,
                            followingThresholdAmountString,
                            setFollowingThresholdAmountString,
                        }}
                    />

                    <StyledCard>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Replies</Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    These are the automatic replies the system will send to the freeloader if they match the above criteria.
                                    You can have setup as many replies as you like. On the last reply, the system will take the action you
                                    specified above. Try to nudge the freeloader <strong>politely</strong> towards spending some money.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                            {formData && formData.replies && formData.replies.length > 0 ? (
                                formData.replies.map((item, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Grid container spacing={0} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                                            <Grid item xs>
                                                <Typography variant="body1">Reply {index + 1}</Typography>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ marginTop: theme.spacing(1), marginRight: theme.spacing(1) }}
                                                    onClick={() => {
                                                        const replies = formData.replies;
                                                        replies.splice(index, 0, '');
                                                        setFormData({ ...formData, replies: replies });
                                                    }}
                                                >
                                                    Insert Above Reply {index + 1}
                                                </Button>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Grid item xs="auto">
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        startIcon={<DeleteIcon />}
                                                        sx={{ marginTop: theme.spacing(1) }}
                                                        onClick={() => {
                                                            dialog
                                                                .confirm({
                                                                    title: 'Delete Reply',
                                                                    message: `Are you sure you want to delete this reply ${index + 1}?`,
                                                                })
                                                                .then(result => {
                                                                    const replies = formData.replies;
                                                                    replies.splice(index, 1);
                                                                    setFormData({ ...formData, replies: replies });
                                                                })
                                                                .catch(() => {});
                                                        }}
                                                    >
                                                        Delete Reply {index + 1}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormAutoReplyMessageEditor
                                                    message={item}
                                                    setMessage={message => {
                                                        const replies = formData.replies;
                                                        replies[index] = message;
                                                        setFormData({ ...formData, replies: replies });
                                                    }}
                                                    messageHasRestrictedWords={messageHasRestrictedWords[index]}
                                                    setMessageHasRestrictedWords={messageHasRestrictedWord => {
                                                        const messageHasRestrictedWordsCopy = messageHasRestrictedWords;
                                                        messageHasRestrictedWordsCopy[index] = messageHasRestrictedWord;
                                                        setMessageHasRestrictedWords(messageHasRestrictedWordsCopy);
                                                    }}
                                                />
                                                {/* <OnlyFansMessageEditor
                                                    message={item}
                                                    setMessage={message => {
                                                        const replies = formData.replies;
                                                        replies[index] = message;
                                                        setFormData({ ...formData, replies: replies });
                                                    }}
                                                    messageHasRestrictedWords={messageHasRestrictedWords[index]}
                                                    setMessageHasRestrictedWords={messageHasRestrictedWord => {
                                                        const messageHasRestrictedWordsCopy = messageHasRestrictedWords;
                                                        messageHasRestrictedWordsCopy[index] = messageHasRestrictedWord;
                                                        setMessageHasRestrictedWords(messageHasRestrictedWordsCopy);
                                                    }}
                                                    height={100}
                                                /> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12}>
                                    <Typography variant="body1">No replies</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    sx={{ marginTop: theme.spacing(1) }}
                                    onClick={() => {
                                        const replies = formData.replies;
                                        replies.push('');
                                        setFormData({ ...formData, replies: replies });
                                    }}
                                >
                                    Add Reply
                                </Button>
                            </Grid>
                        </Grid>
                    </StyledCard>

                    <StyledCard>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    loading={setServiceSettingsHostessAutoReplyFreeloadersLoading}
                                    sx={{ marginTop: theme.spacing(1) }}
                                    disabled={
                                        !formData ||
                                        // (formData && formData.replies && formData.replies.length === 0) ||
                                        (formData && formData.replies && formData.replies.some(item => item === ''))
                                    }
                                    onClick={() => {
                                        // Are any of the replies empty?
                                        if (formData) {
                                            // Save the form data
                                            setServiceSettingsHostessAutoReplyFreeloaders(formData).then(() => {
                                                dialog
                                                    .alert({
                                                        title: 'Success',
                                                        message: 'Settings saved successfully',
                                                    })
                                                    .then(() => {});
                                            });
                                        }
                                    }}
                                >
                                    Save Changes
                                </LoadingButton>
                            </Grid>
                            <Grid item xs="auto">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    sx={{ marginTop: theme.spacing(1) }}
                                    onClick={() => {
                                        if (formData) {
                                            setFormData({} as OnlyFansSubscriberHostessAutoReplyFreeloaders);
                                        }
                                    }}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </StyledCard>
                </>
            )}
        </Container>
    );
};

export default HostessAutoReply;
