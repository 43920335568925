import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

export namespace OnlyFansSettingsNotificationsTransport {
    export type Transports = 'email' | 'message' | 'telegram' | 'webpush' | 'toast';

    export interface Response {
        code: string;
        title: string;
        items: Item[];
    }

    export interface Item {
        code: string;
        title: string;
        isEnabled: boolean;
        value: number;
    }
}

const useSubscriberSettingsNotifications = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    const fetchSettingsNotificationsSite = async (): Promise<OnlyFansSettingsNotificationsTransport.Response[]> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${params.userId}/notifications/site`;

        return axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(async response => response.data)
            .catch(async error => {
                console.error('fetchSettingsNotificationsSite', error);

                await handleHttpError(error, dialog).then(() => {
                    return Promise.reject(error);
                });
            });
    };

    const settingsNotificationsSite = useQuery(
        ['settingsNotificationsSite', params.userId, userContext.jwtToken],
        () => fetchSettingsNotificationsSite(),
        {
            refetchOnWindowFocus: false,
            staleTime: 100,
            retry: false,
            enabled: userContext.jwtToken && settingsContext.apiKey && userContext.user.username && params.userId ? true : false,
        },
    );

    return {
        ...settingsNotificationsSite,
    };
};

export default useSubscriberSettingsNotifications;
