import { Checkbox, FormControlLabel, Grid, Theme, Typography, useTheme } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import useSextforceBigBrotherRestrictedWords from '../../../../hooks/useSextforceBigBrotherRestrictedWords';
import useSubscriberMedia from '../../../../hooks/useSubscriberMedia';
import { SettingsContext } from '../../../../store/SettingsContext';
import {
    createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails,
    OnlyFansSubscriberMessageUsersAutoMessageDetails,
} from '../../../../types/messageUsersAutoSendWelcomeMessage';
import { OnlyFansVaultMedias } from '../../../../types/onlyFansTypes';
import { loadDraftsJSEditorStateFromHtml } from '../../../../utils/common';
import StyledCard from '../../../common/StyledCard';
import MessageUsersFormAddMedias from '../sendMessage/MessageUsersFormAddMedias';
import MessageUsersFormEditor from '../sendMessage/MessageUsersFormEditor';
import MessageUsersPriceLock from '../sendMessage/MessageUsersPriceLock';
import MessageUsersTagCreators from '../sendMessage/MessageUsersTagCreators';
import AutoSendWelcomeMessageCurrentSettings from './AutoSendWelcomeMessageCurrentSettings';

type Props = {
    title: string;
    description: string;
    subscriber: any;
    initialValues: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
    messageDetails: OnlyFansSubscriberMessageUsersAutoMessageDetails;
    setMessageDetails: React.Dispatch<React.SetStateAction<OnlyFansSubscriberMessageUsersAutoMessageDetails | null>>;
    vaultAlbumName: string | undefined;
    setVaultAlbumName: (vaultAlbumName: string | undefined) => void;
    saveTemplate: boolean;
    setSaveTemplate: (saveTemplate: boolean) => void;
    saveTemplateName: string;
    setSaveTemplateName: (saveTemplateName: string) => void;
    setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>;
};

const AutoSendWelcomeMessageDetailsForm = (props: Props) => {
    const {
        title,
        description,
        subscriber,
        initialValues,
        messageDetails,
        setMessageDetails,
        vaultAlbumName,
        setVaultAlbumName,
        saveTemplate,
        setSaveTemplate,
        saveTemplateName,
        setSaveTemplateName,
        setIsFormValid,
    } = props;
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const { getThumbnail: getSubscriberThumbnail, getAudio: getSubscriberAudio } = useSubscriberMedia();
    const { data: restrictedWords, isLoading: isLoadingRestrictedWords } = useSextforceBigBrotherRestrictedWords();

    const [includeMedia, setIncludeMedia] = useState<boolean>(messageDetails.mediaFiles.length > 0 ? true : false);
    const [vaultAlbum, setVaultAlbum] = useState<number>(-1);
    const [medias, setMedias] = useState<OnlyFansVaultMedias.RestructuredResponseItem[]>(messageDetails.mediaFiles || []);
    const [mediasPreviews, setMediasPreviews] = useState<number[]>(messageDetails.mediaFilesPreviews || []);
    const [thumbnails, setThumbnails] = useState<any[]>([]);
    const [tagCreators, setTagCreators] = useState<boolean>(messageDetails.taggedCreators.length > 0 ? true : false);
    const [taggedCreators, setTaggedCreators] = useState<{ id: number; name: string }[]>(messageDetails.taggedCreators || []);
    const [taggedReleaseForms, setTaggedReleaseForms] = useState<{ id: number; name: string }[]>(messageDetails.taggedReleaseForms || []);
    const [lockMessage, setLockMessage] = useState<boolean>(messageDetails.lockMessagePrice !== null ? true : false);
    const [editorState, setEditorState] = useState(loadDraftsJSEditorStateFromHtml(messageDetails.message));
    const [messageHasRestrictedWords, setMessageHasRestrictedWords] = useState<boolean>(false);
    const [messageFoundRestrictedWords, setMessageFoundRestrictedWords] = useState<string[]>([]);
    const [usernameReplacement, setUsernameReplacement] = useState<string>(messageDetails.replaceUsername || '');

    // Check the message doesn't contain any OnlyFans restricted words
    const checkForRestrictedWords = useCallback(
        (text: string) => {
            if (text && !isLoadingRestrictedWords && restrictedWords && subscriber) {
                const lowerCaseMessage = text.toLowerCase();
                const wordsInMessage = lowerCaseMessage.split(/[\s\n/,.]+/);

                const restrictedWordsFound = wordsInMessage.filter(word => restrictedWords.has(word));

                if (subscriber.sextforce?.bigBrother?.restrictedWordsWhiteList?.includes(restrictedWordsFound)) {
                    // Remove any words that are in the whitelist
                    restrictedWordsFound.filter(word => !subscriber.sextforce?.bigBrother?.restrictedWordsWhiteList?.includes(word));
                }

                setMessageHasRestrictedWords(restrictedWordsFound.length > 0 ? true : false);
                setMessageFoundRestrictedWords(restrictedWordsFound);
            } else {
                setMessageHasRestrictedWords(false);
                setMessageFoundRestrictedWords([]);
            }
        },
        [isLoadingRestrictedWords, restrictedWords, subscriber],
    );

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...(prevState || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails()),
                mediaFiles: medias,
            };
        });
    }, [medias, setMessageDetails]);

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...(prevState || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails()),
                taggedCreators: taggedCreators,
            };
        });
    }, [taggedCreators, setMessageDetails]);

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...(prevState || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails()),
                taggedReleaseForms: taggedReleaseForms,
            };
        });
    }, [taggedReleaseForms, setMessageDetails]);

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...(prevState || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails()),
                mediaFilesPreviews: mediasPreviews,
            };
        });
    }, [mediasPreviews, setMessageDetails]);

    useEffect(() => {
        setMessageDetails(prevState => {
            return {
                ...(prevState || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails()),
                replaceUsername: usernameReplacement,
            };
        });
    }, [setMessageDetails, usernameReplacement]);

    useEffect(() => {
        if (!lockMessage) {
            setMessageDetails(prevState => {
                return {
                    ...(prevState || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails()),
                    lockMessagePrice: null,
                };
            });
        }
    }, [lockMessage, setMessageDetails]);

    useEffect(() => {
        if (!includeMedia) {
            setMedias([]);
            setMediasPreviews([]);
        }
    }, [includeMedia]);

    useEffect(() => {
        if (!messageDetails || !messageDetails.active) {
            setIsFormValid(true);

            return;
        }

        if (messageDetails && !messageDetails.active) {
            setIsFormValid(false);

            return;
        }

        if (messageDetails) {
            if (messageDetails.message.trim().length === 0) {
                setIsFormValid(false);

                return;
            }

            if (includeMedia && medias.length === 0) {
                setIsFormValid(false);

                return;
            }

            if (lockMessage && (messageDetails.lockMessagePrice === null || messageDetails.lockMessagePrice === 0)) {
                setIsFormValid(false);

                return;
            }

            if (tagCreators && taggedCreators.length === 0 && taggedReleaseForms.length === 0) {
                setIsFormValid(false);

                return;
            }
        }

        setIsFormValid(true);
    }, [
        includeMedia,
        lockMessage,
        medias.length,
        messageDetails,
        setIsFormValid,
        tagCreators,
        taggedCreators.length,
        taggedReleaseForms.length,
    ]);

    const getThumbnail = (thumbId: number, thumbUrl: string) => {
        getSubscriberThumbnail(subscriber, thumbId, thumbUrl)?.then(blob => {
            if (blob) {
                setThumbnails(prevState => {
                    return [...prevState, { thumbId, blob }];
                });
            } else {
                setThumbnails(prevState => {
                    return [...prevState, { thumbId, blob: null }];
                });
            }
        });
    };

    const getAudio = (url: string) => getSubscriberAudio(subscriber, url);

    return (
        <StyledCard sx={{ backgroundColor: theme.palette.grey[100] }}>
            <Grid container spacing={1} flexGrow={0} alignItems="center" sx={{ mb: 2 }}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="medium"
                                checked={messageDetails && messageDetails.active ? true : false}
                                onChange={(e, checked) => {
                                    setMessageDetails(prevState => {
                                        return {
                                            ...(prevState || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails()),
                                            active: checked,
                                        };
                                    });
                                }}
                            />
                        }
                        label={<Typography variant="h6">{title}</Typography>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">{description}</Typography>
                </Grid>
            </Grid>

            {initialValues && initialValues.active && (
                <AutoSendWelcomeMessageCurrentSettings messageDetails={initialValues} getAudio={getAudio} />
            )}

            <>
                <Grid container spacing={1} flexGrow={0} alignItems="center">
                    <Grid item xs={12}>
                        <MessageUsersFormAddMedias
                            serviceName="autoSendWelcomeMessage"
                            includeMedia={includeMedia}
                            setIncludeMedia={setIncludeMedia}
                            vaultAlbum={vaultAlbum}
                            setVaultAlbum={setVaultAlbum}
                            vaultAlbumName={vaultAlbumName}
                            setVaultAlbumName={setVaultAlbumName}
                            medias={medias}
                            setMedias={setMedias}
                            mediasPreviews={mediasPreviews}
                            setMediasPreviews={setMediasPreviews}
                            thumbnails={thumbnails}
                            getThumbnail={getThumbnail}
                            getAudio={getAudio}
                            tagCreators={tagCreators}
                            setTagCreators={setTagCreators}
                            taggedCreators={taggedCreators}
                            setTaggedCreators={setTaggedCreators}
                            taggedReleaseForms={taggedReleaseForms}
                            setTaggedReleaseForms={setTaggedReleaseForms}
                            session={null}
                            disabled={!messageDetails || !messageDetails.active}
                        />
                    </Grid>
                </Grid>

                {includeMedia && (
                    <MessageUsersTagCreators
                        subscriber={subscriber}
                        tagCreators={tagCreators}
                        setTagCreators={setTagCreators}
                        taggedCreators={taggedCreators}
                        setTaggedCreators={setTaggedCreators}
                        taggedReleaseForms={taggedReleaseForms}
                        setTaggedReleaseForms={setTaggedReleaseForms}
                        session={null}
                        theme={theme}
                        disabled={!messageDetails || !messageDetails.active}
                    />
                )}

                <MessageUsersPriceLock
                    lockMessage={lockMessage}
                    setLockMessage={setLockMessage}
                    lockMessagePrice={(messageDetails !== null && messageDetails.lockMessagePrice) || 0}
                    setLockMessagePrice={lockMessagePrice => {
                        setMessageDetails(prevState => {
                            return {
                                ...(prevState || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails()),
                                lockMessagePrice,
                            };
                        });
                    }}
                    medias={medias}
                    thumbnails={thumbnails}
                    mediasPreviews={mediasPreviews}
                    setMediasPreviews={setMediasPreviews}
                    getAudio={getAudio}
                    session={null}
                    theme={theme}
                    disabled={!messageDetails || !messageDetails.active}
                />

                <MessageUsersFormEditor
                    subscriber={subscriber}
                    message={(messageDetails !== null && messageDetails.message) || ''}
                    setMessage={message => {
                        setMessageDetails(prevState => {
                            return {
                                ...(prevState || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails()),
                                message,
                            };
                        });
                    }}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    messageHasRestrictedWords={messageHasRestrictedWords}
                    messageFoundRestrictedWords={messageFoundRestrictedWords}
                    checkForRestrictedWords={checkForRestrictedWords}
                    usernameReplacement={usernameReplacement}
                    setUsernameReplacement={setUsernameReplacement}
                    taggedCreators={taggedCreators}
                    taggedReleaseForms={taggedReleaseForms}
                    lockMessage={lockMessage}
                    lockMessagePrice={(messageDetails !== null && messageDetails.lockMessagePrice) || 0}
                    saveTemplate={saveTemplate}
                    setSaveTemplate={setSaveTemplate}
                    saveTemplateName={saveTemplateName}
                    setSaveTemplateName={setSaveTemplateName}
                    session={null}
                    theme={theme}
                    disabled={!messageDetails || !messageDetails.active}
                />
            </>
        </StyledCard>
    );
};

export default AutoSendWelcomeMessageDetailsForm;
