import moment from 'moment-timezone';
import { Theme } from '@mui/system';
import { Card, CardContent, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import MessageUsersCurrentSession from './MessageUsersCurrentSession';
import MessageUsersUnsendCurrentSession from '../unsendMessage/MessageUsersUnsendCurrentSession';

type MessageUsersOverviewStatusProps = {
    subscriber: any;
    currentSessions: any;
    loading: boolean;
    theme: Theme;
};

const MessageUsersOverviewStatus = (props: MessageUsersOverviewStatusProps) => {
    const { subscriber, currentSessions, loading, theme } = props;
    const timezone: string = moment.tz.guess();

    const isMessageUsersSessionRunning: boolean = currentSessions && currentSessions.messageUsers.sessions.length > 0 ? true : false;
    const isMessageUsersJobInQueue: boolean = currentSessions && currentSessions.messageUsers.queue ? true : false;
    const isUnsendMessageSessionRunning: boolean = currentSessions && currentSessions.unsendMessage.sessions.length > 0 ? true : false;
    const isUnsendMessageJobInQueue: boolean = currentSessions && currentSessions.unsendMessage.queue ? true : false;

    return (
        <>
            <Typography variant="h5" sx={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(2) }}>
                Status
            </Typography>

            {loading ? (
                <Skeleton />
            ) : (
                <>
                    {isMessageUsersSessionRunning || isMessageUsersJobInQueue ? (
                        <MessageUsersCurrentSession
                            currentSession={currentSessions.messageUsers.sessions[0]}
                            isSessionRunning={isMessageUsersSessionRunning}
                            isJobRunning={isMessageUsersJobInQueue}
                            theme={theme}
                        />
                    ) : (
                        <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Message Users Last Run{' '}
                                            <Tooltip title={`Time Zone: ${timezone}`}>
                                                <InfoIcon fontSize="small" />
                                            </Tooltip>
                                        </Typography>

                                        {subscriber.lastMessageUsersDate ? (
                                            <>{moment(subscriber.lastMessageUsersDate).tz(timezone).format('L hh:mm a')} </>
                                        ) : (
                                            'Message Users has not run yet on this account'
                                        )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}

                    {isUnsendMessageSessionRunning || isUnsendMessageJobInQueue ? (
                        <MessageUsersUnsendCurrentSession
                            currentSession={currentSessions.unsendMessage.sessions[0]}
                            isSessionRunning={isUnsendMessageSessionRunning}
                            isJobRunning={isUnsendMessageJobInQueue}
                            theme={theme}
                        />
                    ) : (
                        <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Unsend Message Last Run{' '}
                                            <Tooltip title={`Time Zone: ${timezone}`}>
                                                <InfoIcon fontSize="small" />
                                            </Tooltip>
                                        </Typography>

                                        {subscriber.lastUnsendMessageDate ? (
                                            <>{moment(subscriber.lastUnsendMessageDate).tz(timezone).format('L hh:mm a')} </>
                                        ) : (
                                            'Unsend Message has not run yet on this account'
                                        )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                </>
            )}
        </>
    );
};

export default MessageUsersOverviewStatus;
