import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { d2f } from '../utils/common';
import HeaderSideMenuSubscriberServiceActions from './HeaderSideMenuSubscriberServiceActions';
import IconOnlyFans from './icons/IconOnlyFans';

const creditStatus = (amount: number): string => {
    if (amount === 0) {
        return 'none';
    }

    if (amount < 5) {
        return 'low';
    }

    return 'good';
};

const status: any = {
    good: {
        color: green[900],
        background: green[100],
    },
    low: {
        color: orange[900],
        background: orange[100],
    },
    none: {
        color: red[900],
        background: red[100],
    },
};

type HeaderSideMenuSubscriberProps = {
    subscriber: any;
    open: any;
    handleToggleSubscriberMenu: (username: string) => void;
    handleDrawerToggle: (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const HeaderSideMenuSubscriber = (props: HeaderSideMenuSubscriberProps) => {
    const { subscriber, open, handleToggleSubscriberMenu, handleDrawerToggle } = props;
    const settingsContext = useContext(SettingsContext);

    console.log(open);

    return (
        <span>
            <ListItemButton
                onClick={() => {
                    handleToggleSubscriberMenu(subscriber.username);
                }}
                id="expandableMenuItem"
            >
                <ListItemIcon
                    sx={{
                        minWidth: '32px',
                    }}
                >
                    <IconOnlyFans />
                </ListItemIcon>
                <ListItemText primary={subscriber.username} />
                {open[subscriber.username] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={open[subscriber.username]} timeout="auto" unmountOnExit>
                <List dense disablePadding sx={{ pl: 2 }}>
                    <ListItemButton
                        component={Link}
                        to={`/subscribers/${subscriber._id}`}
                        onClick={handleDrawerToggle}
                        onKeyDown={handleDrawerToggle}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: '32px',
                            }}
                        >
                            <PeopleAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Account Overview" />
                    </ListItemButton>

                    <ListItemButton
                        component={Link}
                        to={`/subscribers/${subscriber._id}/account/sharedCreditCheckout`}
                        onClick={handleDrawerToggle}
                        onKeyDown={handleDrawerToggle}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: '32px',
                            }}
                        >
                            <CurrencyPoundIcon
                                htmlColor={
                                    status[
                                        creditStatus(
                                            subscriber.financial && subscriber.financial.sharedCredit
                                                ? d2f(subscriber.financial.sharedCredit)
                                                : 0,
                                        )
                                    ].color
                                }
                            />
                        </ListItemIcon>
                        <ListItemText primary="Top Up Shared Credit" />
                    </ListItemButton>

                    {settingsContext &&
                        settingsContext.services &&
                        Object.keys(settingsContext.services)
                            .filter((key: string) => settingsContext.services[key].visible === true)
                            .map((key: string) => (
                                <HeaderSideMenuSubscriberServiceActions
                                    subscriber={subscriber}
                                    service={key}
                                    handleDrawerToggle={handleDrawerToggle}
                                    key={key}
                                />
                            ))}

                    <Divider />
                </List>
            </Collapse>
        </span>
    );
};

export default HeaderSideMenuSubscriber;
