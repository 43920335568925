import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
    Card,
    CardContent,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Tooltip,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { useEffect, useState } from 'react';
import { OnlyFansVaultMedias } from '../../../../types/onlyFansTypes';
import MessageUsersPriceLockPreviewSelector from './MessageUsersPriceLockPreviewSelector';

type Props = {
    lockMessage: boolean;
    setLockMessage: (lockMessage: boolean) => void;
    lockMessagePrice: number;
    setLockMessagePrice: (price: number) => void;
    thumbnails: any[];
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    mediasPreviews: number[];
    setMediasPreviews: React.Dispatch<React.SetStateAction<number[]>>;
    getAudio: (url: string) => Promise<Blob | null>;
    session?: any;
    theme: Theme;
    disabled?: boolean;
};

const MessageUsersPriceLock = (props: Props) => {
    const {
        lockMessage,
        setLockMessage,
        lockMessagePrice,
        setLockMessagePrice,
        thumbnails,
        medias,
        mediasPreviews,
        setMediasPreviews,
        getAudio,
        session,
        theme,
        disabled,
    } = props;

    const [lockMessagePriceString, setLockMessagePriceString] = useState(lockMessagePrice.toString());

    useEffect(() => {
        if (session && session.payload && session.payload.lockMessage) {
            setLockMessage(session.payload.lockMessage);
            setLockMessagePrice(session.payload.lockMessagePrice);
            setLockMessagePriceString(session.payload.lockMessagePrice.toString());
        }
    }, [session, setLockMessage, setLockMessagePrice]);

    const handlePreviewsSelectAllClicked = () => {
        const newPreviews = medias.map(media => media.id);

        setMediasPreviews(newPreviews);
    };

    const handlePreviewsClearSelectionClicked = () => {
        setMediasPreviews([]);
    };

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
            <Grid item xs={12}>
                <Card variant="elevation">
                    <CardContent>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={lockMessage}
                                            onChange={(e, checked) => {
                                                setLockMessage(checked);
                                            }}
                                            disabled={disabled}
                                        />
                                    }
                                    label={<Typography variant="h6">Lock Message (PPV)?</Typography>}
                                />
                            </Grid>
                            {lockMessage && (
                                <>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            spacing={2}
                                            flexGrow={0}
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{ marginBottom: theme.spacing(1) }}
                                        >
                                            <Grid item xs="auto">
                                                Price
                                            </Grid>
                                            <Grid item xs="auto">
                                                <FormControl disabled={disabled}>
                                                    <InputLabel htmlFor="amount">Price</InputLabel>
                                                    <OutlinedInput
                                                        id="amount"
                                                        size="medium"
                                                        inputProps={{ inputMode: 'text', pattern: /([0-9]+([.][0-9]*)?|[.][0-9]+)/ }}
                                                        value={lockMessagePriceString}
                                                        onChange={e => {
                                                            e.preventDefault();

                                                            if (/^([\d]*[,.]?[\d]{0,2})$/.test(e.target.value)) {
                                                                const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                                                                if (isNaN(parsed) || parsed === 0) {
                                                                    setLockMessagePrice(0);
                                                                    setLockMessagePriceString('0');
                                                                } else {
                                                                    setLockMessagePrice(parsed);
                                                                    setLockMessagePriceString(e.target.value.replace(/^0+/, ''));
                                                                }
                                                            }
                                                        }}
                                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                        label="Price"
                                                        error={lockMessagePrice < 3}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs sx={{ color: theme.palette.grey[500] }}>
                                                Minimum: $3
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="h6">Preview (Optional)</Typography>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Tooltip title="Select All">
                                            <IconButton
                                                onClick={async () => {
                                                    handlePreviewsSelectAllClicked();
                                                }}
                                                disabled={disabled}
                                                color="primary"
                                            >
                                                <DoneAllIcon fontSize="large" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={'auto'}>
                                        <Tooltip title="Clear Selection">
                                            <IconButton
                                                color="error"
                                                onClick={() => {
                                                    handlePreviewsClearSelectionClicked();
                                                }}
                                                disabled={disabled}
                                            >
                                                <ClearIcon fontSize="large" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginBottom: theme.spacing(1) }}>
                                        Select the media that will be used as a preview. These will be fully visible to the fan!
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs>
                                                <div style={{ color: theme.palette.grey[500] }}>Click to select</div>
                                            </Grid>
                                            <Grid item xs={'auto'}>
                                                <Chip
                                                    color={mediasPreviews.length > 40 ? 'error' : 'secondary'}
                                                    icon={<CheckCircleIcon />}
                                                    label={`${mediasPreviews.length}/40 selected`}
                                                    disabled={disabled}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MessageUsersPriceLockPreviewSelector
                                            medias={medias}
                                            mediasPreviews={mediasPreviews}
                                            setMediasPreviews={setMediasPreviews}
                                            thumbnails={thumbnails}
                                            getAudio={getAudio}
                                            theme={theme}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MessageUsersPriceLock;
