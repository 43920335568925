import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Alert, Container, Grid, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import OverviewCard from '../../../components/common/OverviewCard';
import ServiceActionsBlocks from '../../../components/common/ServiceActions';
import ServiceStatusCard from '../../../components/common/ServiceStatuxCard';
import SharedCreditCard from '../../../components/common/SharedCreditCard';
import FollowBackServiceDescription from '../../../components/services/followBack/FollowBackServiceDescription';
import FollowBackOverviewGraphs from '../../../components/services/followBack/overview/FollowBackOverviewGraphs';
import FollowBackOverviewSettings from '../../../components/services/followBack/overview/FollowBackOverviewSettings';
import FollowBackOverviewStatus from '../../../components/services/followBack/overview/FollowBackOverviewStatus';
import { SettingsContext } from '../../../store/SettingsContext';
import { UserContext } from '../../../store/UserContext';
import { handleHttpError, handleHttpErrorResponse } from '../../../utils/common';

const FollowBackOverview = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const navigate: NavigateFunction = useNavigate();
    const dialog = useDialog();
    const params = useParams();

    const [subscriber, setSubscriber] = useState<any | null>(null);
    const [subscriberLoading, setSubscriberLoading] = useState<boolean>(false);
    const [refreshCurrentSession, setRefreshCurrentSession] = useState<boolean>(false);

    // Report
    const timezone: string = moment.tz.guess();

    useEffect(() => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            const fetchSubscriber = async () => {
                const query: string = `${settingsContext.routes.subscribers.find}${params.userId}`;

                setSubscriberLoading(true);

                await fetch(query, {
                    method: 'get',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .then(data => {
                        setSubscriber(data);

                        if (!data || !('_id' in data) || !data._id) {
                            navigate('/');
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        handleHttpError(error, dialog);
                    });

                setSubscriberLoading(false);
            };

            fetchSubscriber();
        }
    }, [userContext, settingsContext, params, dialog, navigate]);

    // Fetch Follow-Back Report
    const fetchFollowBackReport = async (startDate: Date, endDate: Date, timezone: string): Promise<any[]> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.followBack.base}${params.userId}/report?${new URLSearchParams({
                startDate: moment(startDate).tz(timezone, true).toISOString(),
                endDate: moment(endDate).tz(timezone, true).toISOString(),
            })}`;

            return fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .then(data => {
                    return data;
                })
                .catch(error => {
                    handleHttpError(error, dialog);
                });
        }

        return [];
    };

    // Query hook for fetching Follow-Back report for the last 7 days
    const { data: reportData, isLoading: isFetchingReport } = useQuery(
        ['followBackReport', subscriber],
        () => {
            if (subscriber) {
                const dateFrom: moment.Moment = moment().subtract(7, 'days');
                const dateTo: moment.Moment = moment();

                return fetchFollowBackReport(dateFrom.toDate(), dateTo.toDate(), timezone);
            }
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 0,
            enabled: subscriber !== null,
        },
    );

    // Fetch Follow-Back Overview
    const fetchCurrentSession = async (): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.followBack.base}${params.userId}/currentSession`;

            return fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const { data: currentSession, isLoading: currentSessionLoading } = useQuery(
        ['followBackOverview', subscriber],
        () => {
            if (subscriber) {
                return fetchCurrentSession();
            }
        },
        {
            onSuccess: (data: any) => {
                setRefreshCurrentSession(data && (!Array.isArray(data) || (Array.isArray(data) && data.length > 0)) ? true : false);
            },
            refetchOnWindowFocus: false,
            staleTime: 0,
            enabled: subscriber ? true : false,
            refetchInterval: refreshCurrentSession ? 30000 : 0,
        },
    );

    // Fetch Follow-Back Overview
    const fetchTotalFollowed = async (): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.followBack.base}${params.userId}/totalFollowed`;

            return fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const { data: totalFollowed, isLoading: totalFollowedLoading } = useQuery(
        ['followBackTotalFollowed', subscriber],
        () => {
            if (subscriber) {
                return fetchTotalFollowed();
            }
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 0,
            enabled: subscriber ? true : false,
        },
    );

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton {...(subscriber && { url: `/subscribers/${subscriber._id}` })} /> Follow-Back Overview for{' '}
                        {subscriber.username}
                    </Typography>

                    <Alert severity="info" sx={{ marginBottom: theme.spacing(2) }}>
                        Please make sure you read the{' '}
                        <Link
                            to={`/subscribers/${subscriber._id}/services/followBack/welcome`}
                            style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                        >
                            <strong>Welcome</strong>
                        </Link>{' '}
                        information first!
                    </Alert>

                    {subscriber.followBack ? (
                        <>
                            <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <ServiceStatusCard
                                        subscriber={subscriber}
                                        serviceName="followBack"
                                        alwaysActive={settingsContext.services.followBack.alwaysActive}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <OverviewCard
                                        title="Total Followed Since Started"
                                        value={
                                            totalFollowed && totalFollowed.totalFollowed ? totalFollowed.totalFollowed.toLocaleString() : 0
                                        }
                                        loading={totalFollowedLoading}
                                        icon={<EmojiEventsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                        theme={theme}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <SharedCreditCard showTopUpButton={true} />
                                </Grid>
                            </Grid>

                            <FollowBackOverviewSettings subscriber={subscriber} loading={subscriberLoading} theme={theme} />

                            <FollowBackOverviewStatus
                                subscriber={subscriber}
                                currentSession={currentSession}
                                loading={currentSessionLoading}
                                theme={theme}
                            />

                            <FollowBackOverviewGraphs
                                subscriber={subscriber}
                                data={reportData || []}
                                loading={subscriberLoading && isFetchingReport}
                                theme={theme}
                            />

                            <ServiceActionsBlocks
                                subscriber={subscriber}
                                actions={settingsContext.services.followBack.actions}
                                theme={theme}
                            />
                        </>
                    ) : (
                        <>
                            <Alert severity="warning" sx={{ marginBottom: theme.spacing(2) }}>
                                You do not have Follow-Back setup on this account. Please contact Admin for more information.
                            </Alert>

                            <FollowBackServiceDescription theme={theme} />
                        </>
                    )}
                </>
            )}
        </Container>
    );
};

export default FollowBackOverview;
