import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useContext, useState, useEffect } from 'react';
import { Link, NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import {
    Container,
    Card,
    Typography,
    CardContent,
    Grid,
    Alert,
    Backdrop,
    CircularProgress,
    Button,
    Box,
    Radio,
    RadioGroup,
    FormControlLabel,
    InputAdornment,
    Checkbox,
    FormGroup,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { UserContext } from '../../../store/UserContext';
import { SettingsContext } from '../../../store/SettingsContext';
import { useDialog } from 'muibox';
import { handleHttpError, handleHttpErrorResponse } from '../../../utils/common';
import FormTextField from '../../../components/forms/FormTextField';
import FormCheckBox from '../../../components/forms/FormCheckBox';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export type FollowBackSettingsInput = {
    active: boolean;
    followBackLimit: number;
    followFansActive: boolean;
    total_spent?: number;
    tips?: number;
    duration?: number;
    inactive?: number;
    recommenders?: boolean;
    online?: boolean;
    thresholdMonths?: number;
    thresholdMinTotalSpent?: number;
};

const settingsInputsSchema = yup.object().shape({
    active: yup.boolean().required(),
    followBackLimit: yup.number().min(-1),
    followFansActive: yup.boolean(),
    total_spent: yup.number(),
    tips: yup.number(),
    duration: yup.number(),
    inactive: yup.number(),
    recommenders: yup.boolean(),
    online: yup.boolean(),
    thresholdMonths: yup.number(),
    thresholdMinTotalSpent: yup.number(),
});

const FollowBackSettings = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const pageParams = useParams();
    const theme: Theme = useTheme();
    const navigate: NavigateFunction = useNavigate();
    const dialog = useDialog();
    const params = useParams();
    const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

    const [subscriber, setSubscriber] = useState<any | null>(null);

    const { control, handleSubmit, setValue, formState } = useForm<FollowBackSettingsInput>({
        resolver: yupResolver(settingsInputsSchema),
        shouldUnregister: false,
        defaultValues: {
            followBackLimit: -1,
            followFansActive: false,
            thresholdMinTotalSpent: 1,
            thresholdMonths: 0,
            total_spent: 1,
            tips: 0,
        },
    });

    const [followBackLimitEnabled, setFollowBackLimitEnabled] = useState<string>('no');
    const [followBackFilterType, setFollowBackFilterType] = useState<string>('none');
    const [filterTotalSpentEnabled, setFilterTotalSpentEnable] = useState<boolean>(false);
    const [filterTipsEnabled, setFilterTipsEnable] = useState<boolean>(false);
    const [filterThresholdMonthsEnabled, setFilterThresholdMonthsEnable] = useState<boolean>(false);
    const [filterThresholdMinTotalSpentEnabled, setFilterThresholdMinTotalSpentEnable] = useState<boolean>(false);

    useEffect(() => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            const fetchSubscriber = async () => {
                const query: string = `${settingsContext.routes.subscribers.find}${params.userId}`;

                setShowBackdrop(true);

                await fetch(query, {
                    method: 'get',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .then(async data => {
                        if (!data || !('_id' in data) || !data._id) {
                            navigate('/');
                        }

                        setSubscriber(data);
                    })
                    .catch(error => {
                        console.error(error);
                        handleHttpError(error, dialog);
                    });

                setShowBackdrop(false);
            };

            fetchSubscriber();
        }
    }, [userContext, settingsContext, params, dialog, navigate]);

    useEffect(() => {
        if (subscriber && subscriber.followBack) {
            const { followBack } = subscriber;

            setTimeout(() => {
                setValue('followBackLimit', followBack.followBackLimit ? followBack.followBackLimit : -1);
                setValue('followFansActive', followBack.followFansActive ? true : false);
                // setValue('followBackFilter', data.followBack.followBackFilter || {});
                setFollowBackLimitEnabled(followBack.followBackLimit && followBack.followBackLimit === -1 ? 'no' : 'yes');

                if (!followBack.followBackFilter || Object.keys(followBack.followBackFilter).length === 0) {
                    setFollowBackFilterType('none');
                } else if (Object.keys(followBack.followBackFilter).find((filter: string) => filter.includes('threshold'))) {
                    setFollowBackFilterType('strict');

                    setFilterThresholdMinTotalSpentEnable(followBack.followBackFilter.thresholdMinTotalSpent ? true : false);
                    setFilterThresholdMonthsEnable(followBack.followBackFilter.thresholdMonths ? true : false);

                    followBack.followBackFilter.thresholdMinTotalSpent &&
                        setValue('thresholdMinTotalSpent', followBack.followBackFilter.thresholdMinTotalSpent);
                    followBack.followBackFilter.thresholdMonths && setValue('thresholdMonths', followBack.followBackFilter.thresholdMonths);
                } else {
                    setFollowBackFilterType('priority');

                    setFilterTotalSpentEnable(followBack.followBackFilter.total_spent ? true : false);
                    setFilterTipsEnable(followBack.followBackFilter.tips ? true : false);

                    followBack.followBackFilter.total_spent && setValue('total_spent', followBack.followBackFilter.total_spent);
                    followBack.followBackFilter.tips && setValue('tips', followBack.followBackFilter.total_spent);
                }
            });
        }
    }, [setValue, subscriber]);

    const onSubmit: SubmitHandler<FollowBackSettingsInput> = newSettings => {
        const query: string = `${settingsContext.routes.followBack.base}${pageParams.userId}/settings`;

        // Constructs new settings object
        const followBackFilter: any = {};

        if (followBackFilterType === 'priority') {
            if (filterTotalSpentEnabled) {
                followBackFilter['total_spent'] = newSettings.total_spent;
            }

            if (filterTipsEnabled) {
                followBackFilter['tips'] = newSettings.tips;
            }
        } else if (followBackFilterType === 'strict') {
            if (filterThresholdMinTotalSpentEnabled) {
                followBackFilter['thresholdMinTotalSpent'] = newSettings.thresholdMinTotalSpent;
            }

            if (filterThresholdMonthsEnabled) {
                followBackFilter['thresholdMonths'] = newSettings.thresholdMonths;
            }
        }

        const postSettings = {
            active: newSettings.active,
            followBackLimit: followBackLimitEnabled === 'yes' ? newSettings.followBackLimit : -1,
            followFansActive: newSettings.followFansActive,
            followBackFilter,
        };

        const updateSettings = async () => {
            setShowBackdrop(true);

            await fetch(query, {
                method: 'put',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postSettings),
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .then(data => {
                    if (data) {
                        const newSubscriber = { ...subscriber };

                        newSubscriber.followBack = data;

                        setSubscriber(newSubscriber);

                        dialog.alert('Follow-Back settings updated successfully!');
                    } else {
                        dialog.alert(`Settings not upated!`);
                    }
                })
                .catch(error => {
                    handleHttpError(error, dialog);
                });

            setShowBackdrop(false);
        };

        updateSettings();
    };

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton
                            {...(subscriber && { url: `/subscribers/${subscriber._id}/${settingsContext.services.followBack.homeUrl}` })}
                        />{' '}
                        Follow-Back Settings for {subscriber.username}
                    </Typography>

                    <Alert severity="warning" sx={{ marginBottom: theme.spacing(2) }}>
                        Changing these settings will directly affect the system's performance on this account! Please change these settings
                        with consideration. If in doubt, please consult Admin.
                        <br />
                        <br />
                        All changes will be applied from the next run of this service.
                    </Alert>

                    {subscriber.followBack ? (
                        <>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                                    <CardContent>
                                        <Grid container spacing={2} alignItems="baseline">
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">
                                                    <strong>Folow-Back Service</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Alert severity="warning">WARNING! Turning the service off will pause the service!</Alert>
                                                <br />
                                                <FormCheckBox
                                                    name="active"
                                                    label="Running"
                                                    value={subscriber.followBack.active}
                                                    control={control}
                                                    errors={formState.errors}
                                                    required={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                                    <CardContent>
                                        <Grid container spacing={2} alignItems="baseline">
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">Follow-Back Limit</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <RadioGroup
                                                    defaultValue={followBackLimitEnabled}
                                                    value={followBackLimitEnabled}
                                                    name="followBackLimitEnabled"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFollowBackLimitEnabled(e.target.value);
                                                    }}
                                                >
                                                    <Alert severity="info">
                                                        If you need to follow some specific users manually, you can set a hard limit for the
                                                        Follow-Back service so the remaining follows are left for you to do manually. Please
                                                        bear in mind the OnlyFans daily follow limit.
                                                    </Alert>
                                                    <br />
                                                    <Grid container flexGrow={0} spacing={1} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <FormControlLabel value="no" control={<Radio />} label="No Hard Limit" />
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <FormControlLabel value="yes" control={<Radio />} label="Hard Limit" />
                                                        </Grid>
                                                        <Grid item xs={12} md={8}>
                                                            <FormTextField
                                                                name="followBackLimit"
                                                                label="Follow-Back Limit"
                                                                control={control}
                                                                errors={formState.errors}
                                                                required={true}
                                                                disabled={followBackLimitEnabled === 'no'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                                    <CardContent>
                                        <Grid container spacing={2} alignItems="baseline">
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">Follow Current Fans</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Alert severity="info">
                                                    This feature is designed to compensate for the fact that OnlyFans' own Auto Follow
                                                    featured needs to be turned off so that this Follow-Back service has enough follows
                                                    available to follow the expired fans.
                                                </Alert>
                                                <br />
                                                <FormCheckBox
                                                    name="followFansActive"
                                                    label="Follow Current Fans (only starts once all expired fans are followed)"
                                                    value={subscriber.followBack.followFansActive}
                                                    control={control}
                                                    errors={formState.errors}
                                                    required={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                                    <CardContent>
                                        <Grid container spacing={2} alignItems="baseline">
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body1">Follow Filter</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={8}>
                                                <Alert severity="info">
                                                    You can choose to follow certain expired fans before others, or ignore some expired fans
                                                    altogether, by specifying the appropriate mode and filter parameters.
                                                    <br />
                                                    <br />
                                                    Select <strong>None</strong> to follow all your expired fans in no partcular order.
                                                    <br />
                                                    <br />
                                                    Select <strong>Priority</strong> to follow some expired fans before others. Tick the
                                                    filter you'd like to apply when searching for this priority group of expired fans, and
                                                    enter the approprivate value in the search box. For example, to follow all the expired
                                                    spenders first, only tick the <strong>Total Spent Minimum</strong> and enter{' '}
                                                    <strong>1</strong> in the amount box. This will tell the system to look for anyone who
                                                    spent at least $1 first, and only when those expired fans have been followed, to look
                                                    for the non-spenders.
                                                    <br />
                                                    <br />
                                                    Select <strong>Strict</strong> to only follow the expired fans that match the specified
                                                    search. Once all these have been followed, the system will not try to follow any other
                                                    expired fans.
                                                </Alert>
                                                <br />
                                                <RadioGroup
                                                    defaultValue={followBackFilterType}
                                                    value={followBackFilterType}
                                                    name="followBackFilterType"
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (e.target.value === 'none') {
                                                            // setValue('followBackFilter', {});
                                                        }

                                                        setFollowBackFilterType(e.target.value);
                                                    }}
                                                >
                                                    <Grid container flexGrow={0} spacing={1} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                value="none"
                                                                control={<Radio />}
                                                                label="None - Follow everybody"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                value="priority"
                                                                control={<Radio />}
                                                                label="Priority - Follow fans that match this search first, and then the rest..."
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                flexGrow={0}
                                                                spacing={1}
                                                                alignItems="center"
                                                                sx={{ marginLeft: theme.spacing(1) }}
                                                            >
                                                                <Grid item xs={12} md={4}>
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name="filterTotalSpentEnabled"
                                                                                    checked={filterTotalSpentEnabled}
                                                                                    size="small"
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                        setFilterTotalSpentEnable(e.target.checked);
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label="Total Spent Minimum"
                                                                            disabled={followBackFilterType !== 'priority'}
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <FormTextField
                                                                        name="total_spent"
                                                                        label="Total Spent"
                                                                        control={control}
                                                                        errors={formState.errors}
                                                                        required={true}
                                                                        disabled={
                                                                            followBackFilterType !== 'priority' || !filterTotalSpentEnabled
                                                                        }
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">$</InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name="filterTipsEnabled"
                                                                                    checked={filterTipsEnabled}
                                                                                    size="small"
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                        setFilterTipsEnable(e.target.checked);
                                                                                    }}
                                                                                />
                                                                            }
                                                                            label="Tips Minimum"
                                                                            disabled={followBackFilterType !== 'priority'}
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <FormTextField
                                                                        name="tips"
                                                                        label="Tips"
                                                                        control={control}
                                                                        errors={formState.errors}
                                                                        required={true}
                                                                        disabled={followBackFilterType !== 'priority' || !filterTipsEnabled}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">$</InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControlLabel
                                                                value="strict"
                                                                control={<Radio />}
                                                                label="Strict - Only follow fans that match this search"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid
                                                                container
                                                                flexGrow={0}
                                                                spacing={1}
                                                                alignItems="center"
                                                                sx={{ marginLeft: theme.spacing(1) }}
                                                            >
                                                                <Grid item xs={12} md={4}>
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name="filterThresholdMinTotalSpentEnabled"
                                                                                    checked={filterThresholdMinTotalSpentEnabled}
                                                                                    size="small"
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                        setFilterThresholdMinTotalSpentEnable(
                                                                                            e.target.checked,
                                                                                        );
                                                                                    }}
                                                                                    disabled={followBackFilterType !== 'strict'}
                                                                                />
                                                                            }
                                                                            label="Total Spent Minimum"
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <FormTextField
                                                                        name="thresholdMinTotalSpent"
                                                                        label="Total Spent"
                                                                        control={control}
                                                                        errors={formState.errors}
                                                                        required={true}
                                                                        disabled={
                                                                            followBackFilterType !== 'strict' ||
                                                                            !filterThresholdMinTotalSpentEnabled
                                                                        }
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">$</InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <FormGroup>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    name="filterTipsEnabled"
                                                                                    checked={filterThresholdMonthsEnabled}
                                                                                    size="small"
                                                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                                        setFilterThresholdMonthsEnable(e.target.checked);
                                                                                    }}
                                                                                    disabled={followBackFilterType !== 'strict'}
                                                                                />
                                                                            }
                                                                            label="Minimum Months Subscribed"
                                                                        />
                                                                    </FormGroup>
                                                                </Grid>
                                                                <Grid item xs={12} md={8}>
                                                                    <FormTextField
                                                                        name="thresholdMonths"
                                                                        label="Months"
                                                                        control={control}
                                                                        errors={formState.errors}
                                                                        required={true}
                                                                        disabled={
                                                                            followBackFilterType !== 'strict' ||
                                                                            !filterThresholdMonthsEnabled
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                    justifyItems={'center'}
                                    sx={{ marginBottom: theme.spacing(2) }}
                                >
                                    <Grid item xs={12} md={6}>
                                        <Box textAlign="center">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                size="large"
                                                sx={{ width: { xs: '100%', md: '50%' } }}
                                            >
                                                Update
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} alignSelf="center">
                                        <Box textAlign="center">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="large"
                                                component={Link}
                                                to={`/subscribers/${subscriber._id}/${settingsContext.services.followBack.homeUrl}`}
                                                startIcon={<NavigateBeforeIcon />}
                                                sx={{ width: { xs: '100%', md: '50%' } }}
                                            >
                                                Back to Dashboard
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    ) : (
                        <>
                            <Alert severity="warning" sx={{ marginBottom: theme.spacing(2) }}>
                                You do not have Follow-Back setup on this account. Please contact Admin for more information.
                            </Alert>
                        </>
                    )}
                </>
            )}

            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
};

export default FollowBackSettings;
