import { Card, Grid, Skeleton, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { Dinero } from 'dinero.js';

const earningsGoalCellBackgroundStyle = (theme: Theme, percent: number) => {
    if (percent > 100) {
        percent = 100;
    }

    if (percent === 0) {
        return 'inherit';
    }

    const color = percent === 100 ? theme.palette.success.light : theme.palette.warning.light;

    return `-webkit-linear-gradient(left, ${color} 0%, ${color} ${percent}%, white ${percent}%); -moz-linear-gradient(left, ${color} 0%, ${color} ${percent}%, white ${percent}%); -ms-linear-gradient(left, ${color} 0%, ${color} ${percent}%, white ${percent}%); linear-gradient(left, ${color} 0%, ${color} ${percent}%, white ${percent}%);`;
};

type Props = {
    title: string | React.ReactNode;
    value: Dinero;
    loading: boolean;
    goal?: Dinero;
    icon?: React.ReactNode;
    theme: Theme;
};

const PriceCardWithGoal = (props: Props) => {
    const { title, value, goal, loading, icon, theme } = props;
    let percent: number = value.toUnit() !== 0 && goal ? (value.toUnit() / goal.toUnit()) * 100 : 0;

    if (percent > 100) {
        percent = 100;
    }

    return (
        <Card
            variant="elevation"
            sx={{
                marginBottom: theme.spacing(0),
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '16px',
                ...(goal && {
                    background: earningsGoalCellBackgroundStyle(theme, percent),
                }),
            }}
        >
            <Grid container flexGrow={1} alignItems="center" spacing={2}>
                {icon && (
                    <Grid item xs={'auto'}>
                        {icon}
                    </Grid>
                )}
                <Grid item xs>
                    <Grid container flexGrow={1} alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" align="right">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{ textAlign: 'right' }}>
                                <Typography variant="h5" display="inline" align="right" fontFamily="monospace">
                                    {loading ? <Skeleton /> : value.toFormat()}
                                </Typography>
                                {goal && (
                                    <Typography variant="body2" display="inline" align="right" fontFamily="monospace">
                                        {' '}
                                        of {goal.toFormat()}
                                    </Typography>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default PriceCardWithGoal;
