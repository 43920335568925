import { Button, Grid, Stack, TextField } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { OnlyFansLoginResponse } from '../../hooks/useSubscriberRelogin';

type Props = {
    otpState: OnlyFansLoginResponse.OtpState;
    selectedOtpOption: string;
    otpCode: string | null;
    setOtpCode: (otpCode: string) => void;
    handleRequestOtpCode: (otpOption: string) => Promise<void>;
    loading: boolean;
};

const OnlyFansOtpCode = (props: Props) => {
    const { otpState, selectedOtpOption, otpCode, setOtpCode, handleRequestOtpCode, loading } = props;
    const [expiresIn, setExpiresIn] = useState<number>(-1);
    const [attemptsMade, setAttemptsMade] = useState<number>(0);
    const [attemptsLeft, setAttemptsLeft] = useState<number>(-1);

    useEffect(() => {
        let t: NodeJS.Timeout | null = null;

        if (!otpState || !otpState.phoneCode || !otpState.phoneCode.expirationDate) {
            setExpiresIn(0);

            return;
        }

        if (
            otpState &&
            otpState.phone &&
            otpState.phoneCode &&
            otpState.phoneCode.expirationDate &&
            moment(otpState.phoneCode.expirationDate).diff(moment(), 'seconds') <= 0
        ) {
            setExpiresIn(0);
        } else {
            // update the expiration date every second
            setExpiresIn(moment(otpState.phoneCode?.expirationDate).diff(moment(), 'seconds'));

            t = setTimeout(() => {
                setExpiresIn(moment(otpState.phoneCode?.expirationDate).diff(moment(), 'seconds'));
            }, 1000);

            if (expiresIn <= 0 && t !== null) {
                clearTimeout(t);
            }
        }

        return () => {
            if (t !== null) {
                clearTimeout(t);
            }
        };
    }, [expiresIn, otpState, otpState.phone, otpState.phoneCode]);

    useEffect(() => {
        if (selectedOtpOption === 'phoneOtp' && otpState.phoneCode) {
            setAttemptsLeft(otpState.phoneCode.requestAttemptsLeft);
        } else if (selectedOtpOption === 'email' && otpState.emailCode) {
            setAttemptsLeft(otpState.emailCode.requestAttemptsLeft);
        } else {
            setAttemptsLeft(3);
        }

        if (otpState.phoneCode) {
            setAttemptsMade(otpState.phoneCode.requestAttemptsLimit - otpState.phoneCode.requestAttemptsLeft);
        } else if (otpState.emailCode) {
            setAttemptsMade(otpState.emailCode.requestAttemptsLimit - otpState.emailCode.requestAttemptsLeft);
        } else {
            setAttemptsMade(0);
        }
    }, [otpState, selectedOtpOption]);

    // let phoneOtpRequestLeft: number = 3;
    // const emailOtpRequestLeft: number = 3;

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
            <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} alignItems="center">
                    <TextField
                        id="otpCode"
                        type={'text'}
                        label="Code"
                        required
                        value={otpCode || ''}
                        disabled={
                            loading ||
                            (selectedOtpOption === 'phoneOtp' && attemptsLeft === 0) ||
                            (selectedOtpOption === 'email' && attemptsLeft === 0)
                        }
                        onChange={event => setOtpCode(event.target.value)}
                        fullWidth
                        autoFocus
                        autoComplete="new-password"
                    />
                    {(selectedOtpOption === 'phoneOtp' || selectedOtpOption === 'email') && (
                        <Button
                            variant={attemptsMade === 0 ? 'contained' : 'outlined'}
                            color="secondary"
                            size="medium"
                            disabled={loading || otpState.phoneCode?.requestAttemptsLeft === 0}
                            onClick={() => {
                                handleRequestOtpCode(selectedOtpOption);
                            }}
                            sx={{
                                // no wrap
                                whiteSpace: 'nowrap',
                                height: '38px',
                            }}
                        >
                            {attemptsMade === 0 ? 'Send' : 'Resend'}
                        </Button>
                    )}
                </Stack>
            </Grid>
            {(selectedOtpOption === 'phoneOtp' || selectedOtpOption === 'email') && (
                <Grid item xs={12}>
                    <Grid container justifyContent={'center'}>
                        <Grid item>
                            {selectedOtpOption === 'phoneOtp' && (
                                <small>
                                    Attempt{' '}
                                    {(otpState.phoneCode?.requestAttemptsLimit || 0) - (otpState.phoneCode?.requestAttemptsLeft || 0)}/
                                    {otpState?.phoneCode?.requestAttemptsLimit || 0} -{' '}
                                    {otpState.phoneCode && otpState.phoneCode.expirationDate && expiresIn > 0
                                        ? `${expiresIn} seconds left`
                                        : 'code expired'}
                                </small>
                            )}
                            {selectedOtpOption === 'email' && (
                                <small>
                                    Email code requested (
                                    {(otpState.emailCode?.requestAttemptsLimit || 0) - (otpState.emailCode?.requestAttemptsLeft || 0)}/
                                    {otpState?.emailCode?.requestAttemptsLimit || 0})
                                </small>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default OnlyFansOtpCode;
