import moment from 'moment';
import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Container, Typography, Alert } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { UserContext } from '../../../store/UserContext';
import { SettingsContext } from '../../../store/SettingsContext';
import { useParams } from 'react-router-dom';
import { useDialog } from 'muibox';
import { handleHttpError, handleHttpErrorResponse } from '../../../utils/common';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import FollowBackReportSearchBar from '../../../components/services/followBack/report/FollowBackReportSearchBar';
import FollowBackReportFollowingGraph from '../../../components/services/followBack/report/FollowBackReportFollowingGraph';
import FollowBackReportSessionsList from '../../../components/services/followBack/report/FollowBackReportSessionsList';

const FollowBackReport = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const navigate: NavigateFunction = useNavigate();
    const dialog = useDialog();
    const params = useParams();
    const isMounted = useRef(true);

    const [subscriber, setSubscriber] = useState<any | null>(null);
    const [subscriberLoading, setSubscriberLoading] = useState<boolean>(false);

    // Search Bar
    const [searchTimezone, setSearchTimezone] = useState<string>(moment.tz.guess());
    const [searchMainFreeRange, setSearchMainFreeRange] = useState<boolean>(false);
    const [searchMainWeek, setSearchMainWeek] = useState<number>(moment().week());
    const [searchMainDateFrom, setSearchMainDateFrom] = useState<Date>(moment().startOf('week').toDate());
    const [searchMainDateTo, setSearchMainDateTo] = useState<Date>(moment().endOf('week').toDate());

    // Report
    const [isFetchingReport, setIsFetchingReport] = useState<boolean>(false);
    const [reportTimezone, setReportTimezone] = useState<string>(moment.tz.guess());
    const [reportMainIsFreeRange, setReportMainIsFreeRange] = useState<boolean>(false);
    const [reportMainDateFrom, setReportMainDateFrom] = useState<Date | null>(null);
    const [reportMainDateTo, setReportMainDateTo] = useState<Date | null>(null);

    // Result
    const [mainData, setMainData] = useState<any[]>([]);

    useEffect(() => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            const fetchSubscriber = async () => {
                const query: string = `${settingsContext.routes.subscribers.find}${params.userId}`;

                setSubscriberLoading(true);

                await fetch(query, {
                    method: 'get',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .then(data => {
                        setSubscriber(data);

                        if (!data || !('_id' in data) || !data._id) {
                            navigate('/');
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        handleHttpError(error, dialog);
                    });

                setSubscriberLoading(false);
            };

            fetchSubscriber();
        }
    }, [userContext, settingsContext, params, dialog, navigate]);

    // Fetch Report
    // Retrieve the sales report from the server
    const requestReport = useCallback(async () => {
        // Fetch Sales By Fraction Report
        const fetchFollowBackReport = async (startDate: Date, endDate: Date, timezone: string): Promise<any[]> => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.followBack.base}${params.userId}/report?${new URLSearchParams({
                    startDate: moment(startDate).tz(timezone, true).toISOString(),
                    endDate: moment(endDate).tz(timezone, true).toISOString(),
                })}`;

                return fetch(query, {
                    method: 'get',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .then(data => {
                        return data;
                    })
                    .catch(error => {
                        handleHttpError(error, dialog);
                    });
            }

            return [];
        };

        const fetchAllReports = async () => {
            setIsFetchingReport(true);

            // Update report date range
            setReportTimezone(searchTimezone);
            setReportMainIsFreeRange(searchMainFreeRange);
            setReportMainDateFrom(searchMainDateFrom);
            setReportMainDateTo(searchMainDateTo);

            // Fetch Follow-BackReport
            const result = await fetchFollowBackReport(searchMainDateFrom, searchMainDateTo, searchTimezone);

            setMainData(result);

            setIsFetchingReport(false);
        };

        if (isFetchingReport) {
            return;
        }

        fetchAllReports();

        // only update if we are still mounted
        if (isMounted.current) {
        }
    }, [
        isFetchingReport,
        userContext.jwtToken,
        settingsContext.apiKey,
        settingsContext.routes.followBack.base,
        params,
        subscriber,
        dialog,
        searchTimezone,
        searchMainFreeRange,
        searchMainDateFrom,
        searchMainDateTo,
    ]);

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton
                            {...(subscriber && { url: `/subscribers/${subscriber._id}/${settingsContext.services.followBack.homeUrl}` })}
                        />{' '}
                        Follow-Back Report for {subscriber.username}
                    </Typography>

                    {subscriber.followBack ? (
                        <>
                            <FollowBackReportSearchBar
                                timezone={searchTimezone}
                                setTimezone={setSearchTimezone}
                                mainSearch={{
                                    freeRange: searchMainFreeRange,
                                    setFreeRange: setSearchMainFreeRange,
                                    week: searchMainWeek,
                                    setWeek: setSearchMainWeek,
                                    dateFrom: searchMainDateFrom,
                                    setDateFrom: setSearchMainDateFrom,
                                    dateTo: searchMainDateTo,
                                    setDateTo: setSearchMainDateTo,
                                }}
                                isFetchingReport={isFetchingReport || subscriberLoading}
                                requestReport={requestReport}
                            />

                            <Typography variant="subtitle1" sx={{ paddingBottom: theme.spacing(2), textAlign: 'center' }}>
                                {reportMainIsFreeRange || !reportMainDateFrom
                                    ? ''
                                    : `Results for Week ${moment(reportMainDateFrom).week()} `}
                                {reportMainDateFrom && reportMainDateTo ? (
                                    <>
                                        <br />
                                        {reportMainDateFrom.toLocaleDateString()} - {reportMainDateTo.toLocaleDateString()}
                                    </>
                                ) : (
                                    'No Dates Selected'
                                )}
                                {reportMainDateFrom && ` (${reportTimezone})`}
                            </Typography>

                            <FollowBackReportFollowingGraph mainData={mainData} isFetching={isFetchingReport} />

                            <FollowBackReportSessionsList data={mainData} isFetching={isFetchingReport} timezone={reportTimezone} />
                        </>
                    ) : (
                        <>
                            <Alert severity="warning" sx={{ marginBottom: theme.spacing(2) }}>
                                You do not have Follow-Back setup on this account. Please contact Admin for more information.
                            </Alert>
                        </>
                    )}
                </>
            )}
        </Container>
    );
};

export default FollowBackReport;
