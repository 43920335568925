import { useEffect } from 'react';
import { Theme } from '@mui/system';
import { Card, CardContent, Grid, Slider, Typography, useMediaQuery } from '@mui/material';

const delayOptions = (isLargeScreen: boolean) => {
    return [
        {
            value: 0,
            label: `0s`,
        },
        {
            value: 1000,
            label: `1s`,
        },
        {
            value: 2000,
            label: `2s`,
        },
        {
            value: 3000,
            label: `3s`,
        },
        {
            value: 4000,
            label: `4s`,
        },
        {
            value: 5000,
            label: `5s`,
        },
        {
            value: 6000,
            label: `6s`,
        },
        {
            value: 7000,
            label: `7s`,
        },
        {
            value: 8000,
            label: `8s`,
        },
        {
            value: 9000,
            label: `9s`,
        },
        {
            value: 10000,
            label: `10s`,
        },
    ];
};

type Props = {
    delayBetweenMessages: number;
    setDelayBetweenMessages: (delayBetweenMessages: number) => void;
    session?: any;
    theme: Theme;
};

const MessageUsersDelayBetweenMessages = (props: Props) => {
    const { delayBetweenMessages, setDelayBetweenMessages, session, theme } = props;
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        if (session && session.payload && session.payload.delayBetweenMessages) {
            setDelayBetweenMessages(session.payload.delayBetweenMessages);
        }
    }, [session, setDelayBetweenMessages]);

    const sliderColor =
        delayBetweenMessages === 0
            ? theme.palette.error.main
            : delayBetweenMessages === 1000 || delayBetweenMessages === 2000
            ? theme.palette.warning.dark
            : theme.palette.success.dark;

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
            <Grid item xs={12}>
                <Card variant="elevation">
                    <CardContent>
                        <Grid container spacing={1} flexGrow={0} alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="h6">Pause Between Messages</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} flexGrow={0} alignItems="center">
                                    <Grid item xs={12}>
                                        In their usual wisdom, OnlyFans have introduced a rate limit on the messages you can send in chats
                                        (manually or automatically). Sending too many messages too quickly will force you to wait 5 seconds
                                        before sending another message.
                                        <br />
                                        <br />
                                        This settings allows you to control how long the system will wait between every message it sends.
                                        <br />
                                        <br />
                                        Smaller accounts or when sending messages to a smaller batch of users can have a shorter delay which
                                        will send this batch of messages quicker. But larger accounts or larger sets of target users, should
                                        have a longer delay. This system can handle rate limit issues automatically, so this is only done so
                                        that you and your sexters/chatters don't experinece a rate limit error in your/their own browsers
                                        when chatting with fans.
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{ textAlign: 'center' }}>
                                            <Slider
                                                getAriaValueText={value => `${value / 1000}s`}
                                                value={delayBetweenMessages}
                                                step={1000}
                                                min={0}
                                                max={10000}
                                                size="medium"
                                                valueLabelDisplay="off"
                                                marks={delayOptions(isLargeScreen)}
                                                sx={{
                                                    color: sliderColor,

                                                    '& .MuiSlider-rail': {
                                                        opacity: 0.5,
                                                        backgroundColor: sliderColor,
                                                    },
                                                    width: '80%',
                                                }}
                                                onChange={(event, newValue) => {
                                                    setDelayBetweenMessages(newValue as number);
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="caption" textAlign={'center'} color={sliderColor}>
                                            <div style={{ textAlign: 'center' }}>
                                                {delayBetweenMessages === 0 &&
                                                    'WARNING: Setting this to 0 will send messages as fast as possible. This could cause rate limit errors.'}
                                                {(delayBetweenMessages === 1000 || delayBetweenMessages === 2000) &&
                                                    'May cause rate limit errors if the message is sending to a large batch of users.'}
                                            </div>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MessageUsersDelayBetweenMessages;
