import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { Alert, Card, CardContent, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { LocalizationProvider, MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import SelectTimezone from '../../../forms/helpers/SelectTimezone';

function getDateFormatString(locale: string) {
    const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    };

    const formatObj = new Intl.DateTimeFormat(locale, options).formatToParts(Date.now());

    return formatObj
        .map(obj => {
            switch (obj.type) {
                case 'hour':
                    return '';
                case 'minute':
                    return '';
                case 'second':
                    return '';
                case 'day':
                    return 'DD';
                case 'month':
                    return 'MM';
                case 'year':
                    return 'YYYY';
                case 'literal':
                    if (obj.value === '/') {
                        return '/';
                    } else {
                        return '';
                    }
                default:
                    return '';
            }
        })
        .join('');
}

type Props = {
    isSessionOrJobRunning: boolean;
    scheduleMessage: boolean;
    setScheduleMessage: (scheduleMessage: boolean) => void;
    dateTime: Date | null;
    setDateTime: (date: Date | null) => void;
    session?: any;
    theme: Theme;
};

const MessageUsersSchedule = (props: Props) => {
    const { isSessionOrJobRunning, scheduleMessage, setScheduleMessage, dateTime, setDateTime, theme, session } = props;
    const [timezone, setTimezone] = useState<string>(moment.tz.guess());
    const [date, setDate] = useState<moment.Moment>(dateTime ? moment(dateTime) : moment());
    const [time, setTime] = useState<moment.Moment>(dateTime ? moment(dateTime) : moment());

    useEffect(() => {
        if (session && session.scheduleMessageDate) {
            const sessionDate: moment.Moment = moment(session.scheduleMessageDate);
            const sessionDateTimezone: string | undefined = sessionDate.tz();

            setScheduleMessage(true);
            setDateTime(sessionDate.toDate());
            sessionDateTimezone && setTimezone(sessionDateTimezone);
        }
    }, [session, setDateTime, setScheduleMessage]);

    useEffect(() => {
        if (isSessionOrJobRunning) {
            setScheduleMessage(true);
        }
    }, [isSessionOrJobRunning, setScheduleMessage]);

    useEffect(() => {
        const handleDateTimeChanged = (newDate: moment.Moment, newTime: moment.Moment) => {
            const localDateTime = moment.tz(`${newDate.format('YYYY-MM-DD')} ${newTime.format('HH:mm')}`, timezone).local();

            setDateTime(localDateTime.toDate());
        };

        handleDateTimeChanged(date, time);
    }, [date, setDateTime, time, timezone]);

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
            <Grid item xs={12}>
                <Card variant="elevation">
                    <CardContent>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Grid container spacing={1} alignItems="center" sx={{ marginBottom: theme.spacing(1) }}>
                                <Grid item xs="auto">
                                    <ScheduleSendIcon color="secondary" />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="h6">Schedule Message</Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <RadioGroup
                                        value={scheduleMessage ? 'later' : 'now'}
                                        onChange={e => {
                                            setScheduleMessage(e.currentTarget.value === 'later');
                                        }}
                                        sx={{ paddingBottom: theme.spacing(1) }}
                                    >
                                        <FormControlLabel
                                            value="now"
                                            control={<Radio size="medium" disabled={isSessionOrJobRunning} />}
                                            label="Send now!"
                                        />
                                        <FormControlLabel value="later" control={<Radio size="medium" />} label="Later..." />
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                            {scheduleMessage && (
                                <Grid container alignItems="center" spacing={1} sx={{ paddingLeft: theme.spacing(4) }}>
                                    <Grid item xs={12}>
                                        Timezone
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectTimezone timezone={timezone} setTimezone={setTimezone} fullWidth={false} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        Date
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MobileDatePicker
                                            renderInput={props => (
                                                <TextField {...props} helperText={getDateFormatString(Intl.Locale.name)} />
                                            )}
                                            label="Date"
                                            value={moment(date)}
                                            onChange={newValue => {
                                                setDate(newValue as moment.Moment);
                                                // handleDateTimeChanged(newValue as moment.Moment, time);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        Time
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MobileTimePicker
                                            label="Time"
                                            ampm={true}
                                            value={moment(time)}
                                            onChange={newValue => {
                                                setTime(newValue as moment.Moment);
                                                // handleDateTimeChanged(date, newValue as moment.Moment);
                                            }}
                                            renderInput={params => <TextField {...params} />}
                                            DialogProps={{
                                                sx: {
                                                    '.MuiPickersToolbar-content .Mui-selected': {
                                                        color: theme.palette.secondary.main,
                                                    },
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Alert variant="filled" severity="info">
                                            A scheduled message won't start sending until the previous session had finished
                                        </Alert>
                                    </Grid>
                                </Grid>
                            )}
                        </LocalizationProvider>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MessageUsersSchedule;
