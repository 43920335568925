import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useInfiniteQuery } from 'react-query';
import { VaultMediaResponse } from '../components/services/messageUsers/sendMessage/MessageUsersFormAddMediasSelector';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useSubscriber from './useSubscriber';

const useSubscriberVaultMedias = (service: string, albumId: number, enabled: boolean = true) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const dialog = useDialog();

    const fetchVaultMedias = async (albumId: number, offset: number): Promise<VaultMediaResponse> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/vaultMedias?${new URLSearchParams({
            offset: offset.toString(),
            ...(albumId && { albumId: albumId.toString() }),
        })}`;

        return axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return { list: [], hasMore: false, nextOffset: null };
            });
    };

    return useInfiniteQuery(['vaultMedias', service, albumId, subscriber], params => fetchVaultMedias(albumId, params.pageParam || 0), {
        getNextPageParam: lastPage => lastPage?.nextOffset,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        enabled: subscriber && userContext.jwtToken && settingsContext.apiKey && enabled,
    });
};

export default useSubscriberVaultMedias;
