import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    Chip,
    CircularProgress,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError, handleHttpErrorResponse } from '../../../../utils/common';
import MessageUsersTagCreatorsList from './MessageUsersTagCreatorsList';
import MessageUsersTagReleaseFormsList from './MessageUsersTagReleaseFormsList';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0, pt: 1, bgcolor: 'background.paper' }}>{children}</Box>}
        </div>
    );
};

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

type Props = {
    subscriber: any;
    tagCreators: boolean;
    setTagCreators: (tagCreators: boolean) => void;
    taggedCreators: { id: number; name: string }[];
    setTaggedCreators: (taggedCreators: { id: number; name: string }[]) => void;
    taggedReleaseForms: { id: number; name: string }[];
    setTaggedReleaseForms: (taggedReleaseForms: { id: number; name: string }[]) => void;
    session?: any;
    theme: Theme;
    disabled?: boolean;
};

const MessageUsersTagCreators = (props: Props) => {
    const {
        subscriber,
        tagCreators,
        setTagCreators,
        taggedCreators,
        setTaggedCreators,
        taggedReleaseForms,
        setTaggedReleaseForms,
        session,
        theme,
        disabled,
    } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();

    const [tabValue, setTabValue] = useState<number>(0);

    const [sortTaggedFriends, setSortTaggedFriends] = useState<boolean>(false);
    const [sortedTaggedFriends, setSortedTaggedFriends] = useState<any[]>([]);

    const [sortReleaseForms, setSortReleaseForms] = useState<boolean>(false);
    const [sortedReleaseForms, setSortedReleaseForms] = useState<any[]>([]);

    const taggedCreatorsCount = taggedCreators ? taggedCreators.length : 0;
    const taggedReleaseFormsCount = taggedReleaseForms ? taggedReleaseForms.length : 0;
    const totalTagged = taggedCreatorsCount + taggedReleaseFormsCount;

    useEffect(() => {
        if (session && session.payload && (session.payload.taggedCreators || session.payload.taggedReleaseForms)) {
            setTagCreators(session.payload.tagCreators);
        }

        if (session && session.payload && session.payload.taggedCreators) {
            setTaggedCreators(session.payload.taggedCreators);
        }

        if (session && session.payload && session.payload.taggedReleaseForms) {
            setTaggedReleaseForms(session.payload.taggedReleaseForms);
        }
    }, [session, setTagCreators, setTaggedCreators, setTaggedReleaseForms]);

    // Fetch all tagged friends
    const fetchTaggedFriends = async (): Promise<any> => {
        if (subscriber && userContext.jwtToken && settingsContext.apiKey && tagCreators) {
            const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/taggedFriends`;

            return fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const {
        data: taggedFriends,
        isLoading: taggedFriendsLoading,
        isFetching: taggedFriendsFetching,
        refetch: refetchTaggedFriends,
    } = useQuery(
        ['taggedFriends', subscriber],
        () => {
            if (subscriber) {
                return fetchTaggedFriends();
            }
        },
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: subscriber && tagCreators ? true : false,
        },
    );

    // Fetch Subscriber's Lists
    const fetchReleaseForms = async (): Promise<any> => {
        if (subscriber && userContext.jwtToken && settingsContext.apiKey && tagCreators) {
            const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/releaseForms`;

            return fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const {
        data: releaseForms,
        isLoading: releaseFormsLoading,
        isFetching: releaseFromsFetching,
        refetch: refetchReleaseForms,
    } = useQuery(
        ['releaseForms', subscriber],
        () => {
            if (subscriber) {
                return fetchReleaseForms();
            }
        },
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: subscriber && tagCreators ? true : false,
        },
    );

    useEffect(() => {
        if (taggedFriends) {
            if (sortTaggedFriends) {
                const copy = [...taggedFriends];
                const sortedData = copy.sort((a: any, b: any) => {
                    let x = a.name.toLowerCase();
                    let y = b.name.toLowerCase();

                    if (x < y) {
                        return -1;
                    }

                    if (x > y) {
                        return 1;
                    }

                    return 0;
                });

                setSortedTaggedFriends(sortedData);
            } else {
                setSortedTaggedFriends(taggedFriends);
            }
        }
    }, [taggedFriends, sortTaggedFriends]);

    useEffect(() => {
        if (releaseForms) {
            if (sortReleaseForms) {
                const copy = [...releaseForms];
                const sortedData = copy
                    .filter(item => item.type === 'document')
                    .sort((a: any, b: any) => {
                        let x = a.name.toLowerCase();
                        let y = b.name.toLowerCase();

                        if (x < y) {
                            return -1;
                        }

                        if (x > y) {
                            return 1;
                        }

                        return 0;
                    });

                setSortedReleaseForms(sortedData);
            } else {
                setSortedReleaseForms(releaseForms.filter((item: any) => item.type === 'document'));
            }
        }
    }, [releaseForms, sortReleaseForms]);

    const handleCreatorListItemClicked = (id: number) => {
        const alreadyChecked = taggedCreators.find(item => item.id === id);

        if (alreadyChecked) {
            const newCheckedLists = taggedCreators.filter(item => item.id !== id);

            setTaggedCreators(newCheckedLists);
        } else if (taggedFriends) {
            const listName: string = taggedFriends.find((item: any) => item.id === id).name;
            setTaggedCreators([...taggedCreators, { id, name: listName }]);
        }
    };

    const handleReleaseFormListItemClicked = (id: number) => {
        const alreadyChecked = taggedReleaseForms.find(item => item.id === id);

        if (alreadyChecked) {
            const newCheckedLists = taggedReleaseForms.filter(item => item.id !== id);

            setTaggedReleaseForms(newCheckedLists);
        } else if (releaseForms) {
            const listName: string = releaseForms.find((item: any) => item.id === id).name;
            setTaggedReleaseForms([...taggedReleaseForms, { id, name: listName }]);
        }
    };

    const handleDeleteTaggedCreator = (id: number) => {
        const newCheckedLists = taggedCreators.filter(item => item.id !== id);

        setTaggedCreators(newCheckedLists);
    };

    const handleDeleteReleaseForm = (id: number) => {
        const newCheckedLists = taggedReleaseForms.filter(item => item.id !== id);

        setTaggedReleaseForms(newCheckedLists);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
            <Grid item xs={12}>
                <Card variant="elevation">
                    <CardContent>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            size="medium"
                                            checked={tagCreators}
                                            onChange={(e, checked) => {
                                                setTagCreators(checked);
                                            }}
                                        />
                                    }
                                    label={<Typography variant="h6">Tag Creators?</Typography>}
                                    disabled={disabled}
                                />
                            </Grid>

                            {tagCreators && (
                                <>
                                    <Grid item xs="auto">
                                        <Chip
                                            color={totalTagged === 0 ? 'error' : 'primary'}
                                            icon={<CheckCircleIcon />}
                                            label={`${totalTagged} selected`}
                                            disabled={disabled}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange}>
                                            <Tab label="Tag Creators" {...a11yProps(0)} />
                                            <Tab label="Tag Release Forms" {...a11yProps(1)} />
                                        </Tabs>

                                        <CustomTabPanel value={tabValue} index={0}>
                                            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                                                {!taggedFriendsLoading && !taggedFriendsFetching && (
                                                    <>
                                                        <Grid item xs>
                                                            <div style={{ color: theme.palette.grey[500] }}>Select friends to tag</div>
                                                        </Grid>
                                                        <Grid item xs={'auto'}>
                                                            <Tooltip title="Sort friends alphabetically or by most recent used on OnlyFans">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setSortTaggedFriends(!sortTaggedFriends);
                                                                    }}
                                                                    color={sortTaggedFriends ? 'primary' : 'secondary'}
                                                                    disabled={disabled}
                                                                >
                                                                    <SortByAlphaIcon fontSize="large" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={'auto'}>
                                                            <Tooltip title="Clear Selection">
                                                                <IconButton
                                                                    color="error"
                                                                    onClick={() => {
                                                                        setTaggedCreators([]);
                                                                    }}
                                                                    disabled={disabled}
                                                                >
                                                                    <ClearIcon fontSize="large" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={'auto'}>
                                                            <Tooltip title="Reload friends from OnlyFans">
                                                                <IconButton
                                                                    color="secondary"
                                                                    onClick={() => {
                                                                        refetchTaggedFriends();
                                                                    }}
                                                                    disabled={disabled}
                                                                >
                                                                    <RefreshIcon fontSize="large" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </>
                                                )}
                                                {taggedFriendsLoading || taggedFriendsFetching ? (
                                                    <Grid item xs>
                                                        <Box textAlign={'center'}>
                                                            <Typography variant="caption">
                                                                Retrieving your Friends...
                                                                <br />
                                                                <br />
                                                                This might take a while depending on how many tagged friends you have and
                                                                OnlyFans servers speed 🤷‍♂️
                                                                <br />
                                                                <br />
                                                                <CircularProgress size={24} />
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12}>
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            flexGrow={1}
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            sx={{ marginBottom: theme.spacing(1) }}
                                                        >
                                                            <Grid item xs={12}>
                                                                <MessageUsersTagCreatorsList
                                                                    sortedTaggedFriends={sortedTaggedFriends}
                                                                    taggedCreators={taggedCreators}
                                                                    handleListItemClicked={handleCreatorListItemClicked}
                                                                    theme={theme}
                                                                    disabled={disabled}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                <Grid item xs={12}>
                                                    <Stack
                                                        direction="row"
                                                        flexWrap="wrap"
                                                        alignItems="center"
                                                        spacing={2}
                                                        rowGap={1}
                                                        justifyContent="flex-start"
                                                    >
                                                        {taggedCreators.map((taggedCreator: any) => (
                                                            <Chip
                                                                key={taggedCreator.id}
                                                                label={taggedCreator.name}
                                                                onDelete={() => {
                                                                    handleDeleteTaggedCreator(taggedCreator.id);
                                                                }}
                                                            />
                                                        ))}
                                                    </Stack>
                                                    {taggedCreators.length === 0 && (
                                                        <Typography variant="caption">No friends selected.</Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </CustomTabPanel>

                                        <CustomTabPanel value={tabValue} index={1}>
                                            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                                                        {!releaseFormsLoading && !releaseFromsFetching && (
                                                            <>
                                                                <Grid item xs>
                                                                    <div style={{ color: theme.palette.grey[500] }}>
                                                                        Select release forms to tag
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={'auto'}>
                                                                    <Tooltip title="Sort release forms alphabetically or by most recent used on OnlyFans">
                                                                        <IconButton
                                                                            onClick={() => {
                                                                                setSortReleaseForms(!sortReleaseForms);
                                                                            }}
                                                                            color={sortReleaseForms ? 'primary' : 'secondary'}
                                                                            disabled={disabled}
                                                                        >
                                                                            <SortByAlphaIcon fontSize="large" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={'auto'}>
                                                                    <Tooltip title="Clear Selection">
                                                                        <IconButton
                                                                            color="error"
                                                                            onClick={() => {
                                                                                setTaggedReleaseForms([]);
                                                                            }}
                                                                            disabled={disabled}
                                                                        >
                                                                            <ClearIcon fontSize="large" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={'auto'}>
                                                                    <Tooltip title="Reload friends from OnlyFans">
                                                                        <IconButton
                                                                            color="secondary"
                                                                            onClick={() => {
                                                                                refetchReleaseForms();
                                                                            }}
                                                                            disabled={disabled}
                                                                        >
                                                                            <RefreshIcon fontSize="large" />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            </>
                                                        )}
                                                        {releaseFormsLoading || releaseFromsFetching ? (
                                                            <Grid item xs>
                                                                <Box textAlign={'center'}>
                                                                    <Typography variant="caption">
                                                                        Retrieving your Release Forms...
                                                                        <br />
                                                                        <br />
                                                                        This might take a while depending on how many release forms you have
                                                                        and OnlyFans servers speed 🤷‍♂️
                                                                        <br />
                                                                        <br />
                                                                        <CircularProgress size={24} />
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        ) : (
                                                            <Grid item xs={12}>
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    flexGrow={1}
                                                                    alignItems="center"
                                                                    justifyContent="center"
                                                                    sx={{ marginBottom: theme.spacing(1) }}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <MessageUsersTagReleaseFormsList
                                                                            sortedReleaseForms={sortedReleaseForms}
                                                                            taggedReleaseForms={taggedReleaseForms}
                                                                            handleListItemClicked={handleReleaseFormListItemClicked}
                                                                            theme={theme}
                                                                            disabled={disabled}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Stack
                                                        direction="row"
                                                        flexWrap="wrap"
                                                        alignItems="center"
                                                        spacing={2}
                                                        rowGap={1}
                                                        justifyContent="flex-start"
                                                    >
                                                        {taggedReleaseForms.map((taggedReleaseForm: any) => (
                                                            <Chip
                                                                key={taggedReleaseForm.id}
                                                                label={taggedReleaseForm.name}
                                                                onDelete={() => {
                                                                    handleDeleteReleaseForm(taggedReleaseForm.id);
                                                                }}
                                                            />
                                                        ))}
                                                    </Stack>
                                                    {taggedReleaseFormsCount === 0 && (
                                                        <Typography variant="caption">No release forms selected.</Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </CustomTabPanel>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default MessageUsersTagCreators;
