import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import {
    Box,
    Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { parse as parseCsv } from 'json2csv';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import useSextforceMetricsCampaignsClaimedTodayOverview, {
    SextforceMetricsCampaignsClaimedTodayOverview,
} from '../../../../../../hooks/useSextforceMetricsCampaignsClaimedToday';
import SextforceMetricsClaimsTodayOverviewStackedBarChartApex from '../../SextforceMetricsClaimsTodayOverviewStackedBarChartApex';

/**
 * Transforms the data rows from the server to a format ready for export to CSV or Excel
 * @param rows Transactions rows
 * @param timezone Timezone string to convert the transaction time to
 * @returns Transformed data for export
 */
const transformTransactionsForExport = (overviewDays: SextforceMetricsCampaignsClaimedTodayOverview[]) => {
    const headers = [
        {
            label: 'Date',
            value: 'date',
        },
        {
            label: 'Name',
            value: 'name',
        },
        {
            label: 'Count',
            value: 'count',
        },
    ];

    const result: { date: string; name: string; count: number }[] = [];

    overviewDays.forEach(row => {
        row.data.forEach(dataItem => {
            result.push({
                date: moment(row.date).format('YYYY-MM-DD'),
                name: dataItem.name,
                count: dataItem.count,
            });
        });
    });

    return { headers, rows: result };
};

type Props = {
    subscriber: any;
    timezone: string;
    hoveredMetricId: string;
    setHoveredMetricId: (metricId: string) => void;
};

const SextforceMetricsCampaignsClaimsTodayOverview = (props: Props) => {
    const { subscriber, timezone, hoveredMetricId, setHoveredMetricId } = props;
    const theme: Theme = useTheme();
    const navigate = useNavigate();

    const [orientation, setOrientation] = useState<'horizontal' | 'vertical'>(
        (localStorage.getItem('sextforceMetricsClaimsTodayOverviewOrientation') as 'horizontal' | 'vertical') || 'horizontal',
    );
    const [overviewDays, setOverviewDays] = useState<number>(
        localStorage.getItem('sextforceMetricsClaimsTodayOverviewOverviewDays')
            ? parseInt(localStorage.getItem('sextforceMetricsClaimsTodayOverviewOverviewDays') as string)
            : 7,
    );

    const { data: claimsTodayOverview, isLoading: claimsTodayOverviewLoading } = useSextforceMetricsCampaignsClaimedTodayOverview(
        subscriber,
        timezone,
        moment().subtract(overviewDays, 'days').startOf('day').toDate(),
        moment().endOf('day').toDate(),
    );

    const calculateHeight = () => {
        if (orientation === 'vertical') {
            return 400;
        } else {
            if (overviewDays === 7) {
                return 400;
            } else if (overviewDays === 14) {
                return 500;
            } else if (overviewDays === 30) {
                return 600;
            }
        }
    };

    // Convert report to CSV format and start file download
    const handleDownloadReportCsv = () => {
        if (!claimsTodayOverview) {
            return;
        }

        const transformedData: any = transformTransactionsForExport(claimsTodayOverview);

        // Format JSON data to CSV
        const csv = parseCsv(transformedData.rows, {
            fields: transformedData.headers,
            transforms: [],
        });

        // Convert CSV to Blob
        const blob: Blob = new Blob([csv], { type: 'text/csv' });

        // Create a descriptive filename
        const filename: string = `campaigns_claimsOverview_${moment()
            .subtract(overviewDays, 'days')
            .format('YYYY-MM-DD')}_${moment().format('YYYY-MM-DD')}.csv`;

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode && link.parentNode.removeChild(link);
    };

    // Convert to Excel format and start file download
    const handleDownloadReportExcel = () => {
        if (!claimsTodayOverview) {
            return;
        }

        const transformedData: any = transformTransactionsForExport(claimsTodayOverview);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [transformedData.headers.map((header: any) => header.label)]);

        XLSX.utils.sheet_add_json(worksheet, transformedData.rows, {
            origin: 'A2',
            skipHeader: true,
            header: transformedData.headers.map((header: any) => header.value),
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

        // Create a descriptive filename
        const filename: string = `campaigns_claimsOverview_${moment()
            .subtract(overviewDays, 'days')
            .format('YYYY-MM-DD')}_${moment().format('YYYY-MM-DD')}.xlsx`;

        XLSX.writeFile(workbook, filename);
    };

    const handleMetricClick = (metricId: string) => {
        navigate(`/subscribers/${subscriber._id}/services/sextforce/metrics/campaigns/details/${metricId}`);
    };

    return (
        <Card sx={{ width: '100%', marginBottom: 1 }}>
            <CardContent>
                <Grid container flexGrow={1} spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h6">Daily Subscribers Overview</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            An overview of the daily subscribers claimed by the campaigns for the past {overviewDays} days. Click on the
                            bars to view more details.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <FormControl fullWidth>
                                    <InputLabel>Overview Days</InputLabel>
                                    <Select
                                        label="Overview Days"
                                        value={overviewDays}
                                        onChange={e => {
                                            setOverviewDays(e.target.value as number);
                                            localStorage.setItem(
                                                'sextforceMetricsClaimsTodayOverviewOverviewDays',
                                                e.target.value as string,
                                            );
                                        }}
                                        displayEmpty
                                    >
                                        <MenuItem value={7}>Last 7 Days</MenuItem>
                                        <MenuItem value={14}>Last 14 Days</MenuItem>
                                        <MenuItem value={30}>Last 30 Days</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs="auto">
                                <Tooltip title="Change Orientation">
                                    <span>
                                        <IconButton
                                            disabled={claimsTodayOverviewLoading || !claimsTodayOverview}
                                            onClick={() => {
                                                setOrientation(orientation === 'horizontal' ? 'vertical' : 'horizontal');
                                            }}
                                            color="primary"
                                        >
                                            <FlipCameraAndroidIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                            <Grid item xs="auto">
                                <Tooltip title="Download as CSV">
                                    <span>
                                        <IconButton
                                            disabled={claimsTodayOverviewLoading || !claimsTodayOverview}
                                            onClick={async () => {
                                                handleDownloadReportCsv();
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFileCsv}
                                                size="2x"
                                                color={claimsTodayOverviewLoading ? theme.palette.text.disabled : theme.palette.info.main}
                                            />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                            <Grid item xs="auto">
                                <Tooltip title="Download as Excel">
                                    <span>
                                        <IconButton
                                            disabled={claimsTodayOverviewLoading || !claimsTodayOverview}
                                            onClick={async () => {
                                                handleDownloadReportExcel();
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFileExcel}
                                                size="2x"
                                                color={claimsTodayOverviewLoading ? theme.palette.text.disabled : theme.palette.info.main}
                                            />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {claimsTodayOverviewLoading && <Skeleton variant="rectangular" height={100} />}
                        {!claimsTodayOverviewLoading && claimsTodayOverview && claimsTodayOverview.length === 0 && (
                            <Box>No data available</Box>
                        )}
                        {!claimsTodayOverviewLoading && claimsTodayOverview && claimsTodayOverview.length > 0 && (
                            <Box height={calculateHeight()} width={'100%'}>
                                <SextforceMetricsClaimsTodayOverviewStackedBarChartApex
                                    data={claimsTodayOverview || []}
                                    orientation={orientation}
                                    hoverMetricId={hoveredMetricId}
                                    setHoveredMetricId={setHoveredMetricId}
                                    handleMetricClick={handleMetricClick}
                                />
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsCampaignsClaimsTodayOverview;
