import AddIcon from '@mui/icons-material/Add';
import {
    Alert,
    Box,
    Button,
    Container,
    Grid,
    PaletteColor,
    Skeleton,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import LargeMaterialUISwitch from '../../../components/common/LargeMaterialUISwitch';
import StyledCard from '../../../components/common/StyledCard';
import StyledCardTitleBar from '../../../components/common/StyledCardTitleBar';
import SextforceAutoCampaignTipReplyNewCampaignForm from '../../../components/services/hostess/autoCampaignTipReply/SextforceAutoCampaignTipReplyNewCampaignForm';
import SextforceAutoCampaignTipReplySearchBar from '../../../components/services/hostess/autoCampaignTipReply/SextforceAutoCampaignTipReplySearchBar';
import SextforceAutoCampaignTipReplyTableRow from '../../../components/services/hostess/autoCampaignTipReply/SextforceAutoCampaignTipReplyTableRow';
import useSextforceAutoCampaignTipReply, { SextforceAutoCampaignTipReplySortBy } from '../../../hooks/useSextforceAutoCampaignTipReply';
import useSubscriber from '../../../hooks/useSubscriber';
import useSubscriberSettingsNotifications, {
    OnlyFansSettingsNotificationsTransport,
} from '../../../hooks/useSubscriberSettingsNotifications';
import { SettingsContext } from '../../../store/SettingsContext';

const SextforceAutoCampaignTipReplyDescription = () => {
    return (
        <>
            <Typography variant="body1" gutterBottom>
                Auto Campaign Tip Reply allows you to automatically send a reply to tips/contributions to a campaign on your page. You can
                choose to send a simple thank you message or even content to the tipper. You can also add the tipper to a list of your
                choosing.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Simply enable the service below, add the campaign you want the system to monitor, and create a reply for each tip option for
                that campaign. The system will automatically send a reply to the tipper when a tip is received.
            </Typography>
            <Typography variant="body1" color="text.secondary">
                Note: If you change the tips options/prices of the campaign on OnlyFans, click on the Edit button of the campaign below to
                refresh the prices and hit save.
            </Typography>
        </>
    );
};

const CounterCard = ({ title, count, color }: { title: string; count: number; color: PaletteColor }) => {
    const theme: Theme = useTheme();

    return (
        <Grid
            container
            spacing={1}
            flexGrow={0}
            alignItems="center"
            sx={{
                backgroundColor: color.main,
                padding: '0.5rem',
                borderRadius: theme.shape.borderRadius,
                color: 'white',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid item>{title}</Grid>
            <Grid item>
                <Typography variant="h5">{count.toLocaleString()}</Typography>
            </Grid>
        </Grid>
    );
};

const SextforceAutoCampaignTipReply = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const timezone: string = moment.tz.guess();
    const [searchParams] = useSearchParams();

    const {
        data: subscriber,
        isLoading: isSubscriberLoading,
        setServiceRunning,
        setServiceRunningLoading,
        setServiceSettings,
    } = useSubscriber();

    const [showAddNewForm, setShowAddNewForm] = useState<boolean>(
        searchParams.has('campaignUrl') && searchParams.get('campaignUrl')!.length > 0 ? true : false,
    );
    const [serviceActive, setServiceActive] = useState<boolean>(false);
    const delayBeforeSendingMessage: boolean = subscriber?.sextforce?.autoCampaignReply?.delayBeforeSendingMessage || false;

    // OnlyFans Notifications Settings
    const { data: onlyFansNotificationSettings, isLoading: onlyFansNotificationSettingsLoading } = useSubscriberSettingsNotifications();
    const youAndYourPosts: OnlyFansSettingsNotificationsTransport.Response | undefined = onlyFansNotificationSettings?.find(
        n => n.code === 'you_and_your_posts',
    );
    const newSubscriberNotifications: OnlyFansSettingsNotificationsTransport.Item | undefined = youAndYourPosts?.items.find(
        n => n.code === 'new_tips_fund_raising',
    );
    const siteNotificationsOn: boolean = newSubscriberNotifications?.isEnabled ?? false;

    // Search
    const [query, setQuery] = useState<string>('');
    const [startDate, setStartDate] = useState<Date>(moment().subtract(1, 'year').toDate());
    const [endDate, setEndDate] = useState<Date>(moment().endOf('day').toDate());
    const [active, setActive] = useState<boolean>(true);
    const [sortBy, setSortBy] = useState<SextforceAutoCampaignTipReplySortBy>('postDate');
    const {
        data: autoCampaignTipReplies,
        refetch: refetchAutoCampaignTipRepllies,
        isLoading: isAutoCampaignTipRepliesLoading,
        updateAutoCampaignTipReply,
        deleteAutoCampaignTipReply,
        createAutoCampaignTipReplyTipMessageDetails,
        updateAutoCampaignTipReplyTipMessageDetails,
        deleteAutoCampaignTipReplyTipMessageDetails,
    } = useSextforceAutoCampaignTipReply(query, startDate, endDate, timezone, active, sortBy);

    useEffect(() => {
        if (subscriber) {
            setServiceActive(subscriber.sextforce?.autoCampaignReply?.active || false);
        } else {
            setServiceActive(false);
        }
    }, [subscriber]);

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}`} />{' '}
                        Auto-Reply to Campaign Tip for {subscriber.username}
                    </Typography>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title="Auto-Reply to Campaign Tips" theme={theme} />
                    </StyledCard>
                    <StyledCard>
                        <SextforceAutoCampaignTipReplyDescription />
                    </StyledCard>

                    <StyledCard>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs>
                                Auto-Reply to Campaign Tip is currently{' '}
                                {serviceActive ? (
                                    <span style={{ color: theme.palette.success.dark }}>running</span>
                                ) : (
                                    <span style={{ color: theme.palette.error.main }}>not running</span>
                                )}
                                .
                            </Grid>
                            <Grid item xs="auto">
                                {isSubscriberLoading || setServiceRunningLoading ? (
                                    <Skeleton width={'100%'} height={51} />
                                ) : (
                                    <LargeMaterialUISwitch
                                        checked={serviceActive}
                                        onChange={e => {
                                            setServiceRunning('sextforce.autoCampaignReply', e.target.checked);
                                            setActive(e.target.checked);
                                        }}
                                        color="primary"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction={'row'} spacing={1} alignItems="center" justifyContent={'space-between'}>
                                    <Stack direction={'column'} spacing={1} alignItems="flex-start">
                                        <Typography variant="body1">Delay before sending message?</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            If enabled, the system will wait a short random time before sending the reply to the tipper.
                                        </Typography>
                                    </Stack>
                                    <Switch
                                        checked={delayBeforeSendingMessage}
                                        size="medium"
                                        onChange={e => {
                                            setServiceSettings('sextforce.autoCampaignReply', {
                                                active: serviceActive,
                                                delayBeforeSendingMessage: e.target.checked,
                                            });
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </StyledCard>

                    {!onlyFansNotificationSettingsLoading && !siteNotificationsOn && (
                        <StyledCard noCard>
                            <Alert severity="warning" sx={{ mb: 2 }}>
                                <Typography variant="body1">
                                    <strong>Important:</strong> For this service to work, you must have the "New Campaign Contribution"
                                    notification turned on in{' '}
                                    <Link to={'https://onlyfans.com/my/settings/notifications/site'} target="_blank">
                                        OnlyFans Notifications Settings
                                    </Link>
                                    .
                                </Typography>
                            </Alert>
                        </StyledCard>
                    )}

                    <StyledCard noCard>
                        <Stack direction="row" spacing={1} flexGrow={0} alignItems="center">
                            <CounterCard
                                title="Campaigns"
                                count={autoCampaignTipReplies?.metadata.totalCampaigns || 0}
                                color={theme.palette.success}
                            />
                            <CounterCard
                                title="Replies Sent"
                                count={autoCampaignTipReplies?.metadata.totalReplies || 0}
                                color={theme.palette.success}
                            />
                        </Stack>
                    </StyledCard>

                    {!showAddNewForm && (
                        <StyledCard noCard>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                size="large"
                                onClick={() => {
                                    setShowAddNewForm(true);
                                }}
                                startIcon={<AddIcon />}
                            >
                                Add New Tip Campaign
                            </Button>
                        </StyledCard>
                    )}

                    {showAddNewForm && (
                        <SextforceAutoCampaignTipReplyNewCampaignForm
                            subscriber={subscriber}
                            refetchAutoCampaignTipRepllies={refetchAutoCampaignTipRepllies}
                            showAddNewForm={showAddNewForm}
                            setShowAddNewForm={setShowAddNewForm}
                            handleCancel={() => {
                                setShowAddNewForm(false);
                            }}
                        />
                    )}

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar
                            title={`Tip Campaigns (${autoCampaignTipReplies ? autoCampaignTipReplies.list.length.toLocaleString() : 0})`}
                            isLoading={isAutoCampaignTipRepliesLoading}
                            theme={theme}
                        />
                    </StyledCard>

                    <SextforceAutoCampaignTipReplySearchBar
                        isLoading={isAutoCampaignTipRepliesLoading}
                        active={active}
                        setActive={setActive}
                        setQuery={setQuery}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        theme={theme}
                    />

                    <StyledCard>
                        <Box
                            sx={{
                                display: 'block',
                                overflowX: 'auto',
                            }}
                        >
                            {!isAutoCampaignTipRepliesLoading && autoCampaignTipReplies && autoCampaignTipReplies.list.length === 0 && (
                                <Typography
                                    variant="body1"
                                    sx={{
                                        textAlign: 'center',
                                        color: theme.palette.text.secondary,
                                    }}
                                >
                                    No tip campaigns yet
                                </Typography>
                            )}
                            {!isAutoCampaignTipRepliesLoading && autoCampaignTipReplies && autoCampaignTipReplies.list.length > 0 && (
                                <>
                                    <Table
                                        size="small"
                                        sx={{
                                            // No padding on the left and right of the table but only on the first and last cell
                                            '& td': {
                                                '&:first-of-type': {
                                                    paddingLeft: '0px',
                                                    paddingRight: '0px',
                                                },
                                                '&:last-child': {
                                                    paddingRight: '0px',
                                                },
                                                paddingLeft: 0.5,
                                                paddingRight: 0.5,
                                            },
                                            '& th': {
                                                '&:first-of-type': {
                                                    paddingLeft: '0px',
                                                    paddingRight: '0px',
                                                },
                                                '&:last-child': {
                                                    paddingRight: '0px',
                                                },
                                                // Make header cells bold
                                                fontWeight: 'bold',
                                                paddingLeft: 0.5,
                                                paddingRight: 0.5,
                                            },
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Tip Campaign</TableCell>
                                                <TableCell width={160} align="center">
                                                    <Stack direction="column" spacing={0.5}>
                                                        <Typography variant="inherit">Posted</Typography>
                                                        <Typography variant="inherit" color="textSecondary">
                                                            Expires
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell width={70} align="center">
                                                    Replies
                                                </TableCell>
                                                <TableCell width={100} align="center">
                                                    Actions
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {autoCampaignTipReplies.list.map(autoCampaignTipReply => (
                                                <SextforceAutoCampaignTipReplyTableRow
                                                    key={autoCampaignTipReply._id}
                                                    autoCampaignTipReply={autoCampaignTipReply}
                                                    updateAutoCampaignTipReply={updateAutoCampaignTipReply}
                                                    deleteAutoCampaignTipReply={deleteAutoCampaignTipReply}
                                                    refetchAutoCampaignTipReplies={refetchAutoCampaignTipRepllies}
                                                    createAutoCampaignTipReplyTipMessageDetails={
                                                        createAutoCampaignTipReplyTipMessageDetails
                                                    }
                                                    updateAutoCampaignTipReplyTipMessageDetails={
                                                        updateAutoCampaignTipReplyTipMessageDetails
                                                    }
                                                    deleteAutoCampaignTipReplyTipMessageDetails={
                                                        deleteAutoCampaignTipReplyTipMessageDetails
                                                    }
                                                    subscriber={subscriber}
                                                    theme={theme}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </>
                            )}
                        </Box>
                    </StyledCard>
                </>
            )}
        </Container>
    );
};

export default SextforceAutoCampaignTipReply;
