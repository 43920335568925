import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
    Theme,
} from '@mui/material';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../../../../store/SettingsContext';
import { d2f } from '../../../../utils/common';
import SextforceMetricsShareDialog from './SextforceMetricsShareDialog';

type Props = {
    subscriber: any | null;
    metricData: any | null;
    saveSettings: (platform: string, associatedUsername: string, goalSpent: number, goalSubs: number) => void;
    isSavingSettings: boolean;
    theme: Theme;
};

export const SextforceMetricsBoughtFromSettingsContent = (props: {
    metricData: any | null;
    platform: string;
    setPlatform: (platform: string) => void;
    associatedUsername: string;
    setAssociatedUsername: (associatedUsername: string) => void;
    setGoalSpent: (goalSpent: number) => void;
    goalSpentString: string;
    setGoalSpentString: (goalSpentString: string) => void;
    setGoalSubs: (goalSubs: number) => void;
    goalSubsString: string;
    setGoalSubsString: (goalSubsString: string) => void;
    setHasChanged: (hasChanged: boolean) => void;
}) => {
    const {
        metricData,
        platform,
        setPlatform,
        associatedUsername,
        setAssociatedUsername,
        setGoalSpent,
        goalSpentString,
        setGoalSpentString,
        setGoalSubs,
        goalSubsString,
        setGoalSubsString,
        setHasChanged,
    } = props;
    const settings = useContext(SettingsContext);

    return (
        <>
            <Grid item xs={12} md>
                <FormControl fullWidth>
                    <InputLabel id="platform">Platform</InputLabel>
                    <Select
                        id="platform"
                        labelId="platform"
                        size="small"
                        label="Platform"
                        disabled={!metricData}
                        displayEmpty
                        renderValue={
                            platform !== ''
                                ? undefined
                                : (selected: string) => {
                                      if (selected && selected.length === 0) {
                                          return <em>Platform</em>;
                                      }

                                      return selected;
                                  }
                        }
                        value={platform}
                        onChange={(e: SelectChangeEvent<string>) => {
                            setPlatform(e.target.value);
                            setHasChanged(true);
                        }}
                    >
                        {Object.keys(settings.platforms).map((p: string) => (
                            <MenuItem key={p} value={p}>
                                <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="left">
                                    <Grid item xs="auto">
                                        {settings.platforms[p].icon!('small')}
                                    </Grid>
                                    <Grid item xs>
                                        {settings.platforms[p].name}
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        ))}
                        <MenuItem value="other">Other</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md>
                <TextField
                    fullWidth
                    id="platform_username"
                    disabled={!metricData}
                    label="Username"
                    value={associatedUsername}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AlternateEmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={e => {
                        setAssociatedUsername(e.target.value);
                        setHasChanged(true);
                    }}
                />
            </Grid>
            <Grid item xs={12} md>
                <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="left">
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="goalSpent">Earnings Goal (Gross)</InputLabel>
                            <OutlinedInput
                                id="goalSpent"
                                label="Earnings Goal (Gross)"
                                disabled={!metricData}
                                value={goalSpentString}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <AttachMoneyIcon />
                                    </InputAdornment>
                                }
                                endAdornment={<InputAdornment position="end">USD</InputAdornment>}
                                inputProps={{
                                    inputMode: 'text',
                                    pattern: /([0-9]+([.][0-9]*)?|[.][0-9]+)/,
                                }}
                                onChange={e => {
                                    e.preventDefault();

                                    if (/^([\d]*[,.]?[\d]{0,2})$/.test(e.target.value)) {
                                        const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                                        if (isNaN(parsed) || parsed === 0) {
                                            setGoalSpent(0);
                                            setGoalSpentString('0');
                                        } else {
                                            setGoalSpent(parsed);
                                            setGoalSpentString(e.target.value.replace(/^0+/, ''));
                                        }

                                        setHasChanged(true);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md>
                <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="left">
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="goalSubs">Subs Goal</InputLabel>
                            <OutlinedInput
                                id="subsGoal"
                                label="Subs Goal"
                                disabled={!metricData}
                                value={goalSubsString}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SubscriptionsIcon />
                                    </InputAdornment>
                                }
                                inputProps={{
                                    inputMode: 'text',
                                    pattern: /^[0-9]*$/,
                                }}
                                onChange={e => {
                                    e.preventDefault();

                                    if (/^[0-9]*$/.test(e.target.value)) {
                                        const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                                        if (isNaN(parsed) || parsed === 0) {
                                            setGoalSubs(0);
                                            setGoalSubsString('0');
                                        } else {
                                            setGoalSubs(parsed);
                                            setGoalSubsString(e.target.value.replace(/^0+/, ''));
                                        }

                                        setHasChanged(true);
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

const SextforceMetricsBoughtFromSettings = (props: Props) => {
    const { subscriber, metricData, saveSettings, isSavingSettings, theme } = props;
    const dialog = useDialog();

    const [platform, setPlatform] = useState<string>('');
    const [associatedUsername, setAssociatedUsername] = useState<string>('');
    const [goalSpent, setGoalSpent] = useState<number>(0);
    const [goalSpentString, setGoalSpentString] = useState<string>('');
    const [goalSubs, setGoalSubs] = useState<number>(0);
    const [goalSubsString, setGoalSubsString] = useState<string>('');
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (metricData) {
            if (metricData.associatedUsername) {
                setAssociatedUsername(metricData.associatedUsername);
            } else {
                setAssociatedUsername('');
            }

            if (metricData.settings) {
                setPlatform(metricData.settings.platform || '');
                setGoalSpent(metricData.settings.goalSpent ? d2f(metricData.settings.goalSpent) : 0);
                setGoalSpentString(metricData.settings.goalSpent ? d2f(metricData.settings.goalSpent).toString() : '');
                setGoalSubs(metricData.settings.goalSubs || 0);
                setGoalSubsString(
                    metricData.settings.goalSubs && metricData.settings.goalSubs !== 0 ? metricData.settings.goalSubs.toString() : '',
                );
            } else {
                setPlatform('');
                setGoalSpent(0);
                setGoalSpentString('');
                setGoalSubs(0);
                setGoalSubsString('');
            }

            setHasChanged(false);
        }
    }, [metricData]);

    const handleSaveSettings = () => {
        dialog
            .confirm({
                message: 'Are you sure you want to save these settings?',
                title: 'Save Settings',
                ok: { text: 'Save', variant: 'contained', color: 'secondary' },
                cancel: { text: 'Cancel' },
            })
            .then(() => {
                saveSettings(platform.trim(), associatedUsername.trim(), goalSpent, goalSubs);
            })
            .catch(() => {});
    };

    const handleResetSettings = () => {
        setAssociatedUsername('');
        setPlatform('');
        setGoalSpent(0);
        setGoalSpentString('');
        setGoalSubs(0);
        setGoalSubsString('');
        setHasChanged(true);
    };

    const handleShareDialogOpen = () => {
        setShareDialogOpen(true);
    };

    const handleShareDialogClose = () => {
        setShareDialogOpen(false);
    };

    return (
        <Card sx={{ width: '100%', marginBottom: theme.spacing(2) }}>
            <CardContent>
                <Grid
                    container
                    spacing={2}
                    flexGrow={1}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ marginBottom: theme.spacing(2) }}
                >
                    <Grid item xs={12}>
                        Enter the username of the creator or account owner you shared the campaign/trial with for improved tracking and
                        easier search. Select the platform they are active on and enter their username.
                    </Grid>
                    <Grid item xs={12}>
                        Enter the campaign's $$$ goal to get see the campaign's progress and get alerted via Telegram when the goal is
                        reached!
                    </Grid>
                    <Grid item xs={12}>
                        Click the Share button to generate a unique sharable link that will show anyone with the link the campaigns current
                        stats excluding the money you've earning from it.
                    </Grid>
                </Grid>

                <Grid container spacing={1} flexGrow={1} alignItems="center" justifyContent="center">
                    <SextforceMetricsBoughtFromSettingsContent
                        metricData={metricData}
                        platform={platform}
                        associatedUsername={associatedUsername}
                        setHasChanged={setHasChanged}
                        goalSpentString={goalSpentString}
                        goalSubsString={goalSubsString}
                        setAssociatedUsername={setAssociatedUsername}
                        setGoalSpent={setGoalSpent}
                        setGoalSpentString={setGoalSpentString}
                        setGoalSubs={setGoalSubs}
                        setGoalSubsString={setGoalSubsString}
                        setPlatform={setPlatform}
                    />
                    <Grid item xs={12} md={6} lg={4}>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="left">
                            <Grid item xs={3} md="auto">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    disabled={isSavingSettings || !metricData || !hasChanged}
                                    endIcon={isSavingSettings ? <CircularProgress size={14} /> : <SaveIcon />}
                                    fullWidth
                                    onClick={() => {
                                        handleSaveSettings();
                                    }}
                                >
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={3} md="auto">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="medium"
                                    disabled={isSavingSettings || !metricData}
                                    endIcon={<RestartAltIcon />}
                                    fullWidth
                                    onClick={() => {
                                        handleResetSettings();
                                    }}
                                >
                                    Clear
                                </Button>
                            </Grid>
                            <Grid item xs={6} md="auto">
                                <Button
                                    variant="contained"
                                    color="info"
                                    size="medium"
                                    disabled={!metricData}
                                    endIcon={<ShareIcon />}
                                    fullWidth
                                    onClick={() => {
                                        handleShareDialogOpen();
                                    }}
                                >
                                    Share
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>

            <SextforceMetricsShareDialog
                open={shareDialogOpen}
                onClose={handleShareDialogClose}
                subscriber={subscriber}
                metricData={metricData}
                theme={theme}
            />
        </Card>
    );
};

export default SextforceMetricsBoughtFromSettings;
