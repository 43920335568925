import JoinFullIcon from '@mui/icons-material/JoinFull';
import { Button, Card, CardContent, Grid, Skeleton, Stack, Theme, Typography, useTheme } from '@mui/material';
import { useDialog } from 'muibox';
import { useState } from 'react';
import useSextforceMetricsGroups, { OnlyFansMetricsGroupWithSummary } from '../../../../hooks/useSextforceMetricsGroups';
import SextforceMetricsGroupDialog from './SextforceMetricsGroupDialog';
import SextforceMetricsGroupsTable from './SextforceMetricsGroupsTable';

type Props = {
    subscriber: any;
    metricType: 'trial' | 'campaign';
    amountType: 'gross' | 'net';
};

const SextforceMetricsGroups = (props: Props) => {
    const { subscriber, metricType, amountType } = props;
    const theme: Theme = useTheme();
    const dialog = useDialog();

    const { data: groups, isLoading: isGroupsLoading, metricsGroupDelete } = useSextforceMetricsGroups(metricType);

    const [groupDialogOpen, setGroupDialogOpen] = useState<boolean>(false);
    const [metricsGroup, setMetricsGroup] = useState<OnlyFansMetricsGroupWithSummary | null>(null);

    const openGroupDialog = (metricId: string | null) => {
        if (metricId && !groups) {
            return;
        }

        setGroupDialogOpen(true);

        if (groups && metricId) {
            setMetricsGroup(groups.find(group => group._id === metricId) || null);
        }
    };

    const handleGroupDialogClose = () => {
        setGroupDialogOpen(false);
        setMetricsGroup(null);
    };

    const handleDeleteGroup = (groupId: string) => {
        dialog
            .confirm({ title: 'Delete Group', message: 'Are you sure you want to delete this group?' })
            .then(() => {
                metricsGroupDelete(groupId);
            })
            .catch(() => {});
    };

    return (
        <Card sx={{ width: '100%', marginBottom: 1 }}>
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent={'space-between'}>
                            <Typography variant="h6">Grouped Metrics</Typography>
                            <Button
                                onClick={() => openGroupDialog('')}
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<JoinFullIcon />}
                            >
                                Create
                            </Button>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        Group metrics from different pages in your dashboard to see the combined earnings. You can group tracking links
                        (campaigns) and trials together from the same and multiple pages as necessary.
                    </Grid>

                    {isGroupsLoading ? (
                        <Grid item xs={12}>
                            <Skeleton variant="rectangular" width="100%" height={50} />
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            {groups && groups.length > 0 ? (
                                <SextforceMetricsGroupsTable
                                    groups={groups}
                                    amountType={amountType}
                                    openGroupDialog={openGroupDialog}
                                    handleDeleteGroup={handleDeleteGroup}
                                    theme={theme}
                                />
                            ) : (
                                <Stack
                                    spacing={1}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: '100%', height: 50 }}
                                >
                                    <Typography variant="body2" color="textSecondary">
                                        No groups found
                                    </Typography>
                                </Stack>
                            )}
                        </Grid>
                    )}
                </Grid>

                <SextforceMetricsGroupDialog
                    open={groupDialogOpen}
                    onClose={handleGroupDialogClose}
                    subscriber={subscriber}
                    metricType={metricType}
                    metricsGroup={metricsGroup}
                />
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsGroups;
