import { Backdrop, Card, CardContent, CircularProgress, Container, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { useContext } from 'react';
import BackNavigationButton from '../../components/common/BackNavigationButton';
import ServiceActionsBlocks from '../../components/common/ServiceActions';
import SextforceServiceDescription from '../../components/services/sextforce/SextforceServiceDescription';
import useSubscriber from '../../hooks/useSubscriber';
import { SettingsContext } from '../../store/SettingsContext';

const LabsOverview = () => {
    const settings = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const { data: subscriber, isFetching: subscriberLoading } = useSubscriber();

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton {...(subscriber && { url: `/subscribers/${subscriber._id}` })} /> Labs Overview for{' '}
                        {subscriber.username}
                    </Typography>

                    {subscriber.sextforce ? (
                        <>
                            <ServiceActionsBlocks actions={settings.services.labs.actions} subscriber={subscriber} theme={theme} />
                        </>
                    ) : (
                        <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                            <CardContent>
                                <SextforceServiceDescription theme={theme} />
                            </CardContent>
                        </Card>
                    )}
                </>
            )}
            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={subscriberLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    );
};

export default LabsOverview;
