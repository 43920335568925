import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
    Alert,
    Box,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    PaletteColor,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import dinero from 'dinero.js';
import moment from 'moment';
import { useDialog } from 'muibox';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import LargeMaterialUISwitch from '../../../components/common/LargeMaterialUISwitch';
import StyledCard from '../../../components/common/StyledCard';
import StyledCardTitleBar from '../../../components/common/StyledCardTitleBar';
import SextforceLiveStreamAddTippersSettings from '../../../components/services/hostess/liveStreams/addTippersToList/SextforceLiveStreamAddTippersSettings';
import SextforceLiveStreamAddTippersToListTableStremsRow from '../../../components/services/hostess/liveStreams/addTippersToList/SextforceLiveStreamAddTippersToListTableStremsRow';
import useSextforceLiveStreamAddTippersToList from '../../../hooks/useSextforceLiveStreamAddTippersToList';
import useSubscriber from '../../../hooks/useSubscriber';
import useSubscriberSettingsNotifications, {
    OnlyFansSettingsNotificationsTransport,
} from '../../../hooks/useSubscriberSettingsNotifications';
import { SettingsContext } from '../../../store/SettingsContext';
import { handleHttpError } from '../../../utils/common';

const SextforceLiveStreanAddTippersToListDescription = () => {
    return (
        <>
            <Typography variant="body1" gutterBottom>
                The system monitors your live streams and automatically adds tippers to an OnlyFans list. This feature is useful if you send
                out content or thank you notes to tippers after the show, or just see an analysis of the stream. The system creates a
                convenient list of tippers for you to use either via the OnlyFans Mass DM facility or the Messaging feature here in the
                dashboard.
            </Typography>
            <Typography variant="body1" gutterBottom>
                You have an option to add all tippers to one global list or create a new list for each stream. Or... both! When you create a
                new live stream, the system will automatically create a new list on OnlyFans called "Stream XXXXXX Tippers" and message you
                on Telegram with the name of the list and a direct link to it. After the stream is finished, you can rename the list to
                anything you like.
            </Typography>
            <Typography variant="body1" gutterBottom>
                At the end of the stream, the system will message you on Telegram with the total amount of tips, tippers and other stats,
                which you can also see below by expanding the stream row.
            </Typography>
            <Typography variant="body1">
                <strong>The system MUST be turned on before you start your live stream.</strong>
            </Typography>
        </>
    );
};

const CounterCard = ({ title, count, color }: { title: string; count: number | string | ReactNode; color: PaletteColor }) => {
    const theme: Theme = useTheme();

    return (
        <Grid
            container
            spacing={1}
            flexGrow={0}
            alignItems="center"
            sx={{
                backgroundColor: color.main,
                padding: '0.5rem',
                borderRadius: theme.shape.borderRadius,
                color: 'white',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid item>{title}</Grid>
            <Grid item>
                <Typography variant="h5">{typeof count === 'number' ? count.toLocaleString() : count}</Typography>
            </Grid>
        </Grid>
    );
};

export const TablePaginationActions = (props: {
    count: number;
    limit: number;
    setLimit: (limit: number) => void;
    skip: number;
    setSkip: (skip: number) => void;
}) => {
    const { count, limit, setLimit, skip, setSkip } = props;

    const handleNextButtonClick = () => {
        setSkip(skip + limit);
    };

    const handleBackButtonClick = () => {
        setSkip(skip - limit);
    };

    return (
        <Stack
            direction="row"
            spacing={2}
            alignItems={'center'}
            justifyContent={'flex-end'}
            width={'100%'}
            sx={{
                mt: 2,
            }}
        >
            <Box sx={{ flex: '1 1 auto' }} />
            <Typography variant="body2">Rows per page:</Typography>
            <Select
                variant="standard"
                value={limit}
                onChange={e => {
                    setLimit(parseInt(e.target.value as string));
                    setSkip(0);
                }}
                size="small"
                sx={{ width: 75 }}
            >
                {[10, 20, 50, 100].map(option => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
            <Typography variant="body2">
                {(skip + 1).toLocaleString()}-{Math.min(skip + limit, count).toLocaleString()} of {count.toLocaleString()}
            </Typography>
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={handleBackButtonClick} disabled={skip === 0}>
                        <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton onClick={handleNextButtonClick} disabled={skip + limit >= count}>
                        <NavigateNextIcon />
                    </IconButton>
                </Box>
            </Box>
        </Stack>
    );
};

export interface AddTippersToListSettings {
    addTippersToIndividualList: boolean;
    addTippersToAllTippersList: boolean;
    allTippersListId: number | undefined;
}

const SextforceLiveStreamAddTippersToList = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const dialog = useDialog();

    const [active, setActive] = useState<boolean>(true);
    const [tippersCount, setTippersCount] = useState<number>(0);

    const {
        data: subscriber,
        isLoading: isSubscriberLoading,
        setServiceRunning,
        setServiceRunningLoading,
        setServiceSettings,
    } = useSubscriber();

    // Add Stream Tippers to List Settings
    const [settings, setSettings] = useState<AddTippersToListSettings>({
        addTippersToIndividualList: false,
        addTippersToAllTippersList: false,
        allTippersListId: undefined,
    });

    const handleUpdateSettings = (newSettings: AddTippersToListSettings) => {
        setServiceSettings('sextforce.liveStream.addTippersToList', newSettings)
            .then(settings => {
                setSettings(settings);
            })
            .catch(error => {
                handleHttpError(error, dialog);
            });
    };

    // OnlyFans Notifications Settings
    const { data: onlyFansNotificationSettings, isLoading: onlyFansNotificationSettingsLoading } = useSubscriberSettingsNotifications();
    const youAndYourPosts: OnlyFansSettingsNotificationsTransport.Response | undefined = onlyFansNotificationSettings?.find(
        n => n.code === 'you_and_your_posts',
    );
    const newTipNotification: OnlyFansSettingsNotificationsTransport.Item | undefined = youAndYourPosts?.items.find(
        n => n.code === 'new_tips',
    );
    const subscriptionsGroup: OnlyFansSettingsNotificationsTransport.Response | undefined = onlyFansNotificationSettings?.find(
        n => n.code === 'subscriptions',
    );
    const newStreamNotification: OnlyFansSettingsNotificationsTransport.Item | undefined = subscriptionsGroup?.items.find(
        n => n.code === 'new_stream',
    );
    const siteNotificationsOn: boolean = newTipNotification?.isEnabled && newStreamNotification?.isEnabled ? true : false;

    // Live Streams
    const [queryText, setQueryText] = useState<string>('');
    const [query, setQuery] = useState<string>('');
    const [limit, setLimit] = useState<number>(20);
    const [skip, setSkip] = useState<number>(0);
    const [startDate, setStartDate] = useState<Date>(moment().subtract(1, 'year').toDate());
    const [endDate, setEndDate] = useState<Date>(moment().endOf('day').toDate());
    const [startDateField, setStartDateField] = useState<Date | null>(startDate); // Used for the date picker
    const [endDateField, setEndDateField] = useState<Date | null>(endDate); // Used for the date picker
    const [sortBy, setSortBy] = useState<'createdAt' | 'name' | 'description' | 'stats.stream_tip_count' | 'stats.stream_tip_sum'>(
        'createdAt',
    );

    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Add a small delay to avoid too many requests
        setTimeout(() => {
            setQuery(event.target.value);
            setSkip(0);
        }, 500);

        setQueryText(event.target.value);
    };

    const {
        data: liveStreams,
        refetch: refetchLiveStreams,
        isLoading: isLiveStreamsLoading,
        isSextforceLiveStreamAddTippersToListLoading,
        sextforceLiveStreamAddTippersToListResetCounters,
        sextforceLiveStreamAddTippersToListEditTitleAndDescription,
    } = useSextforceLiveStreamAddTippersToList(query, limit, skip, startDate, endDate, sortBy);

    useEffect(() => {
        if (subscriber) {
            setActive(subscriber.sextforce?.liveStream?.addTippersToList?.active || false);
            setTippersCount(subscriber.sextforce?.liveStream?.addTippersToListCount || 0);

            setSettings({
                addTippersToIndividualList: subscriber.sextforce?.liveStream?.addTippersToList?.addTippersToIndividualList || false,
                addTippersToAllTippersList: subscriber.sextforce?.liveStream?.addTippersToList?.addTippersToAllTippersList || false,
                allTippersListId: subscriber.sextforce?.liveStream?.addTippersToList?.allTippersListId,
            });
        } else {
            setActive(false);
            setTippersCount(0);
        }
    }, [subscriber]);

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.sextforce.homeUrl}`} /> Add
                        Stream Tippers to List for {subscriber.username}
                    </Typography>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title="Add Stream Tippers to List" theme={theme} />
                    </StyledCard>
                    <StyledCard>
                        <SextforceLiveStreanAddTippersToListDescription />
                    </StyledCard>

                    <StyledCard>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs>
                                Add Stream Tippers to List is currently{' '}
                                {active ? (
                                    <span style={{ color: theme.palette.success.dark }}>running</span>
                                ) : (
                                    <span style={{ color: theme.palette.error.main }}>not running</span>
                                )}
                                .
                            </Grid>
                            <Grid item xs="auto">
                                <LargeMaterialUISwitch
                                    checked={active}
                                    disabled={isSubscriberLoading || setServiceRunningLoading}
                                    onChange={e => {
                                        setServiceRunning('sextforce.liveStream.addTippersToList', e.target.checked);
                                        setActive(e.target.checked);
                                    }}
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                    </StyledCard>

                    <SextforceLiveStreamAddTippersSettings
                        isSubscriberLoading={isSubscriberLoading}
                        settings={settings}
                        setServiceRunningLoading={setServiceRunningLoading}
                        handleUpdateSettings={handleUpdateSettings}
                        theme={theme}
                    />

                    <StyledCard noCard>
                        <Stack direction="row" spacing={1} flexGrow={0} alignItems="center">
                            <CounterCard
                                title="Total Tips"
                                count={
                                    liveStreams
                                        ? dinero({ amount: Math.trunc(liveStreams.totalAmount * 100), currency: 'USD' }).toFormat()
                                        : '$0.00'
                                }
                                color={theme.palette.success}
                            />
                            {/* <CounterCard title="Tippers Added to List" count={tippersCount} color={theme.palette.success} /> */}
                        </Stack>
                    </StyledCard>

                    {/* <StyledCard noCard>
                        <LoadingButton
                            variant="contained"
                            color="secondary"
                            size="large"
                            fullWidth
                            loading={isSextforceLiveStreamAddTippersToListLoading}
                            onClick={() => {
                                dialog
                                    .confirm({ title: 'Confirm', message: 'Are you sure you want to reset the counters?' })
                                    .then(() => {
                                        sextforceLiveStreamAddTippersToListResetCounters().then(data => {
                                            setTippersCount(data.addTippersToListCount || 0);
                                        });
                                    })
                                    .catch(() => {});
                            }}
                        >
                            Reset Counters
                        </LoadingButton>
                    </StyledCard> */}

                    {!onlyFansNotificationSettingsLoading && !siteNotificationsOn && (
                        <StyledCard noCard>
                            <Alert severity="warning" sx={{ mb: 2 }}>
                                <Typography variant="body1">
                                    <strong>Important:</strong> For this service to work, you must have the "New Tips" and the "New Stream"
                                    notifications turned on in{' '}
                                    <Link to={'https://onlyfans.com/my/settings/notifications/site'} target="_blank">
                                        OnlyFans Notifications Settings
                                    </Link>
                                    .
                                </Typography>
                            </Alert>
                        </StyledCard>
                    )}

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title={`Live Streams`} isLoading={isSubscriberLoading} theme={theme} />
                    </StyledCard>

                    <StyledCard noCard noBottomMargin>
                        <Box
                            sx={{
                                bgcolor: 'grey.50',
                                p: 2,
                                pt: 4,
                                // Elevation 1
                                boxShadow: theme.shadows[1],
                                borderRadius: theme.shape.borderRadius,
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Grid container spacing={1} alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs>
                                                <TextField
                                                    fullWidth
                                                    label="Search"
                                                    value={queryText}
                                                    onChange={handleQueryChange}
                                                    onKeyDown={e => {
                                                        // Clear on Escape
                                                        if (e.key === 'Escape') {
                                                            setQuery('');
                                                            setQueryText('');
                                                            setSkip(0);
                                                        }
                                                    }}
                                                    sx={{
                                                        backgroundColor: theme.palette.background.paper,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6} sm="auto">
                                        <MobileDatePicker
                                            label="Start Date"
                                            value={startDateField}
                                            onAccept={(date: moment.Moment | null) => {
                                                if (date) {
                                                    setStartDate(date.toDate());
                                                }
                                            }}
                                            onChange={(date: moment.Moment | null) => {
                                                if (date) {
                                                    setStartDateField(date.toDate());
                                                }
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        backgroundColor: theme.palette.background.paper,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={6} sm="auto">
                                        <MobileDatePicker
                                            label="End Date"
                                            value={endDateField}
                                            minDate={moment(startDate)}
                                            onAccept={(date: moment.Moment | null) => {
                                                if (date) {
                                                    setEndDate(date.toDate());
                                                }
                                            }}
                                            onChange={(date: moment.Moment | null) => {
                                                if (date) {
                                                    setEndDateField(date.toDate());
                                                }
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    sx={{
                                                        backgroundColor: theme.palette.background.paper,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs sm>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="sort-by-label">Sort By</InputLabel>
                                            <Select
                                                labelId="sort-by-label"
                                                label="Sort By"
                                                fullWidth
                                                size="small"
                                                value={sortBy}
                                                onChange={e => {
                                                    setSortBy(
                                                        e.target.value as
                                                            | 'createdAt'
                                                            | 'name'
                                                            | 'description'
                                                            | 'stats.stream_tip_count'
                                                            | 'stats.stream_tip_sum',
                                                    );
                                                }}
                                                sx={{
                                                    backgroundColor: theme.palette.background.paper,
                                                }}
                                            >
                                                <MenuItem value={'createdAt'}>Date Started</MenuItem>
                                                <MenuItem value={'name'}>Name</MenuItem>
                                                <MenuItem value={'description'}>Description</MenuItem>
                                                <MenuItem value={'stats.stream_tip_count'}>Tippers Count</MenuItem>
                                                <MenuItem value={'stats.stream_tip_sum'}>Total Tips</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </Box>
                    </StyledCard>

                    <StyledCard
                        sx={{
                            pl: 0,
                            pr: 0,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'block',
                                overflowX: 'auto',
                            }}
                        >
                            {!isLiveStreamsLoading && liveStreams && liveStreams.list.length === 0 && (
                                <Typography
                                    variant="body1"
                                    sx={{
                                        textAlign: 'center',
                                        color: theme.palette.text.secondary,
                                    }}
                                >
                                    No live streams recorded yet
                                </Typography>
                            )}
                            {!isLiveStreamsLoading && liveStreams && liveStreams.list.length > 0 && (
                                <>
                                    <Table
                                        size="small"
                                        sx={{
                                            // No padding on the left and right of the table but only on the first and last cell
                                            '& td': {
                                                '&:first-of-type': {
                                                    paddingLeft: '0px',
                                                    paddingRight: '0px',
                                                },
                                                '&:first-child': {
                                                    paddingLeft: 0.5,
                                                },
                                                '&:last-child': {
                                                    paddingRight: 0.5,
                                                },
                                                paddingLeft: 0.5,
                                                paddingRight: 0.5,
                                            },
                                            '& th': {
                                                '&:first-of-type': {
                                                    paddingLeft: '0px',
                                                    paddingRight: '0px',
                                                },
                                                '&:last-child': {
                                                    paddingRight: '0px',
                                                },
                                                // Make header cells bold
                                                fontWeight: 'bold',
                                                paddingLeft: 0.5,
                                                paddingRight: 0.5,
                                            },
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={45}>&nbsp;</TableCell>
                                                <TableCell width={130}>ID</TableCell>
                                                <TableCell>Live Stream</TableCell>
                                                <TableCell width={60} align="center">
                                                    Views
                                                </TableCell>
                                                <TableCell width={70} align="center">
                                                    Tippers
                                                </TableCell>
                                                <TableCell width={140} align="center">
                                                    Tips
                                                </TableCell>
                                                <TableCell width={80} align="center">
                                                    Duration
                                                </TableCell>
                                                <TableCell width={80} align="center">
                                                    Actions
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {liveStreams.list.map(liveStream => (
                                                <SextforceLiveStreamAddTippersToListTableStremsRow
                                                    subscriber={subscriber}
                                                    liveStream={liveStream}
                                                    referchLiveStreams={refetchLiveStreams}
                                                    sextforceLiveStreamAddTippersToListEditTitleAndDescription={
                                                        sextforceLiveStreamAddTippersToListEditTitleAndDescription
                                                    }
                                                    theme={theme}
                                                    key={liveStream._id}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePaginationActions
                                        count={liveStreams.totalCount}
                                        limit={limit}
                                        setLimit={setLimit}
                                        skip={skip}
                                        setSkip={setSkip}
                                    />
                                </>
                            )}
                        </Box>
                    </StyledCard>
                </>
            )}
        </Container>
    );
};

export default SextforceLiveStreamAddTippersToList;
