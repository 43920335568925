import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Slider,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import dinero from 'dinero.js';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSubscriber from '../../hooks/useSubscriber';
import useSubscribers from '../../hooks/useSubscribers';
import { UserContext } from '../../store/UserContext';
import { d2f } from '../../utils/common';

const TargetAccountSelect = ({
    sourceSubscriber,
    subscribers,
    isSubscribersLoading,
    targetSubscriberId,
    setTargetSubscriberId,
    disabled,
}: {
    sourceSubscriber: any;
    subscribers: any;
    isSubscribersLoading: boolean;
    targetSubscriberId: string;
    setTargetSubscriberId: (id: string) => void;
    disabled: boolean;
}) => {
    const sortedSubscribers =
        subscribers && subscribers.length > 0
            ? subscribers.sort((a: any, b: any) => {
                  if (a.username < b.username) {
                      return -1;
                  }

                  if (a.username > b.username) {
                      return 1;
                  }

                  return 0;
              })
            : [];

    return (
        <FormControl fullWidth>
            <InputLabel>Target Account</InputLabel>
            <Select
                value={targetSubscriberId}
                onChange={e => setTargetSubscriberId(e.target.value as string)}
                label="Target Account"
                size="medium"
            >
                {isSubscribersLoading && sortedSubscribers.length === 0 && (
                    <MenuItem>
                        <Skeleton />
                    </MenuItem>
                )}
                {sortedSubscribers
                    .filter((sub: any) => sub._id !== sourceSubscriber._id)
                    .map((sub: any) => (
                        <MenuItem key={sub._id} value={sub._id} disabled={disabled}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                    <Typography variant="body1">{sub.username.toUpperCase()}</Typography>
                                </Grid>
                                <Grid item xs="auto" textAlign={'right'}>
                                    <Typography variant="body2" fontFamily={'monospace'}>
                                        {sub.financial && sub.financial.sharedCredit
                                            ? dinero({
                                                  amount: Math.trunc(d2f(sub.financial.sharedCredit) * 100),
                                                  currency: 'GBP',
                                              }).toFormat()
                                            : '£0.00'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

const AmountSelectSlider = ({
    availableCredit,
    transferAmount,
    setTransferAmount,
    disabled,
}: {
    availableCredit: number;
    transferAmount: number;
    setTransferAmount: (amount: number) => void;
    disabled: boolean;
}) => {
    return (
        <Slider
            value={transferAmount}
            onChange={(e, amount) => setTransferAmount(amount as number)}
            valueLabelDisplay="off"
            min={0}
            max={availableCredit}
            step={1}
            marks={
                availableCredit > 0
                    ? [
                          {
                              value: 0,
                              label: '0',
                          },
                          {
                              value: availableCredit / 2,
                              label: dinero({
                                  amount: Math.trunc((availableCredit / 2) * 100),
                                  currency: 'GBP',
                              }).toFormat(),
                          },
                          {
                              value: availableCredit,
                              label: dinero({
                                  amount: Math.trunc(availableCredit * 100),
                                  currency: 'GBP',
                              }).toFormat(),
                          },
                      ]
                    : []
            }
            color={transferAmount > 0 ? 'primary' : 'secondary'}
            disabled={disabled}
        />
    );
};

type SharedCreditTransferDialogProps = {
    open: boolean;
    onClose: () => void;
    sourceSubscriber: any;
};

const SharedCreditTransferDialog = (props: SharedCreditTransferDialogProps) => {
    const { open, onClose, sourceSubscriber } = props;
    const userContext = useContext(UserContext);
    const dialog = useDialog();
    const params = useParams();
    const { moveCreditToAnotherAccount, moveCreditToAnotherAccountLoading, refetch: refetchSubscriber } = useSubscriber();
    const { data: subscribers, isFetching: isSubscribersLoading, refetch: refetchSubscribers } = useSubscribers();

    const [targetSubscriberId, setTargetSubscriberId] = useState<string>('');
    const [transferAmount, setTransferAmount] = useState<number>(0);

    const handleClose = () => {
        onClose();

        setTargetSubscriberId('');
        setTransferAmount(0);
    };

    const handleTransfer = () => {
        if (targetSubscriberId !== '' && transferAmount > 0) {
            moveCreditToAnotherAccount(sourceSubscriber._id, targetSubscriberId, transferAmount).then(response => {
                if (response.success) {
                    if (params.userId) {
                        refetchSubscriber();
                    }

                    refetchSubscribers();

                    dialog
                        .alert({
                            title: 'Transfer Credit',
                            message: 'Credit transfered successfully.',
                            ok: {
                                variant: 'contained',
                                color: 'primary',
                                text: 'OK',
                            },
                        })
                        .then(() => {
                            handleClose();
                        });
                }
            });
        }
    };

    const sharedCredit: number =
        sourceSubscriber.financial && sourceSubscriber.financial.sharedCredit ? d2f(sourceSubscriber.financial.sharedCredit) : 0;

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
            <DialogTitle>Transfer Credit</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>
                    Select the account you wish to transfer the credit to and enter the amount.
                </DialogContentText>
                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                        {isSubscribersLoading ? (
                            <Skeleton />
                        ) : (
                            <TargetAccountSelect
                                sourceSubscriber={sourceSubscriber}
                                subscribers={subscribers}
                                isSubscribersLoading={isSubscribersLoading}
                                targetSubscriberId={targetSubscriberId}
                                setTargetSubscriberId={setTargetSubscriberId}
                                disabled={moveCreditToAnotherAccountLoading}
                            />
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sx={{ ml: 1, pr: 1 }}>
                        {isSubscribersLoading ? (
                            <Skeleton />
                        ) : (
                            <AmountSelectSlider
                                availableCredit={sharedCredit}
                                transferAmount={transferAmount}
                                setTransferAmount={setTransferAmount}
                                disabled={moveCreditToAnotherAccountLoading}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={0} alignItems={'center'}>
                            <div>Transfer</div>
                            <div>
                                <TextField
                                    label="Amount"
                                    value={transferAmount}
                                    onChange={e => setTransferAmount(parseFloat(e.target.value))}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    inputProps={{
                                        min: 0,
                                        max: sharedCredit,
                                        step: 0.01,
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                    }}
                                    disabled={moveCreditToAnotherAccountLoading}
                                    sx={{ ml: 1, pr: 1 }}
                                />
                            </div>
                            <div>
                                to{' '}
                                <strong>
                                    {!userContext.isAdmin &&
                                        subscribers &&
                                        subscribers.find((sub: any) => sub._id === targetSubscriberId)?.username.toUpperCase()}
                                </strong>
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    autoFocus
                    onClick={handleClose}
                    disabled={moveCreditToAnotherAccountLoading}
                >
                    Cancel
                </Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={transferAmount <= 0 || targetSubscriberId === ''}
                    endIcon={<FontAwesomeIcon icon={faMoneyBillTransfer} />}
                    autoFocus
                    disableElevation
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    onClick={handleTransfer}
                    loading={moveCreditToAnotherAccountLoading}
                >
                    Transfer
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SharedCreditTransferDialog;
