import { Grid, Typography } from '@mui/material';
import StyledCard from '../../../../components/common/StyledCard';

const AutoReplyIntroCard = () => {
    return (
        <StyledCard>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                    Auto Reply to Freeloaders
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    Free up your sexters time by setting up automatic replies to freeloaders to nudge them towards spending some money.
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    You can setup as many replies as you like. On the last reply, the system will take the action you specified - do
                    nothing, mute, restrict, or block the user.
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    Setup exactly who you want the system to target and who to skip.
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    This features requires a current Sextforce subscription.
                </Typography>
            </Grid>
        </StyledCard>
    );
};

export default AutoReplyIntroCard;
