import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MouseIcon from '@mui/icons-material/Mouse';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { Container, Grid, Skeleton, Theme, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import dinero from 'dinero.js';
import moment from 'moment-timezone';
import 'moment/locale/en-gb';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
// import WarningIcon from '@mui/icons-material/Warning';
import OverviewCard from '../../../components/common/OverviewCard';
import PriceCard from '../../../components/common/PriceCard';
import { SettingsContext } from '../../../store/SettingsContext';
import { handleHttpError, metricTypeName } from '../../../utils/common';
// import SextforceMetricsSuspiciousUsersList from '../../../components/services/sextforce/metrics/SextforceMetricsSuspiciousUsersList';
import axios from 'axios';
import SextforceMetricsCampaignCountersGraph from '../../../components/services/sextforce/metrics/campaigns/details/SextforceMetricsCampaignCountersGraph';
import SextforceMetricsCampaignSubscribedAtGraph from '../../../components/services/sextforce/metrics/campaigns/details/SextforceMetricsCampaignSubscribedAtGraph';
import SextforceMetricsTrialLink from '../../../components/services/sextforce/metrics/trials/details/SextforceMetricsTrialLink';
import SextforceMetricsTrialSubscribedAtGraph from '../../../components/services/sextforce/metrics/trials/details/SextforceMetricsTrialSubscribedAtGraph';

const SextforceMetricsPublic = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const params = useParams();

    const timezone: string = moment.tz.guess();

    // Set global MomentJS locale
    moment.locale('en-gb');

    // Retrieve subscriber data
    const fetchMetric = async () => {
        const query: string = `${settingsContext.routes.sextforce.publicMetrics}${params.metricShareLinkId}`;

        return axios
            .get(query, {
                method: 'get',
                headers: {
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(response => response.data);
    };

    const { data: metric, isLoading: metricLoading } = useQuery(['publicMetric'], () => fetchMetric(), {
        refetchOnWindowFocus: false,
        staleTime: 0,
        enabled: 'metricShareLinkId' in params && params.metricShareLinkId ? true : false,
        retry: 0,
        onError: (error: any) => {
            if (error && error.response && error.response.data && error.response.data.status && error.response.data.status === 400) {
                dialog
                    .alert({
                        message: 'Link does not exist or expired',
                        title: 'Error',
                        ok: {
                            text: 'OK',
                            color: 'primary',
                            variant: 'contained',
                        },
                    })
                    .then(() => {});

                return null;
            }

            handleHttpError(error, dialog);
        },
    });

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            {metricLoading && <Skeleton />}
            {!metricLoading && metric && (
                <>
                    <Typography variant="h5">
                        {metricTypeName(metric.type)} Metrics for {metric.username}
                    </Typography>
                    {metric.name && <Typography variant="h6">{metric.name}</Typography>}
                    {metric.createdAt && (
                        <Typography variant="subtitle1" sx={{ marginBottom: theme.spacing(2) }}>
                            {`Created at: ${moment(metric.createdAt).tz(timezone).format('LL')}`}
                        </Typography>
                    )}

                    <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                        {metric.counters && 'countSubscribers' in metric.counters && typeof metric.counters.countSubscribers === 'number' && (
                            <Grid item xs={12} md={6} lg={3}>
                                <OverviewCard
                                    title="Total Subscribed"
                                    value={metric.counters.countSubscribers}
                                    loading={false}
                                    icon={<SubscriptionsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                    theme={theme}
                                />
                            </Grid>
                        )}
                        {metric.counters && 'countTransitions' in metric.counters && typeof metric.counters.countTransitions === 'number' && (
                            <Grid item xs={12} md={6} lg={3}>
                                <OverviewCard
                                    title="Total Clicked"
                                    value={metric.counters.countTransitions}
                                    loading={false}
                                    icon={<MouseIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                    theme={theme}
                                />
                            </Grid>
                        )}

                        {metric.counters && 'claimsCount' in metric.counters && typeof metric.counters.claimsCount === 'number' && (
                            <Grid item xs={12} md={6} lg={3}>
                                <OverviewCard
                                    title="Total Claimed"
                                    value={metric.counters.claimsCount}
                                    loading={false}
                                    icon={<SubscriptionsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                    theme={theme}
                                />
                            </Grid>
                        )}
                        {metric.counters && 'subscribeCounts' in metric.counters && typeof metric.counters.subscribeCounts === 'number' && (
                            <Grid item xs={12} md={6} lg={3}>
                                <OverviewCard
                                    title="Total Subscribed"
                                    value={metric.counters.subscribeCounts}
                                    loading={false}
                                    icon={<MouseIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                    theme={theme}
                                />
                            </Grid>
                        )}

                        {/* {metric.suspiciousUsers && (
                            <Grid item xs={12} md={6} lg={3}>
                                <OverviewCard
                                    title="Suspicious Users"
                                    value={metric.suspiciousUsers.length}
                                    loading={false}
                                    icon={<WarningIcon fontSize="large" htmlColor={theme.palette.warning.dark} />}
                                    theme={theme}
                                />
                            </Grid>
                        )} */}
                        {'totalEarnings' in metric && typeof metric.totalEarnings === 'number' && (
                            <>
                                <Grid item xs={12} md={6} lg={3}>
                                    <PriceCard
                                        title="Total Earned (gross)"
                                        value={dinero({ amount: Math.trunc(metric.totalEarnings * 100), currency: 'USD' })}
                                        loading={false}
                                        icon={<AttachMoneyIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                        theme={theme}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <PriceCard
                                        title="Total Earned (net)"
                                        value={dinero({ amount: Math.trunc(metric.totalEarnings * 100 * 0.8), currency: 'USD' })}
                                        loading={false}
                                        icon={<AttachMoneyIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                                        theme={theme}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>

                    {metric.type === 'trialLinkTrial' && metric.trialUrl && (
                        <SextforceMetricsTrialLink trialUrl={metric.trialUrl} theme={theme} />
                    )}

                    {metric.type === 'promoCampaign' && metric.campaignCode && (
                        <SextforceMetricsTrialLink
                            trialUrl={`https://onlyfans.com/${metric.username}/c${metric.campaignCode}`}
                            theme={theme}
                        />
                    )}

                    {metric.type === 'promoCampaign' && metric.counters.metricsDatas && (
                        <SextforceMetricsCampaignCountersGraph
                            promoCampaignCounters={metric.counters}
                            promoCampaignCountersLoading={false}
                            timezone={timezone}
                            theme={theme}
                        />
                    )}

                    {metric.subscribersGraph && metric.type === 'promoCampaign' && (
                        <SextforceMetricsCampaignSubscribedAtGraph
                            metricId={metric._id}
                            promoCampaignSubscribedAtVsSales={metric.subscribersGraph}
                            promoCampaignSubscribedAtVsSalesLoading={false}
                            showEarnings={metric.totalEarnings}
                            showEarningsAsGross={true}
                            showEarningsWithSubscriptions={true}
                            timezone={timezone}
                            theme={theme}
                        />
                    )}

                    {metric.subscribersGraph && (metric.type === 'trialLinkTrial' || metric.type === 'trialLinkPromo') && (
                        <SextforceMetricsTrialSubscribedAtGraph
                            metricId={metric._id}
                            trialSubscribedAtVsSales={metric.subscribersGraph}
                            trialSubscribedAtVsSalesLoading={false}
                            showEarnings={metric.totalEarnings}
                            showEarningsAsGross={true}
                            showEarningsWithSubscriptions={true}
                            timezone={timezone}
                            theme={theme}
                        />
                    )}

                    {/* {metric.suspiciousUsers && (
                        <SextforceMetricsSuspiciousUsersList
                            metricId={''}
                            suspiciousUsers={metric.suspiciousUsers}
                            suspiciousUsersLoading={false}
                            theme={theme}
                            timezone={timezone}
                        />
                    )} */}
                </>
            )}
        </Container>
    );
};

export default SextforceMetricsPublic;
