import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Checkbox, FormControlLabel, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Dinero from 'dinero.js';
import { parse as parseCsv } from 'json2csv';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { d2f, trendColor, trendIcon } from '../../../../utils/common';

/**
 * Transforms the transactions data rows from the server to a format ready for export to CSV or Excel
 * @param rows Transactions rows
 * @param timezone Timezone string to convert the transaction time to
 * @returns Transformed data for export
 */
const transformTransactionsForExport = (mainData: any[], compareData: any[] | null, compare: boolean) => {
    const headers = [
        {
            label: 'Agent Name',
            value: 'agentName',
        },
        {
            label: 'Agent Type',
            value: 'agentType',
        },
        {
            label: 'Agent Role',
            value: 'agentRole',
        },
        {
            label: 'Fractions',
            value: 'fraction',
        },
        {
            label: 'Total Amount ($USD)',
            value: 'totalAmount',
        },
        {
            label: 'Total Fee ($USD)',
            value: 'totalFee',
        },
        {
            label: 'Total Net ($USD)',
            value: 'totalNet',
        },
        {
            label: 'Total Commission ($USD)',
            value: 'totalCommission',
        },
    ];

    if (compare) {
        headers.push({
            label: 'Total Amount ($USD) - Compare',
            value: 'totalAmountCompare',
        });
        headers.push({
            label: 'Total Fee ($USD) - Compare',
            value: 'totalFeeCompare',
        });
        headers.push({
            label: 'Total Net ($USD) - Compare',
            value: 'totalNetCompare',
        });
        headers.push({
            label: 'Total Commission ($USD) - Compare',
            value: 'totalCommissionCompare',
        });
    }

    const fractionDateFromCompare = (fraction: number) => {
        if (!compareData) {
            return {};
        }

        const compareAgent = compareData.find(row => d2f(row.fraction) === fraction);

        if (!compareAgent) {
            return {};
        }

        return {
            totalAmountCompare: Dinero({ amount: compareAgent.totalAmountCents, currency: 'USD' }).toFormat(),
            totalFeeCompare: Dinero({ amount: compareAgent.totalFeeCents, currency: 'USD' }).toFormat(),
            totalNetCompare: Dinero({
                amount: compareAgent.totalNetCents,
                currency: 'USD',
            }).toFormat(),
            totalCommissionCompare: Dinero({
                amount: compareAgent.totalCommissionCents,
                currency: 'USD',
            }).toFormat(),
        };
    };

    const data = mainData.map(row => {
        return {
            agentName: row.agent ? row.agent.name : 'Unknown',
            fraction: d2f(row.fraction).toLocaleString(undefined, { minimumFractionDigits: 2 }),
            agentType: row.agent && row.agent.type ? row.agent.type : '',
            agentRole: row.agent && row.agent.role ? row.agent.role : '',
            totalAmount: Dinero({ amount: row.totalAmountCents, currency: 'USD' }).toFormat(),
            totalFee: Dinero({ amount: row.totalFeeCents, currency: 'USD' }).toFormat(),
            totalNet: Dinero({ amount: row.totalNetCents, currency: 'USD' }).toFormat(),
            totalCommission: Dinero({ amount: row.totalCommissionCents, currency: 'USD' }).toFormat(),
            ...(compare && fractionDateFromCompare(d2f(row.fraction))),
        };
    });

    return { headers, rows: data };
};

// const calculateAgentCommision = (transaction: any) => {
//     if (transaction.agent) {
//         return Dinero({ amount: transaction.totalNetCents, currency: 'USD' }).percentage(
//             transaction.commissionPercentOverride ? transaction.commissionPercentOverride : transaction.agent.commissionPercent,
//         );
//     }

//     return Dinero({ amount: 0, currency: 'USD' });
// };

const generateDataRows = (transactionsMain: any[], transactionsCompare: any[], compare: boolean, theme: Theme) => {
    return transactionsMain.map(transactionMain => {
        let compareTotals = {};

        const totalAmount = Dinero({ amount: transactionMain.totalAmountCents, currency: 'USD' });
        const totalFee = Dinero({ amount: transactionMain.totalFeeCents, currency: 'USD' });
        const totalNet = Dinero({ amount: transactionMain.totalNetCents, currency: 'USD' });
        const totalCommission = Dinero({ amount: transactionMain.totalCommissionCents, currency: 'USD' });
        // const totalCommission = calculateAgentCommision(transactionMain);

        if (compare) {
            // Find a compare transaction with the same fraction
            const transactionCompare = transactionsCompare.find(transaction => d2f(transaction.fraction) === d2f(transactionMain.fraction));

            if (transactionCompare) {
                compareTotals = {
                    totalAmount: Dinero({ amount: transactionCompare.totalAmountCents, currency: 'USD' }),
                    totalAmountCents: transactionCompare.totalAmountCents,
                    totalAmountColor: trendColor(
                        totalAmount,
                        Dinero({ amount: transactionCompare.totalAmountCents, currency: 'USD' }),
                        compare,
                        theme,
                    ),
                    totalFee: Dinero({ amount: transactionCompare.totalFeeCents, currency: 'USD' }),
                    totalFeeCents: transactionCompare.totalFeeCents,
                    totalFeeColor: trendColor(
                        totalFee,
                        Dinero({ amount: transactionCompare.totalFeeCents, currency: 'USD' }),
                        compare,
                        theme,
                    ),
                    totalNet: Dinero({ amount: transactionCompare.totalNetCents, currency: 'USD' }),
                    totalNetCents: transactionCompare.totalNetCents,
                    totalNetColor: trendColor(
                        totalNet,
                        Dinero({ amount: transactionCompare.totalNetCents, currency: 'USD' }),
                        compare,
                        theme,
                    ),
                    totalCommission: Dinero({ amount: transactionCompare.totalCommissionCents, currency: 'USD' }),
                    totalCommissionCents: transactionCompare.totalCommissionCents,
                    totalCommissionColor: trendColor(
                        totalCommission,
                        Dinero({ amount: transactionCompare.totalCommissionCents, currency: 'USD' }),
                        compare,
                        theme,
                    ),
                };
            }
        }

        // Construct a DataGrid row
        const row: any = {
            id: d2f(transactionMain.fraction) * 100,
            agentId: transactionMain.agent ? transactionMain.agent._id : null,
            agentName: transactionMain.agent ? transactionMain.agent.name : 'Unknown',
            agentType: transactionMain.agent && transactionMain.agent.type ? transactionMain.agent.type : '',
            agentRole: transactionMain.agent && transactionMain.agent.role ? transactionMain.agent.role : '',
            fraction: d2f(transactionMain.fraction),
            totalAmount,
            totalAmountCents: transactionMain.totalAmountCents,
            totalFee,
            totalFeeCents: transactionMain.totalFeeCents,
            totalNet,
            totalNetCents: transactionMain.totalNetCents,
            totalCommission,
            compare,
            compareTotals,
        };

        return row;
    });
};

const renderCell = (mainPrice: Dinero.Dinero, comparePrice: Dinero.Dinero, compare: boolean, color: string) => {
    return (
        <>
            <Grid container flexGrow={1} alignItems="center">
                <Grid item xs={1}>
                    {compare && <FontAwesomeIcon icon={trendIcon(mainPrice, comparePrice, compare)} color={color} />}
                </Grid>
                <Grid item xs>
                    <Typography variant="body1" align="right" color={color} sx={{ fontFamily: 'monospace' }}>
                        {mainPrice.toFormat()}
                    </Typography>
                    {compare && comparePrice && (
                        <Typography variant="body1" align="right" sx={{ fontFamily: 'monospace' }}>
                            {comparePrice.toFormat()}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

const columns = (subscriberId: string, searchParams: string, theme: Theme): GridColDef[] => [
    {
        field: 'agentName',
        headerName: 'Agent',
        flex: 1,
        renderCell: params => (
            <Link
                to={`/subscribers/${subscriberId}/services/sextforce/transactions?${searchParams}&agent=${params.row.agentId}&runReport=true`}
                style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
            >
                <strong>{params.value}</strong>
            </Link>
        ),
    },
    { field: 'agentType', headerName: 'Type', flex: 0.5 },
    { field: 'agentRole', headerName: 'Role', flex: 0.5 },
    {
        field: 'fraction',
        headerName: 'Fraction',
        width: 80,
        align: 'right',
        headerAlign: 'right',
        renderCell: params => (
            <div style={{ fontFamily: 'monospace' }}>{params.row.fraction.toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
        ),
    },
    {
        field: 'totalAmountCents',
        headerName: 'Gross',
        width: 150,
        align: 'right',
        headerAlign: 'right',
        renderCell: params =>
            renderCell(
                params.row.totalAmount,
                params.row.compareTotals.totalAmount,
                params.row.compare,
                params.row.compareTotals.totalAmountColor,
            ),
    },
    {
        field: 'totalFeeCents',
        headerName: 'OF Fee',
        width: 150,
        align: 'right',
        headerAlign: 'right',
        renderCell: params =>
            renderCell(params.row.totalFee, params.row.compareTotals.totalFee, params.row.compare, params.row.compareTotals.totalFeeColor),
    },
    {
        field: 'totalNetCents',
        headerName: 'Net',
        width: 130,
        align: 'right',
        headerAlign: 'right',
        renderCell: params =>
            renderCell(params.row.totalNet, params.row.compareTotals.totalNet, params.row.compare, params.row.compareTotals.totalNetColor),
    },
    {
        field: 'totalCommissionCents',
        headerName: 'Commission',
        align: 'right',
        headerAlign: 'right',
        width: 130,
        renderCell: params =>
            renderCell(
                params.row.totalCommission,
                params.row.compareTotals.totalCommission,
                params.row.compare,
                params.row.compareTotals.totalCommissionColor,
            ),
    },
];

type ReportAccountTotalsByAgentProps = {
    subscriberId: string;
    searchParams: string;
    transactionsMain: any[];
    transactionsCompare: any[];
    compare: boolean;
    isFetching: boolean;
};

const ReportAccountTotalsByAgent = (props: ReportAccountTotalsByAgentProps) => {
    const { subscriberId, searchParams, transactionsMain, transactionsCompare, compare, isFetching } = props;
    const theme: Theme = useTheme();
    const [rows, setRows] = useState<any[]>([]);
    const [includeUnknownAgents, setIncludeUnknownAgents] = useState<boolean>(true);
    const timezone: string = moment.tz.guess();

    const [filteredTransactionsMain, setFilteredTransactionsMain] = useState<any[]>([]);
    const [filteredTransactionsCompare, setFilteredTransactionsCompare] = useState<any[]>([]);

    useEffect(() => {
        if (transactionsMain) {
            setFilteredTransactionsMain(includeUnknownAgents ? transactionsMain : transactionsMain.filter(t => t.agent !== null));
        } else {
            setFilteredTransactionsMain([]);
        }
    }, [transactionsMain, includeUnknownAgents]);

    useEffect(() => {
        if (transactionsCompare) {
            setFilteredTransactionsCompare(includeUnknownAgents ? transactionsCompare : transactionsCompare.filter(t => t.agent !== null));
        } else {
            setFilteredTransactionsCompare([]);
        }
    }, [transactionsCompare, includeUnknownAgents]);

    useEffect(() => {
        setRows(generateDataRows(filteredTransactionsMain, filteredTransactionsCompare, compare, theme));
    }, [compare, theme, filteredTransactionsMain, filteredTransactionsCompare]);

    // Convert report to CSV format and start file download
    const handleDownloadReportCsv = () => {
        const transformedData: any = transformTransactionsForExport(filteredTransactionsMain, filteredTransactionsCompare, compare);

        // Format JSON data to CSV
        const csv = parseCsv(transformedData.rows, {
            fields: transformedData.headers,
            transforms: [],
        });

        // Convert CSV to Blob
        const blob: Blob = new Blob([csv], { type: 'text/csv' });

        // Create a descriptive filename
        const filename: string = `sales_report_by_agent_${moment().tz(timezone, true).format('YYYY-MM-DD')}.csv`;

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode && link.parentNode.removeChild(link);
    };

    // Convert to Excel format and start file download
    const handleDownloadReportExcel = () => {
        const transformedData: any = transformTransactionsForExport(filteredTransactionsMain, filteredTransactionsCompare, compare);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [transformedData.headers.map((header: any) => header.label)]);

        XLSX.utils.sheet_add_json(worksheet, transformedData.rows, {
            origin: 'A2',
            skipHeader: true,
            header: transformedData.headers.map((header: any) => header.value),
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

        // Create a descriptive filename
        const filename: string = `sales_report_by_agent_${moment().tz(timezone, true).format('YYYY-MM-DD')}.xls`;

        XLSX.writeFile(workbook, filename);
    };

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
            <CardContent>
                <Grid container spacing={2} alignItems="center" sx={{ marginBottom: theme.spacing(2), padding: 0 }}>
                    <Grid item xs>
                        <Typography variant="h6">Agents Performance</Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Include Unknown Agents">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeUnknownAgents}
                                        onChange={event => setIncludeUnknownAgents(event.target.checked)}
                                    />
                                }
                                label="Include Unknown Agents"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Download as CSV">
                            <span>
                                <IconButton
                                    disabled={
                                        !transactionsMain || (!transactionsCompare && !compare) || filteredTransactionsMain.length === 0
                                    }
                                    onClick={() => {
                                        handleDownloadReportCsv();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faFileCsv}
                                        size="2x"
                                        color={
                                            !transactionsMain || (!transactionsCompare && !compare) || filteredTransactionsMain.length === 0
                                                ? theme.palette.grey[500]
                                                : theme.palette.info.main
                                        }
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Download as Excel">
                            <span>
                                <IconButton
                                    disabled={
                                        !transactionsMain || (!transactionsCompare && !compare) || filteredTransactionsMain.length === 0
                                    }
                                    onClick={() => {
                                        handleDownloadReportExcel();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faFileExcel}
                                        size="2x"
                                        color={
                                            !transactionsMain || (!transactionsCompare && !compare) || filteredTransactionsMain.length === 0
                                                ? theme.palette.grey[500]
                                                : theme.palette.info.main
                                        }
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>

                <DataGrid
                    rows={rows}
                    columns={columns(subscriberId, searchParams, theme)}
                    initialState={{ sorting: { sortModel: [{ field: 'totalAmountCents', sort: 'desc' }] } }}
                    hideFooter={true}
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight={true}
                    loading={isFetching}
                    rowCount={transactionsMain ? filteredTransactionsMain.length : 0}
                    sx={{ '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' }, backgroundColor: '#ffffff' }}
                />
            </CardContent>
        </Card>
    );
};

export default ReportAccountTotalsByAgent;
