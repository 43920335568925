import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Tab,
    Tabs,
    Theme,
    Typography,
} from '@mui/material';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError, handleHttpErrorResponse, metricTypeName } from '../../../../utils/common';
import LinkWithCopyToClipboardBox from '../../../common/LinkWithCopyToClipboardBox';
import { PromoCampaignOptions, TrialOrPromoOptions } from './SextforceMetricsShareDialogOptions';
import SextforceMetricsShareDialogTelegram from './SextforceMetricsShareDialogTelegram';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
    theme: Theme;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, theme, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{
                        p: 3,
                        bgcolor: 'grey.100',
                        borderBottomColor: theme.palette.primary.main,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                    }}
                >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

type Props = {
    open: boolean;
    onClose: () => void;
    subscriber: any | null;
    metricData: any;
    theme: Theme;
};

const SextforceMetricsShareDialog = (props: Props) => {
    const { open, onClose, subscriber, metricData, theme } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();

    const [value, setValue] = useState(0);

    const [options, setOptions] = useState<any>({});
    const [expiresIn, setExpiresIn] = useState<number>(30);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [webLink, setWebLink] = useState<string | null>(null);
    const [telegramLink, setTelegramLink] = useState<string | null>(null);

    useEffect(() => {
        if (!open) {
            setOptions({});
            setExpiresIn(30);
            setIsLoading(false);
            setWebLink(null);
            setTelegramLink(null);
        }
    }, [open]);

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    const handleCreateShareDashboardLink = () => {
        const createShareLink = async () => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.metrics.base}${params.userId}/promocampaigns/${metricData._id}/share`;

                setIsLoading(true);

                const body: any = {
                    options,
                    expiresIn,
                };

                // Update campaign settings
                const shareLink = await fetch(query, {
                    method: 'post',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        setIsLoading(false);
                        handleHttpError(error, dialog);
                    });

                if (shareLink && shareLink.link) {
                    setWebLink(`https://dashboard.onlystruggles.com/sextforce/metrics/${shareLink.link}`);
                }

                setIsLoading(false);
            }
        };

        createShareLink();
    };

    return (
        <>
            {subscriber && metricData ? (
                <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
                    <DialogTitle>Share {metricData && metricTypeName(metricData.type)} Stats</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {webLink && 'Click below to copy link to clipboard'}
                            {!webLink &&
                                `Invite a colleague to receive basic daily updates on the progress of this ${
                                    metricData && metricTypeName(metricData.type)
                                }, or create a sharable web link to share the information below with your colleagues, the person you bought GGs from or your clients.`}
                        </DialogContentText>
                    </DialogContent>

                    <AppBar position="static">
                        <Tabs
                            value={value}
                            onChange={(event: React.SyntheticEvent, newValue: number) => {
                                setValue(newValue);
                            }}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs"
                        >
                            <Tab label="Telegram Updates" {...a11yProps(0)} />
                            <Tab label="Web Link" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>

                    <TabPanel value={value} index={0} dir={theme.direction} theme={theme}>
                        <SextforceMetricsShareDialogTelegram
                            subscriber={subscriber}
                            metricData={metricData}
                            telegramLink={telegramLink}
                            setTelegramLink={setTelegramLink}
                            theme={theme}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={1} dir={theme.direction} theme={theme}>
                        {webLink && webLink.length > 0 && (
                            <>
                                <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                                    Click on the link below to copy it to the clipboard.
                                </Typography>
                                <LinkWithCopyToClipboardBox link={webLink} theme={theme} />
                            </>
                        )}

                        {!webLink && (
                            <Grid container spacing={2} flexGrow={1}>
                                <Grid item xs={12}>
                                    Select the items you want to share:
                                </Grid>
                                <Grid item xs={12}>
                                    {metricData && metricData.type === 'promoCampaign' && (
                                        <PromoCampaignOptions setOptions={setOptions} theme={theme} />
                                    )}
                                    {metricData && (metricData.type === 'trialLinkPromo' || metricData.type === 'trialLinkTrial') && (
                                        <TrialOrPromoOptions setOptions={setOptions} theme={theme} />
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    This link will automatically expire in:
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} alignItems="center" flexGrow={1}>
                                        <Grid item xs="auto">
                                            Expires In
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl>
                                                <InputLabel id="shareExpire">Expires In</InputLabel>
                                                <Select
                                                    labelId="shareExpired"
                                                    id="shareExpired"
                                                    value={expiresIn.toString()}
                                                    onChange={(e: SelectChangeEvent) => {
                                                        setExpiresIn(Number.parseInt(e.target.value, 10));
                                                    }}
                                                    autoWidth
                                                    label="Expired In"
                                                >
                                                    <MenuItem value={7}>7 Days</MenuItem>
                                                    <MenuItem value={14}>14 Days</MenuItem>
                                                    <MenuItem value={21}>21 Days</MenuItem>
                                                    <MenuItem value={30}>30 Days</MenuItem>
                                                    <MenuItem value={60}>60 Days</MenuItem>
                                                    <MenuItem value={90}>90 Days</MenuItem>
                                                    <MenuItem value={-1}>Does Not Expire</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} textAlign={'center'}>
                                    {!webLink && (
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            disabled={isLoading || Object.keys(options).length === 0}
                                            onClick={handleCreateShareDashboardLink}
                                        >
                                            Create Link
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </TabPanel>

                    <DialogActions>
                        <Button
                            color="secondary"
                            size="medium"
                            disabled={isLoading}
                            onClick={() => {
                                handleClose({}, 'escapeKeyDown');
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : (
                <Dialog open={open} onClose={handleClose}>
                    No Data
                    <DialogActions>
                        <Button
                            size="medium"
                            onClick={() => {
                                handleClose({}, 'escapeKeyDown');
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default SextforceMetricsShareDialog;
