import { Grid, ToggleButton, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
type Props = {
    loading: boolean;
    selectorType: { includeInactive: boolean };
    setSelectorType: (types: { includeInactive: boolean }) => void;
    theme: Theme;
};

const SextforceMetricsCampaignsOverviewSelector = (props: Props) => {
    const { loading, selectorType, setSelectorType, theme } = props;
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md="auto">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <ToggleButton
                            color="secondary"
                            size={'small'}
                            fullWidth={largeScreen ? false : true}
                            value={selectorType.includeInactive ? 'true' : 'false'}
                            selected={selectorType.includeInactive}
                            disabled={loading}
                            onChange={(e, value: string) =>
                                setSelectorType({
                                    includeInactive: !selectorType.includeInactive,
                                })
                            }
                            sx={{ marginTop: theme.spacing(0.5) }}
                        >
                            Show Inactive
                        </ToggleButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SextforceMetricsCampaignsOverviewSelector;
