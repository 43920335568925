import { Grid, Typography } from '@mui/material';
import StyledCard from '../../../../components/common/StyledCard';
import { OnlyFansSubscriberHostessAutoReplyFreeloaders } from '../../../../types/hostessAutoReplyFreeloaders';
import FormActionToTakeSelect from './FormActionToTakeSelect';
import FormAutoUnrestrictWhenSpendingCheckbox from './FormAutoUnrestrictWhenSpendingCheckbox';
import FormFollowingThesholdEnabledCheckbox from './FormFollowingThesholdEnabledCheckbox';
import FormFollowingThresholdPeriodSelect from './FormFollowingThresholdPeriodSelect';
import FormFollowThresholdAmount from './FormFollowThresholdAmount';
import FormSkipCreatorsCheckbox from './FormSkipCreatorsCheckbox';
import FormSkipExpiredFansCheckbox from './FormSkipExpiredFansCheckbox';
import FormSpentThresholdAmount from './FormSpentThresholdAmount';

const AutoReplySettingsCard = (props: {
    formData: OnlyFansSubscriberHostessAutoReplyFreeloaders;
    setFormData: (data: OnlyFansSubscriberHostessAutoReplyFreeloaders) => void;
    autoReplyFreeloaders: OnlyFansSubscriberHostessAutoReplyFreeloaders | null;
    followingThresholdEnabled: boolean;
    setFollowingThresholdEnabled: (followingThresholdEnabled: boolean) => void;
    followingThresholdAmountString: string;
    setFollowingThresholdAmountString: (followingThresholdAmountString: string) => void;
}) => {
    const {
        formData,
        setFormData,
        autoReplyFreeloaders,
        followingThresholdEnabled,
        setFollowingThresholdEnabled,
        followingThresholdAmountString,
        setFollowingThresholdAmountString,
    } = props;

    return (
        <StyledCard>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                <Grid item xs={12}>
                    <Typography variant="h6">Settings</Typography>
                </Grid>
                <Grid item xs={12} md>
                    <Typography variant="body1">Spent Threshold</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Any amount below this one will trigger the auto-reply
                    </Typography>
                </Grid>
                <Grid item xs={12} md="auto">
                    <FormSpentThresholdAmount formData={formData} setFormData={setFormData} autoReplyFreeloaders={autoReplyFreeloaders} />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                <Grid item xs={12} md>
                    <Typography variant="body1">Grace Period</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Freeloaders who have followed you within this time will be skipped
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <FormFollowingThesholdEnabledCheckbox
                        followingThresholdEnabled={followingThresholdEnabled}
                        setFollowingThresholdEnabled={setFollowingThresholdEnabled}
                        setFollowingThresholdAmountString={setFollowingThresholdAmountString}
                        formData={formData}
                        setFormData={setFormData}
                    />
                </Grid>
                <Grid item xs={6} md="auto">
                    <FormFollowThresholdAmount
                        followingThresholdEnabled={followingThresholdEnabled}
                        followingThresholdAmountString={followingThresholdAmountString}
                        setFollowingThresholdAmountString={setFollowingThresholdAmountString}
                        autoReplyFreeloaders={autoReplyFreeloaders}
                        formData={formData}
                        setFormData={setFormData}
                    />
                </Grid>
                <Grid item xs={6} md="auto">
                    <FormFollowingThresholdPeriodSelect
                        followingThresholdEnabled={followingThresholdEnabled}
                        formData={formData}
                        setFormData={setFormData}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                <Grid item xs>
                    <Typography variant="body1">Skip Creators?</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        If checked, auto-reply will not reply to creators
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <FormSkipCreatorsCheckbox formData={formData} setFormData={setFormData} />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                <Grid item xs>
                    <Typography variant="body1">Skip Expired Fans?</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        If checked, auto-reply will not reply to expired fans
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <FormSkipExpiredFansCheckbox formData={formData} setFormData={setFormData} />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                <Grid item xs={12} md>
                    <Typography variant="body1">Action to Take</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        What to do when the freeloader has received the maximum number of strikes
                    </Typography>
                </Grid>
                <Grid item xs={12} md="auto">
                    <FormActionToTakeSelect formData={formData} setFormData={setFormData} />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                <Grid item xs>
                    <Typography variant="body1">Auto Unmute/Unrestrict When Spending?</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        If checked, fans will be unmuted or unrestricted as necessary when they spend above the threshold
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <FormAutoUnrestrictWhenSpendingCheckbox formData={formData} setFormData={setFormData} />
                </Grid>
            </Grid>
        </StyledCard>
    );
};

export default AutoReplySettingsCard;
